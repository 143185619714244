import type { LoadingButtonProps } from "@mui/lab"
import { LoadingButton } from "@mui/lab"
import React from "react"

export const RoundIconButton = ({
  children,
  size,
  sx,
  ...props
}: LoadingButtonProps): JSX.Element => {
  return (
    <LoadingButton
      sx={{
        minWidth: size === "small" ? 32 : size === "medium" ? 40 : 48,
        width: size === "small" ? 32 : size === "medium" ? 40 : 48,
        height: size === "small" ? 32 : size === "medium" ? 40 : 48,
        borderRadius: "50%",
        ...sx,
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  )
}
