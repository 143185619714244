import { Trans } from "@lingui/macro"
import { Box, Typography, Modal } from "@mui/material"
import React from "react"

import ProfileInfo from "./ProfileInfo"

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 580,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "8px",
  p: 3,
  padding: "32px",
  overflow: "auto",
  height: "fit-content",
  maxHeight: "100vh",
}

const ProfileInfoModal: React.FC = () => {
  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography variant="h4" mb={2} mt={2} align="center">
          <Trans>Say hello to ChainSafe Gaming!</Trans>
        </Typography>
        <Typography variant="body1" mb={4} align="center">
          <Trans>
            Fill out the below information to generate a project ID and register your game in
            Web3.Unity.{" "}
          </Trans>
        </Typography>
        <ProfileInfo />
      </Box>
    </Modal>
  )
}

export default ProfileInfoModal
