import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function CheckedCircleIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1.83325C5.93999 1.83325 1.83333 5.93992 1.83333 10.9999C1.83333 16.0599 5.93999 20.1666 11 20.1666C16.06 20.1666 20.1667 16.0599 20.1667 10.9999C20.1667 5.93992 16.06 1.83325 11 1.83325ZM9.16666 15.5833L4.58333 10.9999L5.87583 9.70742L9.16666 12.9891L16.1242 6.03159L17.4167 7.33325L9.16666 15.5833Z"
          fill="#588E23"
        />
      </svg>
    </SvgIcon>
  )
}
