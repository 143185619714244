import { t } from "@lingui/macro"
import { useSetChain } from "@web3-onboard/react"
import { ethers } from "ethers"
import { useSnackbar } from "notistack"
import React, { useCallback, useState } from "react"

import { NFTModal } from "../components/elements/NFTModal"
import CollectionFactory from "../contracts/NFTLaunchPad/CollectionFactory.json"
import type { Network } from "../utils/NetworkHelpers"

import { useCountly } from "./CountlyContext"
import { useGamingApi } from "./GamingApiContext"
import type { IProject } from "./ProjectContext"
import type { INFTAttribute } from "./StorageContext"
import { useWeb3Connection } from "./Web3ConnectionContext"

export const COLLECTION_SYNC_TIME_IN_SECONDS = 600

export const TOKEN_TYPE = {
  ERC721: "ERC721",
  ERC1155: "ERC1155",
} as const

export type TokenType = keyof typeof TOKEN_TYPE

const INITIAL_COLLECTION_OFFSET = 0
const INITIAL_COLLECTION_PAGE_SIZE = 100

const apiNFTLaunchPadRoutes = {
  getCollections: (projectId: string, offset?: number, pageSize?: number) =>
    `/v1/projects/${projectId}/collections?${offset !== undefined ? `?offset=${offset}` : ""}${
      pageSize !== undefined ? `&pageSize=${pageSize}` : ""
    }`,
  createCollection: (projectId: string) => `/v1/projects/${projectId}/collections`,
  deleteCollection: (projectId: string, collectionId: string) =>
    `/v1/projects/${projectId}/collections/${collectionId}`,
  getCollection: (projectId: string, collectionId: string) =>
    `/v1/projects/${projectId}/collections/${collectionId}`,
  getCollectionNFTs: (projectId: string, collectionId: string) =>
    `/v1/projects/${projectId}/collections/${collectionId}/tokens`,
}

export type NFTCollection = {
  id: string
  name: string
  description?: string
  owner: string
  chain_id: number
  project_id: string
  contract_address: string
  deployed: boolean
  logo?: string
  type: TokenType
  banner?: string
  created_at: number
  updated_at: number
}

type CreateNFTCollection = {
  name: string
  description?: string
  chain_id: number
  type: TokenType
  logo?: File
  banner?: File
}

export type NFTTokenType = {
  chain_id: number
  contract_address: string
  supply: string
  token_id: string
  token_type: "ERC1155" | "ERC721"
  uri: string
  metadata?: {
    image: string
    name: string
    additionalFiles?: string[]
    attributes?: INFTAttribute[]
    description?: string
    tokenType: NFTTokenType
  }
}

export type NFTModal = Omit<NFTTokenType, "supply" | "chain_id">

export type NFTsContextType = {
  collections?: NFTCollection[]
  isLoadingCollections: boolean
  hasLoadedCollections: boolean
  NFTsProject?: IProject
  NFTsCollection?: NFTCollection
  NFTsNetworks: Network[]
  setNFTsProject: (NFTsProject: IProject | undefined) => void
  setNFTsCollection: (NFTsCollection: NFTCollection | undefined) => void
  getCollectionFactory: (chainId: number) => ethers.Contract | undefined
  getCollections: () => void
  hasLoadedCollection: boolean
  getCollection: (collectionId: string) => Promise<void>
  createCollection: (data: CreateNFTCollection) => Promise<NFTCollection | undefined>
  createCollectionOnChain: (collection: NFTCollection) => Promise<void>
  deleteCollection: (collectionId: string) => Promise<void>
  getCollectionNFTs: (collectionId: string) => Promise<NFTTokenType[]>
  openNFTModal: (nft: NFTModal) => void
  closeNFTModal: (nft: NFTTokenType) => void
}

type NFTsContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const NFTsContext = React.createContext<NFTsContextType | undefined>(undefined)

const NFTsProvider = ({ children }: NFTsContextProviderProps): JSX.Element => {
  const { gamingApiInstance } = useGamingApi()
  const { network, provider, networks } = useWeb3Connection()
  const [, setChain] = useSetChain()
  const { trackEvent } = useCountly()
  const { enqueueSnackbar } = useSnackbar()

  const [hasLoadedCollection, setHasLoadedCollection] = useState(false)
  const [collections, setCollections] = useState<NFTCollection[]>([])
  const [isLoadingCollections, setIsLoadingCollections] = useState(false)
  const [hasLoadedCollections, setHasLoadedCollections] = useState(false)
  const [NFTsProject, setNFTsProject] = useState<IProject>()
  const [NFTsCollection, setNFTsCollection] = useState<NFTCollection>()

  const [nftDetailsModal, setNftDetailsModal] = useState<NFTModal | undefined>()

  const NFTsNetworks = networks.filter((n) => !n.isLocalNetwork && !!n.collectionFactoryAddress)

  const openNFTModal = useCallback((nft: NFTModal) => {
    setNftDetailsModal(nft)
  }, [])

  const closeNFTModal = useCallback(() => {
    setNftDetailsModal(undefined)
  }, [])

  const getCollectionFactory = useCallback(
    (chainId: number) => {
      // getting the contract ready
      const contractAddress = NFTsNetworks.find(
        (aN) => aN.chainId === chainId
      )?.collectionFactoryAddress

      if (!contractAddress || !provider) return

      const signer = provider?.getSigner(0)
      return new ethers.Contract(
        contractAddress, // contract address
        CollectionFactory.abi, // contract abi (meta-data)
        signer // Signer object signs and sends transactions
      )
    },
    [NFTsNetworks, provider]
  )

  const getCollections = useCallback(() => {
    if (!NFTsProject || !gamingApiInstance) return
    setIsLoadingCollections(true)
    gamingApiInstance
      .get(
        apiNFTLaunchPadRoutes.getCollections(
          NFTsProject.projectId,
          INITIAL_COLLECTION_OFFSET,
          INITIAL_COLLECTION_PAGE_SIZE
        )
      )
      .then(({ data }) => {
        if (data && JSON.parse(data)) {
          setCollections(JSON.parse(data).collections)
        }
        setHasLoadedCollections(true)
        setIsLoadingCollections(false)
      })
      .catch((e) => {
        console.error(e)
        setCollections([])
        setHasLoadedCollections(true)
        setIsLoadingCollections(false)
      })
  }, [NFTsProject, gamingApiInstance])

  const createCollection = useCallback(
    async (data: CreateNFTCollection) => {
      try {
        if (!gamingApiInstance || !NFTsProject) return

        // create form data for API
        const formData = new FormData()

        formData.append("name", data.name)
        formData.append("chain_id", data.chain_id.toString())
        formData.append("type", data.type.toString())
        if (data.description) formData.append("description", data.description)
        if (data.banner) formData.append("banner", data.banner)
        if (data.logo) formData.append("logo", data.logo)

        const resp = await gamingApiInstance.post(
          apiNFTLaunchPadRoutes.createCollection(NFTsProject.projectId),
          formData
        )

        const newCollection: NFTCollection = JSON.parse(resp.data)
        //  track event
        trackEvent({
          key: "collection-created",
          segmentation: { project_id: NFTsProject.projectId, collection_id: newCollection.id },
        })

        getCollections()
        return newCollection
      } catch (error) {
        enqueueSnackbar(t`Failed to create collection`, {
          variant: "error",
        })
      }
    },
    [NFTsProject, enqueueSnackbar, gamingApiInstance, getCollections, trackEvent]
  )

  const createCollectionOnChain = useCallback(
    async (collection: NFTCollection) => {
      // ensure correct chainId
      if (network?.chainId !== collection.chain_id) {
        const wasNetworkSet = await setChain({ chainId: `0x${collection.chain_id.toString(16)}` })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }
      const collectionFactory = getCollectionFactory(collection.chain_id)
      if (!collectionFactory) return
      // creating on chain collection
      const collectionTx = await (collection.type === TOKEN_TYPE.ERC721
        ? collectionFactory.create721Collection(
            NFTsProject?.projectId,
            collection.id,
            collection.name,
            "",
            "",
            false
          )
        : collectionFactory.create1155Collection(NFTsProject?.projectId, collection.id, "", false))
      await collectionTx

      // track event
      trackEvent({
        key: "collection-created-on-chain",
        segmentation: { project_id: NFTsProject?.projectId, collection_id: collection.id },
      })
    },
    [NFTsProject, enqueueSnackbar, getCollectionFactory, network, setChain, trackEvent]
  )

  const deleteCollection = useCallback(
    async (collectionId: string) => {
      try {
        if (!gamingApiInstance || !NFTsProject) return

        await gamingApiInstance.delete(
          apiNFTLaunchPadRoutes.deleteCollection(NFTsProject.projectId, collectionId)
        )
        trackEvent({
          key: "collection-deleted",
          segmentation: { project_id: NFTsProject.projectId, collection_id: collectionId },
        })

        return
      } catch (error) {
        console.error(error)
      }
    },
    [NFTsProject, gamingApiInstance, trackEvent]
  )

  const getCollection = useCallback(
    async (collectionId: string) => {
      try {
        if (!gamingApiInstance || !NFTsProject) return

        setHasLoadedCollection(false)
        const { data } = await gamingApiInstance.get(
          apiNFTLaunchPadRoutes.getCollection(NFTsProject.projectId, collectionId)
        )
        setNFTsCollection(JSON.parse(data))
        setHasLoadedCollection(true)
      } catch (error) {
        setHasLoadedCollection(true)
        console.error(error)
      }
    },
    [NFTsProject, gamingApiInstance]
  )

  const getCollectionNFTs = useCallback(
    async (collectionId: string) => {
      if (!NFTsProject || !NFTsCollection || !gamingApiInstance) return
      setIsLoadingCollections(true)
      try {
        const { data } = await gamingApiInstance.get(
          apiNFTLaunchPadRoutes.getCollectionNFTs(NFTsProject.projectId, collectionId)
        )
        if (data && JSON.parse(data)) {
          return JSON.parse(data).tokens
        }
        return []
      } catch (err) {
        console.error(err)
      }
    },
    [NFTsProject, NFTsCollection, gamingApiInstance]
  )

  return (
    <NFTsContext.Provider
      value={{
        collections,
        isLoadingCollections,
        hasLoadedCollections,
        NFTsProject,
        NFTsCollection,
        NFTsNetworks,
        getCollectionFactory,
        setNFTsProject,
        setNFTsCollection,
        getCollections,
        getCollection,
        hasLoadedCollection,
        createCollection,
        createCollectionOnChain,
        deleteCollection,
        getCollectionNFTs,
        openNFTModal,
        closeNFTModal,
      }}
    >
      {children}
      <NFTModal onClose={closeNFTModal} nft={nftDetailsModal} />
    </NFTsContext.Provider>
  )
}

function useNFTs(): NFTsContextType {
  const context = React.useContext(NFTsContext)
  if (context === undefined) {
    throw new Error("useNFTs must be used within a NFTsContext")
  }
  return context
}

export { NFTsProvider, useNFTs }
