import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { Trans } from "@lingui/macro"
import { Close } from "@mui/icons-material"
import { Box, Button, IconButton, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

import { BookACallIcon } from "../../../assets/icons/SupportPopupIcons/BookACallIcon"
import { ChatIcon } from "../../../assets/icons/SupportPopupIcons/ChatIcon"
import { useCountly } from "../../../contexts/CountlyContext"
import { ROUTE_LINKS } from "../../../routes/routes"
import { SoftButton } from "../SoftButton"

const supportPopupLastDateKey = "csgd.supportPopUpLastDate"
const supportPopupHideKey = "csgd.supportPopUpHide"
const SUPPORT_POPUP_DAY_DIFFERENCE = 7

export const SupportPopup = (): JSX.Element | null => {
  const { canUseLocalStorage, localStorageSet, localStorageGet } = useLocalStorage()
  const { trackEvent } = useCountly()

  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (canUseLocalStorage) {
      const supportPopupLastDateString = localStorageGet(supportPopupLastDateKey)
      const supportPopupHideString = localStorageGet(supportPopupHideKey)
      if (
        !supportPopupHideString &&
        (!supportPopupLastDateString ||
          dayjs().diff(supportPopupLastDateString, "day") >= SUPPORT_POPUP_DAY_DIFFERENCE)
      ) {
        setShowPopup(true)
      }
    }
  }, [canUseLocalStorage, localStorageGet])

  const onClose = (): void => {
    localStorageSet(supportPopupLastDateKey, new Date().toISOString())
    setShowPopup(false)
  }

  const onDoNotShowAgain = (): void => {
    localStorageSet(supportPopupHideKey, "true")
    setShowPopup(false)
  }

  if (!showPopup) return null

  return (
    <Box
      sx={{
        background: "#141414",
        borderRadius: "8px",
        zIndex: 10000,
        position: "fixed",
        right: "16px",
        bottom: "40px",
        width: 220,
        border: "1px solid #262626",
      }}
      p={2}
    >
      <Box display="flex" justifyContent="flex-end" mb={0.5}>
        <IconButton sx={{ p: 0 }} onClick={onClose} data-cy="button-support-close">
          <Close fontSize="small" sx={{ p: 0, fontSize: "16px", color: "#8C8C8C" }} />
        </IconButton>
      </Box>
      <Box flex={1}>
        <Typography fontSize={16}>
          <Trans>Get free support now from one of our experienced devs!</Trans>
        </Typography>
        <Box display="flex" flexDirection="column" gap={1.5} mt={1.5}>
          <a
            href={ROUTE_LINKS.Discord}
            target="_blank"
            style={{ textDecoration: "none" }}
            data-cy="button-chat-support"
            onClick={() => {
              trackEvent({ key: "discord-support-click" })
            }}
          >
            <SoftButton
              sx={{
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
              }}
            >
              <ChatIcon sx={{ fontSize: "20px", marginRight: "6px" }} />
              <Trans>Chat support</Trans>
            </SoftButton>
          </a>
          <a
            href={ROUTE_LINKS.BookCall}
            target="_blank"
            style={{ textDecoration: "none" }}
            data-cy="button-book-a-call"
            onClick={() => {
              trackEvent({ key: "book-call-click" })
            }}
          >
            <SoftButton
              sx={{
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                width: "100%",
                fontSize: "16px",
              }}
            >
              <BookACallIcon sx={{ fontSize: "20px", marginRight: "6px" }} />
              <Trans>Book a call</Trans>
            </SoftButton>
          </a>
          <Button
            size="small"
            onClick={onDoNotShowAgain}
            variant="text"
            sx={{ fontSize: "12px", p: "0px", color: "GrayText" }}
            data-cy="button-support-dont-show-again"
          >
            <Trans>Don't show again</Trans>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
