import { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { useCountly } from "../contexts/CountlyContext"

const RouteTracker = (): null => {
  const location = useLocation()
  const { trackPageView } = useCountly()

  useEffect(() => {
    trackPageView(location.pathname)
  }, [location.pathname, trackPageView])

  return null
}

export default RouteTracker
