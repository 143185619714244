import { Trans } from "@lingui/macro"
import { Typography, Box, Divider } from "@mui/material"
import React from "react"

import AnalyticsBody from "../components/modules/analytics/AnalyticsBody"

const AnalyticsPage = (): JSX.Element => {
  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" alignItems="flex-end" mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>Game Analytics</Trans>
          </Typography>
          <Typography sx={{ color: "#8C8C8C", ml: 2, mb: 0.25 }} variant="body1">
            <Trans>See player activity related to your game.</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <AnalyticsBody />
      </Box>
    </Box>
  )
}

export default AnalyticsPage
