import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Typography, Box, Divider, Breadcrumbs } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import MarketplaceListItem from "../../components/modules/marketplace/MarketplaceListItem"
import { useMarketplace } from "../../contexts/MarketplaceContext"
import { ROUTE_LINKS } from "../../routes/routes"

const MarketplaceListItemPage = (): JSX.Element | null => {
  const { marketplaceProject, marketplace } = useMarketplace()

  if (!marketplaceProject || !marketplace) return null

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            List NFT's
          </Typography>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 1 }}>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={ROUTE_LINKS.marketplacesOnProject(marketplaceProject.projectId)}
            >
              {marketplaceProject.name || "Project"}
            </Link>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={ROUTE_LINKS.marketplacesOnProject(marketplaceProject.projectId)}
            >
              Marketplaces
            </Link>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={ROUTE_LINKS.marketplaceView(marketplaceProject.projectId, marketplace.id)}
            >
              {marketplace.name || "Marketplace"}
            </Link>
            <Typography>List New NFT's</Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <MarketplaceListItem />
      </Box>
    </Box>
  )
}

export default MarketplaceListItemPage
