import { Trans } from "@lingui/macro"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Box, Typography, Modal, Button } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"

import type { IProject } from "../../../contexts/ProjectContext"

interface IProjectConfirmModalProps {
  onClose: () => void
  isOpen: boolean
  project: IProject
}

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 480,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 5,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
}

const ProjectConfirmModal: React.FC<IProjectConfirmModalProps> = ({ isOpen, onClose, project }) => {
  const { projectId } = project
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          <Trans>Project Created</Trans>
        </Typography>
        <Typography align="center" mb={3}>
          <Trans>
            This is your Project ID. Please keep it safe and don't give it away to others! We use
            this to help identify and support you within the blockchain gaming space.
          </Trans>
        </Typography>

        <Box
          sx={{ borderRadius: 4, backgroundColor: "#333333" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          pl={3}
          pr={3}
          pt={1}
          pb={1}
        >
          <Typography
            data-cy="label-project-confirmation-project-id"
            variant="body1"
            color="#F5F5F5"
          >
            {projectId}
          </Typography>
          <ContentCopyIcon
            data-cy="button-project-confirmation-copy-project-id"
            onClick={() => {
              navigator.clipboard.writeText(projectId)
              enqueueSnackbar("Project ID copied", { variant: "success" })
            }}
            color="primary"
            sx={{ fontSize: 14, ml: 1.5, cursor: "pointer" }}
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button
            data-cy="button-project-confirmation-close"
            variant="contained"
            size="small"
            onClick={onClose}
          >
            <Trans>Ok, got it!</Trans>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ProjectConfirmModal
