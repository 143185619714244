import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useEffect } from "react"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { useNFTs } from "../../../../contexts/NFTsContext"

import CollectionCard from "./CollectionCard"

const CollectionsList: React.FC = () => {
  const { collections, isLoadingCollections, hasLoadedCollections, getCollections } = useNFTs()

  useEffect(() => {
    getCollections()
  }, [getCollections])

  if (isLoadingCollections && !hasLoadedCollections) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (hasLoadedCollections && !collections?.length) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Box
          width={400}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
          sx={{ border: "1px solid #333333", borderRadius: "8px", background: "#141414" }}
          data-cy="box-collections-list-empty"
        >
          <NoDataOrConnectionIcon />
          <Typography fontSize={20} mt={2}>
            <Trans>No collections yet</Trans>
          </Typography>
          <Typography fontSize="16px" align="center" mt={2} color="#8C8C8C">
            <Trans>Please create a collection before minting new NFT&apos;s.</Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="grid"
      gap={2}
      sx={(theme) => ({
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.up("xl")]: {
          gridTemplateColumns: "1fr 1fr 1fr",
        },
      })}
    >
      {collections?.map((c) => <CollectionCard key={c.id} collection={c} />)}
    </Box>
  )
}

export default CollectionsList
