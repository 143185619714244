import type { AxiosInstance } from "axios"
import axios from "axios"
import React, { useMemo } from "react"

import { useStorageApi } from "./StorageContext"

type GamingApiContextProps = {
  children: React.ReactNode | React.ReactNode[]
  apiUrl: string
}

type GamingApiContext = {
  gamingApiInstance?: AxiosInstance
}

const GamingApiContext = React.createContext<GamingApiContext | undefined>(undefined)

const GamingApiProvider = ({ children, apiUrl }: GamingApiContextProps): JSX.Element => {
  const { isLoggedIn, accessToken } = useStorageApi()

  const gamingApiInstance = useMemo(() => {
    if (isLoggedIn && accessToken && apiUrl) {
      return axios.create({
        baseURL: apiUrl,
        // Disable the internal Axios JSON de serialization as this is handled by the client
        transformResponse: [],
        headers: {
          authorization: `Bearer ${accessToken.token}`,
        },
      })
    }
    return
  }, [accessToken, apiUrl, isLoggedIn])

  return (
    <GamingApiContext.Provider value={{ gamingApiInstance }}>{children}</GamingApiContext.Provider>
  )
}

const useGamingApi = (): GamingApiContext => {
  const context = React.useContext(GamingApiContext)
  if (context === undefined) {
    throw new Error("useGamingApi must be used within a GamingApiProvider")
  }
  return context
}

export { GamingApiProvider, useGamingApi }
