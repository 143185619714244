import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function ChatIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.75 17.6925V14.9925C4.9925 14.7975 2 11.6775 2 7.875C2 3.945 5.195 0.75 9.125 0.75C13.055 0.75 16.25 3.945 16.25 7.875C16.25 11.5875 13.67 15.3225 9.8225 17.175L8.75 17.6925ZM9.125 2.25C6.02 2.25 3.5 4.77 3.5 7.875C3.5 10.98 6.02 13.5 9.125 13.5H10.25V15.225C12.98 13.5 14.75 10.665 14.75 7.875C14.75 4.77 12.23 2.25 9.125 2.25ZM8.375 10.875H9.875V12.375H8.375V10.875ZM9.875 9.75H8.375C8.375 7.3125 10.625 7.5 10.625 6C10.625 5.175 9.95 4.5 9.125 4.5C8.3 4.5 7.625 5.175 7.625 6H6.125C6.125 4.3425 7.4675 3 9.125 3C10.7825 3 12.125 4.3425 12.125 6C12.125 7.875 9.875 8.0625 9.875 9.75Z"
          fill="#DFF7C7"
        />
      </svg>
    </SvgIcon>
  )
}
