import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function BookACallIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.4 2.85H13.725V1.5H12.375V2.85H5.625V1.5H4.275V2.85H3.6C2.8575 2.85 2.25 3.4575 2.25 4.2V15C2.25 15.7425 2.8575 16.35 3.6 16.35H14.4C15.1425 16.35 15.75 15.7425 15.75 15V4.2C15.75 3.4575 15.1425 2.85 14.4 2.85ZM14.4 15H3.6V6.225H14.4V15Z"
          fill="#DFF7C7"
        />
      </svg>
    </SvgIcon>
  )
}
