import { Trans } from "@lingui/macro"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, Modal } from "@mui/material"
import React from "react"

import type { NFTCollection } from "../../../../contexts/NFTsContext"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 520,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const DeleteCollectionModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  onDeleteCollection: () => void
  isLoadingDelete: boolean
  collection: NFTCollection
}> = ({ isOpen, collection, onClose, onDeleteCollection, isLoadingDelete }) => {
  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          <Trans>Delete collection?</Trans>
        </Typography>
        <Typography variant="body1" mb={4} align="center">
          <Trans>
            Deleting a collection is a permanent action. Please confirm that you would like to
            permanently remove “{collection.name}”
          </Trans>
        </Typography>

        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
          <LoadingButton
            variant="text"
            disabled={isLoadingDelete}
            sx={{
              color: "#BFBFBF",
              width: 80,
              pl: 2,
              pr: 2,
            }}
            onClick={onClose}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            sx={{
              width: 160,
              backgroundColor: "#333333",
              color: "#FF7875",
              "&:hover": {
                backgroundColor: "#333333",
                color: "#FF7875",
              },
            }}
            loading={isLoadingDelete}
            disabled={isLoadingDelete}
            onClick={onDeleteCollection}
          >
            <Trans>Delete collection</Trans>
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteCollectionModal
