import { Trans } from "@lingui/macro"
import { Typography, Box, Divider } from "@mui/material"
import React from "react"

import MarketplaceBase from "../../components/modules/marketplace/MarketplaceBase"

const MarketplacesBasePage = (): JSX.Element => {
  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" alignItems="flex-end" mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>Marketplaces</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <MarketplaceBase />
      </Box>
    </Box>
  )
}

export default MarketplacesBasePage
