import React from "react"
import { Navigate, Outlet } from "react-router-dom"

import { useStorageApi } from "../contexts/StorageContext"

import { ROUTE_LINKS } from "./routes"

interface IAuthLayout {
  isPrivate?: boolean
}

const AuthLayout = ({ isPrivate = true }: IAuthLayout): JSX.Element | null => {
  const { isLoggedIn } = useStorageApi()

  if (isPrivate) {
    return isLoggedIn === undefined ? null : isLoggedIn === true ? (
      <Outlet />
    ) : (
      <Navigate to={ROUTE_LINKS.Landing} replace />
    )
  } else {
    return <Outlet />
  }
}

export default AuthLayout
