import { t } from "@lingui/macro"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Box, Typography } from "@mui/material"
import React from "react"

import type { TManageLootboxTab } from "./types"

interface ISidebarElementProps {
  isActive: boolean
  text: string
  onClick: () => void
}

const SidebarElement: React.FC<ISidebarElementProps> = ({ isActive, text, onClick }) => (
  <Box
    sx={{
      color: isActive ? "#FFFFFF" : "#595959",
      cursor: "pointer",
      transition: "color 0.20s ease",
    }}
    display="flex"
    alignItems="center"
    gap={1}
    mb={2}
    onClick={onClick}
  >
    <ArrowForwardIosIcon color="inherit" sx={{ fontSize: "14px" }} />
    <Typography color="inherit">{text}</Typography>
  </Box>
)

interface IManageLootboxSidebarProps {
  tab: TManageLootboxTab
  setTab: (tab: TManageLootboxTab) => void
}

const ManageLootboxSidebar: React.FC<IManageLootboxSidebarProps> = ({ tab, setTab }) => {
  return (
    <Box minWidth={260} maxWidth={260} pr={6}>
      <SidebarElement
        isActive={tab === "tokenAddresses"}
        text={t`Whitelist Reward Tokens`}
        onClick={() => setTab("tokenAddresses")}
      />
      <SidebarElement
        isActive={tab === "depositorAccess"}
        text={t`Designate Access`}
        onClick={() => setTab("depositorAccess")}
      />
      <SidebarElement
        isActive={tab === "sendLoot"}
        text={t`Send Rewards`}
        onClick={() => setTab("sendLoot")}
      />
      <SidebarElement
        isActive={tab === "rewards"}
        text={t`Manage Reward Units`}
        onClick={() => setTab("rewards")}
      />
      <SidebarElement
        isActive={tab === "mint"}
        text={t`Loot Box Minting`}
        onClick={() => setTab("mint")}
      />
      <SidebarElement
        isActive={tab === "review"}
        text={t`Review`}
        onClick={() => setTab("review")}
      />
    </Box>
  )
}

export default ManageLootboxSidebar
