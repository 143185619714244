import { t } from "@lingui/macro"
import { object, string } from "yup"

export const emailValidation = object().shape({
  email: string()
    .trim()
    .email(t`Invalid email address. Please try again.`)
    .required(t`Email is required`),
})

export const nonceValidation = object().shape({
  nonce: string()
    .trim()
    .required(t`Verification code is required`),
})
