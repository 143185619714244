import { Box, CircularProgress } from "@mui/material"
import type { ReactNode } from "react"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useMarketplace } from "../../contexts/MarketplaceContext"
import { useProjectApi } from "../../contexts/ProjectContext"
import { ROUTE_LINKS } from "../../routes/routes"
import { SupportPopup } from "../elements/Banners/SupportPopup"

export const MarketplacesDashboardLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const { projects } = useProjectApi()
  const {
    marketplaceProject,
    setMarketplaceProject,
    marketplace,
    getMarketplace,
    hasLoadedMarketplace,
  } = useMarketplace()
  const navigate = useNavigate()

  const { projectId } = useParams<{ projectId: string }>()
  const { marketplaceId } = useParams<{ marketplaceId: string }>()

  useEffect(() => {
    if (projectId && marketplaceProject?.projectId !== projectId) {
      const project = projects?.find((p) => p.projectId === projectId)
      if (project) setMarketplaceProject(project)
    } else if (projectId && !projects?.find((p) => p.projectId === projectId)) {
      setMarketplaceProject(undefined)
      navigate(ROUTE_LINKS.marketplaces)
    }
  }, [projectId, projects, setMarketplaceProject, marketplaceProject, navigate])

  useEffect(() => {
    if (marketplaceId && marketplace?.id !== marketplaceId) getMarketplace(marketplaceId)
  }, [marketplaceId, getMarketplace, marketplace])

  if (marketplaceId && !hasLoadedMarketplace) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box mb={32}>
      {children}
      <SupportPopup />
    </Box>
  )
}
