import { Trans } from "@lingui/macro"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Typography, Box, Divider, Breadcrumbs } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import NFTMint from "../../components/modules/nftLaunchpad/NFTs/NFTMint"
import { useNFTs } from "../../contexts/NFTsContext"
import { ROUTE_LINKS } from "../../routes/routes"

const NFTsMintNFTPage = (): JSX.Element | null => {
  const { NFTsProject, NFTsCollection } = useNFTs()

  if (!NFTsProject || !NFTsCollection) return null

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>Mint new NFT</Trans>
          </Typography>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 1 }}>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId)}
              data-cy="link-breadcrumb-project-collections"
            >
              {NFTsProject?.name || "Project"}
            </Link>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={ROUTE_LINKS.NFTsOnCollection(NFTsProject?.projectId, NFTsCollection.id)}
              data-cy="link-breadcrumb-collection-nfts"
            >
              {NFTsCollection?.name || "Collection"}
            </Link>
            <Typography data-cy="label-breadcrumb-nfts">NFTs</Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <NFTMint />
      </Box>
    </Box>
  )
}

export default NFTsMintNFTPage
