import { Box, Button, Typography } from "@mui/material"

import type { NFTTokenType } from "../../../../contexts/NFTsContext"
import { useNFTs } from "../../../../contexts/NFTsContext"
import { getIPFSUri } from "../../../../utils/Helpers"

interface IProps {
  NFTToken: NFTTokenType
  onDetailsClick: (nft: NFTTokenType) => void
}

export function NFTCard({ NFTToken, onDetailsClick }: IProps): JSX.Element | null {
  const { NFTsProject } = useNFTs()

  const { metadata, token_id, supply } = NFTToken
  if (!metadata || !NFTsProject) return null

  const { image, name } = metadata

  return (
    <>
      <Box
        width="100%"
        sx={{
          borderRadius: "12px",
          background: "#262626",
        }}
      >
        <Box
          height={180}
          display="flex"
          alignItems="center"
          sx={{ background: "#141414", borderRadius: "12px" }}
        >
          {image ? (
            <img
              style={{ width: "100%", maxHeight: 180, borderRadius: "12px", objectFit: "contain" }}
              src={getIPFSUri(image)}
              alt="collection"
              data-cy="label-nft-card-image"
            />
          ) : (
            <Box
              height="180px"
              width="100%"
              sx={{ background: "#595959", borderRadius: "12px" }}
              data-cy="label-nft-card-default-image"
            />
          )}
        </Box>
        <Box p={1} mt={0.25}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography fontSize="18px" data-cy="label-nft-name">
              {name}
            </Typography>
            <Button
              variant="text"
              sx={{
                color: "#E8E8E8",
                fontWeight: 200,
                textDecoration: "underline",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => onDetailsClick(NFTToken)}
              data-cy="button-nft-card-details"
            >
              Details
            </Button>
          </Box>
          <Box display="flex">
            <Box pr={7}>
              <Typography fontSize={14} color="#8C8C8C">
                Quantity
              </Typography>
              <Typography data-cy="label-nft-card-supply">{supply}</Typography>
            </Box>
            <Box>
              <Typography fontSize={14} color="#8C8C8C">
                ID
              </Typography>
              <Typography data-cy="label-nft-card-token-id">#{token_id}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
