import { Trans } from "@lingui/macro"
import { Typography, Box, Divider } from "@mui/material"
import React from "react"

import ProfileInfo from "../components/modules/profile/ProfileInfo"
import SubscriptionsView from "../components/modules/profile/SubscriptionsView"
// import { ROUTE_LINKS } from "../routes/routes"
// import { Link } from "react-router-dom"

const SettingsPage = (): JSX.Element => {
  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box>
          {/* <Breadcrumbs
            sx={{ mb: 1 }}
          >
            <Link to={ROUTE_LINKS.Dashboard}
              style={{ textDecoration: "none" }}
            >
              <Box display="flex">
                <Typography
                  sx={{
                    textDecoration: "none", color: "GrayText",
                    ":hover": {
                      textDecoration: "underline"
                    }
                  }}
                >
                  Home
                </Typography>
                <Typography
                  sx={{ textDecoration: "none", color: "GrayText" }}
                >
                  &nbsp; /
                </Typography>
              </Box>
            </Link>
          </Breadcrumbs> */}
          <Typography sx={{ flex: 1, mb: 0.5 }} variant="h4">
            <Trans>Settings</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box display="flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            ml: 2,
            mr: 2,
            flex: 3,
            backgroundColor: "#171717",
            pt: 4,
            pl: 2,
            pr: 2,
            pb: 4,
            borderRadius: 4,
          }}
        >
          <Box sx={{ maxWidth: 700 }}>
            <ProfileInfo isEdit={true} />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            ml: 2,
            mr: 2,
            flex: 2,
            backgroundColor: "#171717",
            pt: 4,
            pl: 2,
            pr: 2,
            pb: 4,
            borderRadius: 4,
            height: "fit-content",
          }}
        >
          <Box sx={{ maxWidth: 500 }}>
            <SubscriptionsView />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SettingsPage
