import { Box, Typography } from "@mui/material"
import { ethers } from "ethers"
import { useCallback, useEffect, useState } from "react"

import { useWeb3Connection } from "../../../../../../contexts/Web3ConnectionContext"
import GenericERC20 from "../../../../../../contracts/Generic/GenericERC20.json"

const ERC20Reward: React.FC<{ address: string; amount: string }> = ({ address, amount }) => {
  const { provider } = useWeb3Connection()
  const [tokenInfo, setTokenInfo] = useState<{ name: string; symbol: string }>()

  const getInfo = useCallback(async () => {
    if (!provider) return
    const signer = provider?.getSigner(0)
    const contract = new ethers.Contract(address, GenericERC20, signer)
    const name = await contract.name()
    const symbol = await contract.symbol()
    setTokenInfo({
      name,
      symbol,
    })
  }, [address, provider])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  if (!provider || !tokenInfo) return null

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1}
      sx={{
        pt: 6,
        width: "100%",
        height: 260,
        border: "1px solid #BFBFBF",
        borderRadius: "10px",
        maxWidth: 200,
      }}
    >
      <Typography fontSize={22}>{tokenInfo.name}</Typography>
      <Typography fontSize={16}>{tokenInfo.symbol}</Typography>
      <Typography variant="body2" color="#BFBFBF">
        ERC20
      </Typography>
      <Typography fontSize={18}>Quantity: {amount}</Typography>
    </Box>
  )
}

export default ERC20Reward
