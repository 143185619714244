import { Box, Modal } from "@mui/material"
import React from "react"

import WalletConnect from "./WalletConnect"

interface IWalletConnectModalProps {
  subtitle?: string
  onClose?: () => void
  isOpen: boolean
}

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 460,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  ":focus": {
    outline: "none",
  },
}

const WalletConnectModal: React.FC<IWalletConnectModalProps> = ({
  subtitle,
  onClose,
  isOpen,
}: IWalletConnectModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose} sx={{ zIndex: 1 }}>
      <Box sx={style}>
        <WalletConnect subtitle={subtitle} />
      </Box>
    </Modal>
  )
}

export default WalletConnectModal
