import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { Trans } from "@lingui/macro"
import AddIcon from "@mui/icons-material/Add"
import { Typography, Box, Divider, Button } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"

import { RoundIconButton } from "../components/elements/RoundIconButton"
import ProfileInfoModal from "../components/modules/profile/ProfileInfoModal"
import CreateProjectModal from "../components/modules/project/CreateProjectModal"
import ProjectConfirmModal from "../components/modules/project/ProjectConfirmModal"
import ProjectGrid from "../components/modules/project/ProjectGrid"
import { useProfileInfo } from "../contexts/ProfileInfoContext"
import { useProjectApi } from "../contexts/ProjectContext"
import type { IProject } from "../contexts/ProjectContext"

const HIDE_SDK_BANNER_LOCALSTORAGE_KEY = "csgd.hideSDKBanner"

const DashboardPage = (): JSX.Element => {
  const { projects } = useProjectApi()
  const [modalMode, setModalMode] = useState<"create" | "edit">()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isProjectConfirmModalOpen, setIsProjectConfirmModalOpen] = useState(false)
  const [newProject, setNewProject] = useState<IProject>()
  const [projectToEdit, setProjectToEdit] = useState<IProject>()
  const { userLocalStore } = useProfileInfo()

  const shouldShowProfileInfoModal = useMemo(() => {
    return userLocalStore !== undefined
      ? userLocalStore["csgd.isProfileConfirmed"] !== "true"
      : false
  }, [userLocalStore])

  // show sdk banner if downloaded was not clicked
  // and saved in localstorage
  const { canUseLocalStorage, localStorageGet, localStorageSet } = useLocalStorage()

  const [shouldShowSDKBanner, setShouldShowSDKBanner] = useState(false)

  useEffect(() => {
    if (canUseLocalStorage && !localStorageGet(HIDE_SDK_BANNER_LOCALSTORAGE_KEY)) {
      setShouldShowSDKBanner(true)
    }
  }, [canUseLocalStorage, localStorageGet])

  const onRemoveSDKBanner = (): void => {
    setShouldShowSDKBanner(false)
    localStorageSet(HIDE_SDK_BANNER_LOCALSTORAGE_KEY, "true")
  }

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box>
          <Typography sx={{ flex: 1, mb: 0.5, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>My Projects</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
        mb={4}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box>
          <Typography sx={{ flex: 1, mt: 0.5, mb: 0.5 }} variant="h5">
            <Trans>Get started</Trans>
          </Typography>
          <Typography variant="body2">
            <Trans>Register your project ID in web3.unity by creating a new project.</Trans>
          </Typography>
        </Box>
        <RoundIconButton
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => {
            setModalMode("create")
            setIsModalOpen(true)
          }}
          data-cy="button-projects-create-project"
        >
          <AddIcon />
        </RoundIconButton>
      </Box>
      {projects && (
        <ProjectGrid
          projects={projects}
          setProjectToEdit={(p: IProject) => {
            setModalMode("edit")
            setIsModalOpen(true)
            setProjectToEdit(p)
          }}
        />
      )}
      {isModalOpen ? (
        <CreateProjectModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setModalMode(undefined)
            setProjectToEdit(undefined)
          }}
          modalMode={modalMode}
          project={projectToEdit}
          onNewProject={(newProject: IProject) => {
            setIsProjectConfirmModalOpen(true)
            setNewProject(newProject)
          }}
        />
      ) : null}
      {shouldShowProfileInfoModal ? <ProfileInfoModal /> : null}
      {isProjectConfirmModalOpen && newProject ? (
        <ProjectConfirmModal
          isOpen={isProjectConfirmModalOpen}
          onClose={() => {
            setIsProjectConfirmModalOpen(false)
          }}
          project={newProject}
        />
      ) : null}
      {/* Bottom bar */}
      {shouldShowSDKBanner ? (
        <Box
          sx={{
            width: "calc(100% - 315px)",
            position: "absolute",
            bottom: 24,
            backgroundColor: "#31372B",
            pt: 1.75,
            pb: 1.75,
            pl: 3,
            pr: 1,
            ml: 2,
            borderRadius: 3,
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            <Trans>Seamlessly build blockchain-enabled games with our Web3.Unity SDK</Trans>
          </Typography>
          <Box display="flex">
            <a
              data-cy="button-banner-install-unity-package"
              href="https://github.com/ChainSafe/web3.unity/releases/latest"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" sx={{ mr: 1 }}>
                <Trans>Install Unity Package</Trans>
              </Button>
            </a>
            <Button
              data-cy="button-banner-close-install-unity-package"
              sx={{ pr: 4, pl: 2 }}
              onClick={onRemoveSDKBanner}
            >
              <Typography color="primary" sx={{ fontWeight: 500, fontSize: "14px" }}>
                <Trans>I already did</Trans>
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default DashboardPage
