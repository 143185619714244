import { Trans } from "@lingui/macro"
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded"
import { Box, Typography, Tooltip as TooltipMui } from "@mui/material"
import React from "react"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Cell } from "recharts"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { formatNumberWithMultiplier } from "../../../../utils/Helpers"

const DailyPlayerCalls: React.FC<{ data: { player: string; calls: number }[] }> = ({ data }) => {
  return (
    <Box
      width="100%"
      sx={{
        border: "1px solid #333333",
        borderRadius: 2,
        pt: 1,
        pb: 2,
        pr: 2,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" pt={1} mb={3}>
        <Box display="flex" gap={1} ml={3} alignItems="center">
          <Typography sx={{ fontSize: 18 }}>Method calls</Typography>
          <Typography color="#8C8C8C" variant="body2">
            by player ID
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <Box
            sx={{
              border: "1px solid #262626",
              borderRadius: 2,
              padding: "4px 6px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
            }}
          >
            <Typography variant="body2" color="#595959" fontWeight={500}>
              <Trans>Last 24 hrs</Trans>
            </Typography>
          </Box>
          <TooltipMui
            title="This graph is updated daily with the number of calls made by each player ID"
            arrow
            placement="top"
            data-cy="tooltip-method-calls-by-player-id"
          >
            <HelpOutlineRoundedIcon sx={{ color: "#8C8C8C" }} />
          </TooltipMui>
        </Box>
      </Box>
      <ResponsiveContainer width="100%" height={180}>
        {data.length ? (
          <BarChart id="chart-method-calls-by-player-id" data={data}>
            <XAxis
              dataKey="player"
              axisLine={false}
              tick={{ fill: "#8C8C8C", fontSize: "12px" }}
              tickLine={{ stroke: "transparent" }}
            />
            <YAxis
              tick={{ fill: "#8C8C8C", fontSize: "12px" }}
              tickLine={{ stroke: "transparent" }}
              axisLine={false}
              label={{
                value: "Number of calls",
                angle: -90,
                position: "insideBottomLeft",
                offset: 20,
                fill: "#D9D9D9",
                fontSize: "13px",
              }}
              interval="preserveStartEnd"
              tickFormatter={formatNumberWithMultiplier}
            />
            <Bar dataKey="calls" fill="#588E23" radius={[5, 5, 5, 5]}>
              {data.map((entry, index) => {
                const color =
                  index > 7
                    ? `rgba(49, 55, 43, ${data.length / (data.length + (index - 8) * 1.5)})`
                    : index > 3
                      ? `rgba(68, 91, 44, ${data.length / (data.length + (index - 4) * 1.5)})`
                      : `rgba(88, 142, 35, ${data.length / (data.length + index * 2)})`

                return <Cell key={`cell-${index}`} fill={color} />
              })}
            </Bar>
            <Tooltip
              content={({ label, payload }) => (
                <Box
                  sx={{
                    backgroundColor: "#141414",
                    border: "1px solid #B4FF68",
                    padding: "8px 12px 8px 12px",
                    borderRadius: 1,
                  }}
                >
                  <Typography color="#8C8C8C">Player {label}</Typography>
                  <Typography color="#DFF7C7">
                    {payload?.length ? payload[0].value : "-"} calls
                  </Typography>
                </Box>
              )}
              cursor={false}
              wrapperStyle={{ outline: "none" }}
            />
          </BarChart>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="75%"
            gap={1}
            data-cy="label-empty-method-calls-by-player-id"
          >
            <NoDataOrConnectionIcon />
            <Typography>
              <Trans>No calls made</Trans>
            </Typography>
            <Typography variant="body2" color="#8C8C8C">
              <Trans>Project analytics will show here once there is activity.</Trans>
            </Typography>
          </Box>
        )}
      </ResponsiveContainer>

      {data.length ? (
        <Box display="flex" justifyContent="center">
          <Typography variant="body2" color="#D9D9D9">
            Player "ID"
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}

export default DailyPlayerCalls
