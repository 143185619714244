import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { CssBaseline, Fade, ThemeProvider } from "@mui/material"
import "./app.css"
import { Web3OnboardProvider } from "@web3-onboard/react"
import { SnackbarProvider } from "notistack"

import { STORAGE_API, GAMING_API } from "./config"
import { CountlyContextProvider } from "./contexts/CountlyContext"
import { GamingApiProvider } from "./contexts/GamingApiContext"
import { LanguageProvider } from "./contexts/LanguageContext"
import { MarketplaceProvider } from "./contexts/MarketplaceContext"
import { NFTsProvider } from "./contexts/NFTsContext"
import { ProfileInfoProvider } from "./contexts/ProfileInfoContext"
import { ProjectApiProvider } from "./contexts/ProjectContext"
import { StorageApiProvider } from "./contexts/StorageContext"
import { Web3ConnectionContextProvider, web3Onboard } from "./contexts/Web3ConnectionContext"
import AppRoutes from "./routes/routes"
import { defaultTheme } from "./themes/theme"

const availableLanguages = [{ id: "en", label: "English" }]

export const muiCache = createCache({
  key: "mui",
  prepend: true,
})

const App = (): JSX.Element => {
  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={defaultTheme}>
        <CountlyContextProvider>
          <Web3OnboardProvider web3Onboard={web3Onboard}>
            <Web3ConnectionContextProvider>
              <CssBaseline />
              <LanguageProvider availableLanguages={availableLanguages}>
                <SnackbarProvider
                  maxSnack={3}
                  TransitionComponent={Fade}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                  autoHideDuration={5000}
                >
                  <StorageApiProvider apiUrl={STORAGE_API} withLocalStorage={true}>
                    <GamingApiProvider apiUrl={GAMING_API}>
                      <ProfileInfoProvider>
                        <ProjectApiProvider>
                          <NFTsProvider>
                            <MarketplaceProvider>
                              <AppRoutes />
                            </MarketplaceProvider>
                          </NFTsProvider>
                        </ProjectApiProvider>
                      </ProfileInfoProvider>
                    </GamingApiProvider>
                  </StorageApiProvider>
                </SnackbarProvider>
              </LanguageProvider>
            </Web3ConnectionContextProvider>
          </Web3OnboardProvider>
        </CountlyContextProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default App
