import styled from "@emotion/styled"
import { ContentCopy } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Link, Modal, Typography } from "@mui/material"
import { useSnackbar } from "notistack"

import type { NFTModal } from "../../../contexts/NFTsContext"
import { centerEllipsis, formatTokenTypeDash, getIPFSUri } from "../../../utils/Helpers"

const ModalWrapper = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "1045px",
  backgroundColor: "#262626",
  borderRadius: "12px",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  "&:focus-visible": {
    outline: "none",
  },
  h3: {
    fontWeight: 400,
    fontSize: "32px",
  },
  h4: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#AFAFAF",
    marginTop: "20px",
  },
  fontWeight: 500,
})

const ModalLayout = styled("div")({
  backgroundColor: "#262626",
  border: "1px solid #595959",
  padding: "20px",
  borderRadius: "12px",
  display: "grid",
  gridTemplateColumns: "repeat(2, 482px)",
  justifyContent: "space-between",
})

const DataField = styled("div")({
  "p:first-child": {
    color: "#AFAFAF",
  },
  p: {
    color: "#F5F5F5",
    display: "flex",
    fontWeight: 500,
  },
  marginBottom: "8px",
  "&.copy": {
    cursor: "pointer",
    svg: {
      height: "14px",
    },
    "&:hover": {
      filter: "opacity(0.8)",
    },
    "&:active": {
      filter: "opacity(1)",
    },
  },
})

const DetailsGrid = styled("div")<{ gridColumns: number; width: string }>(
  ({ gridColumns, width }) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${gridColumns}, ${width})`,
    justifyContent: "space-between",
  })
)

const NFTImage = styled("img")({
  objectFit: "contain",
  maxWidth: "484px",
})

const Line = styled("div")({
  width: "100%",
  borderBottom: "1ps solid #AFAFAF",
  margin: "6px 0",
})

const XIcon = styled("div")({
  position: "absolute",
  right: "10px",
  top: "10px",
  cursor: "pointer",
  "&:hover": {
    filter: "opacity(0.8)",
  },
  "&:active": {
    filter: "opacity(1)",
  },
})

type NFTModalProps = {
  nft?: NFTModal
  onClose: () => void
}

export function NFTModal({ nft, onClose }: NFTModalProps): JSX.Element | null {
  const { enqueueSnackbar } = useSnackbar()
  if (!nft) return null

  return (
    <Modal open={!!nft} onClose={onClose}>
      <ModalWrapper>
        <XIcon data-cy="button-nft-details-close" onClick={onClose}>
          <CloseIcon />
        </XIcon>
        <ModalLayout>
          <NFTImage
            data-cy="image-nft-details-logo"
            src={nft.metadata?.image ? getIPFSUri(nft.metadata.image) : undefined}
            alt={nft.metadata?.name}
          />
          <Box>
            <Typography variant="h3" data-cy="label-nft-details-name">
              {nft.metadata?.name}
            </Typography>
            {nft.metadata?.description?.length && (
              <DataField>
                <Typography variant="body2">Description</Typography>
                <Typography variant="body2" data-cy="label-nft-details-description">
                  {nft.metadata?.description}
                </Typography>
              </DataField>
            )}
            <Typography variant="h4">Details</Typography>
            <Line />
            <DetailsGrid width="120px" gridColumns={2}>
              <DataField className="copy">
                <Typography variant="body2">Contract Address</Typography>
                <Typography
                  variant="body2"
                  onClick={() => {
                    enqueueSnackbar("NFT address copied", { variant: "success" })
                    navigator.clipboard.writeText(nft.contract_address)
                  }}
                  data-cy="button-nft-details-copy-contract-address"
                >
                  {centerEllipsis(nft.contract_address)} <ContentCopy />
                </Typography>
              </DataField>
              <DataField>
                <Typography variant="body2">Token Standard</Typography>
                <Typography variant="body2" data-cy="label-nft-details-token-type">
                  {formatTokenTypeDash(nft.token_type)}
                </Typography>
              </DataField>
              <DataField>
                <Typography variant="body2">Metadata URL</Typography>
                <Typography variant="body2">
                  <Link target="_blank" href={nft.uri} data-cy="button-nft-details-metadata">
                    View
                  </Link>
                </Typography>
              </DataField>
              <DataField>
                <Typography variant="body2">Token ID</Typography>
                <Typography variant="body2" data-cy="label-nft-details-token-id">
                  {nft.token_id}
                </Typography>
              </DataField>
            </DetailsGrid>
            {nft.metadata?.attributes?.length && (
              <>
                <Typography variant="h4">Attributes</Typography>
                <Line />
                <DetailsGrid gridColumns={3} width="100px">
                  {nft.metadata.attributes.map((att) => (
                    <DataField>
                      <Typography variant="body2" data-cy="label-nft-details-attribute-key">
                        {att.trait_type}
                      </Typography>
                      <Typography variant="body2" data-cy="label-nft-details-attribute-value">
                        {att.value}
                      </Typography>
                    </DataField>
                  ))}
                </DetailsGrid>
              </>
            )}
          </Box>
        </ModalLayout>
      </ModalWrapper>
    </Modal>
  )
}
