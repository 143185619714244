import injectedModule from "@web3-onboard/injected-wallets"
import { useConnectWallet, init } from "@web3-onboard/react"
import walletConnectModule from "@web3-onboard/walletconnect"
import axios from "axios"
import { ethers } from "ethers"
import React, { useEffect, useMemo, useState } from "react"

import { GAMING_API, WALLET_CONNECT_PROJECT_ID } from "../config"
import type { Network } from "../utils/NetworkHelpers"
import { initialNetworks } from "../utils/NetworkHelpers"

import { useCountly } from "./CountlyContext"

const injected = injectedModule()

const walletConnect = walletConnectModule({
  version: 2,
  projectId: WALLET_CONNECT_PROJECT_ID,
})

const wallets = [injected, walletConnect]

export const web3Onboard = init({
  wallets,
  chains: initialNetworks.map((n) => ({
    id: n.chainId,
    token: n.token,
    label: n.label,
    rpcUrl: n.rpcUrl,
    namespace: "evm",
  })),
})

export type Web3ConnectionContextType = {
  address: string | undefined
  isLoggedIn: boolean
  provider?: ethers.providers.Web3Provider
  signer?: ethers.providers.JsonRpcSigner
  network?: Network
  networks: Network[]
}

type NetworkConfig = {
  chains: Record<
    string,
    {
      id: number
      minter_factory: string
      marketplace_factory: string
    }
  >
}

const configApi = "/v1/config"

type Web3ConnectionContextProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

const Web3ConnectionContext = React.createContext<Web3ConnectionContextType | undefined>(undefined)

const Web3ConnectionContextProvider = ({
  children,
}: Web3ConnectionContextProviderProps): JSX.Element | null => {
  const [{ wallet }] = useConnectWallet()
  const { updateWeb3Profile } = useCountly()

  const [networks, setNetworks] = useState(initialNetworks)

  // set up networks from config API
  useEffect(() => {
    axios
      .get<NetworkConfig>(`${GAMING_API}${configApi}`)
      .then(({ data }) => {
        const networksConfigData = Object.values(data.chains)
        setNetworks(
          initialNetworks.map((initialNetwork) => ({
            ...initialNetwork,
            collectionFactoryAddress: networksConfigData.find(
              (networkConfig) => networkConfig.id === initialNetwork.chainId
            )?.minter_factory,
            marketplaceFactoryAddress: networksConfigData.find(
              (networkConfig) => networkConfig.id === initialNetwork.chainId
            )?.marketplace_factory,
          }))
        )
      })
      .catch(console.error)
  }, [])

  const walletValues = useMemo(() => {
    const provider = wallet ? new ethers.providers.Web3Provider(wallet.provider, "any") : undefined
    return {
      address: wallet?.accounts[0].address,
      isLoggedIn: !!wallet?.accounts[0].address,
      provider,
      signer: provider?.getSigner(0),
      network: wallet?.chains[0].id
        ? networks.find((n) => n.chainId === parseInt(wallet?.chains[0].id, 16))
        : undefined,
    }
  }, [wallet, networks])

  // set tracking states
  useEffect(() => {
    updateWeb3Profile(walletValues.address || "", walletValues.network?.label || "")
  }, [walletValues, updateWeb3Profile])

  return (
    <Web3ConnectionContext.Provider value={{ ...walletValues, networks }}>
      {children}
    </Web3ConnectionContext.Provider>
  )
}

function useWeb3Connection(): Web3ConnectionContextType {
  const context = React.useContext(Web3ConnectionContext)
  if (context === undefined) {
    throw new Error("useWeb3Connection must be used within a Web3ConnectionContext")
  }
  return context
}

export { Web3ConnectionContextProvider, useWeb3Connection }
