import { Trans } from "@lingui/macro"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Box, Button, Card, Chip, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React, { useState } from "react"

import type { IProject } from "../../../contexts/ProjectContext"

import DeleteProjectAlert from "./DeleteProjectAlert"

interface IProjectGrid {
  projects: IProject[]
  setProjectToEdit: (project: IProject) => void
}

const ProjectGrid: React.FC<IProjectGrid> = ({ projects, setProjectToEdit }) => {
  const [deletePending, setDeletePending] = useState<IProject | undefined>()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <Box
        display="grid"
        sx={(theme) => ({
          gridTemplateColumns: "1fr 1fr 1fr",
          gridColumnGap: 20,
          gridRowGap: 20,
          [theme.breakpoints.down("lg")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
          },
        })}
      >
        {projects.map((project) => (
          <Card
            key={project.projectId}
            sx={{
              borderRadius: 3,
              p: 2.5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box data-cy="label-projects-table-created-at">
                <Typography variant="caption" color="#8C8C8C" sx={{ mr: 1 }}>
                  {dayjs(project.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box data-cy="label-projects-table-name">
                <Typography variant="h6" sx={{ mr: 1, color: "#F5F5F5", fontSize: 20 }}>
                  {project.name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" data-cy="label-projects-table-id">
                <Typography color="#8C8C8C" variant="body2" sx={{ mt: 1, mb: 1, fontSize: 16 }}>
                  {project.projectId}
                </Typography>
                <ContentCopyIcon
                  data-cy="button-projects-table-copy-id"
                  onClick={() => {
                    navigator.clipboard.writeText(project.projectId)
                    enqueueSnackbar("Project ID copied", { variant: "success" })
                  }}
                  color="primary"
                  sx={{ fontSize: 14, ml: 1.5, cursor: "pointer" }}
                />
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1} mt={1.5} mb={2}>
                {project.targetPlatforms?.map((t) => (
                  <Chip
                    data-cy="label-projects-table-target-platforms"
                    key={t}
                    label={
                      <Typography color="#F5F5F5" variant="body2">
                        {t}
                      </Typography>
                    }
                    variant="outlined"
                    clickable={true}
                    color="primary"
                    sx={{ borderWidth: "1.5px" }}
                  />
                ))}
              </Box>
            </Box>
            <Box>
              {project.isActive && (
                <Button
                  onClick={() => setDeletePending(project)}
                  variant="text"
                  size="small"
                  sx={{ minWidth: 40, p: 0 }}
                  data-cy="button-projects-table-delete"
                >
                  <Typography color="#8C8C8C" sx={{ fontSize: 14, fontWeight: 500 }}>
                    <Trans>Delete</Trans>
                  </Typography>
                </Button>
              )}
              <Button
                onClick={() => setProjectToEdit(project)}
                variant="text"
                size="small"
                sx={{ minWidth: 56, p: 0 }}
                data-cy="button-projects-table-edit"
              >
                <Typography color="#8C8C8C" sx={{ fontSize: 14, fontWeight: 500 }}>
                  <Trans>Edit</Trans>
                </Typography>
              </Button>
            </Box>
          </Card>
        ))}
      </Box>
      {!projects.length ? (
        <Box display="flex" justifyContent="center" mt={8} color="#7f7f7f">
          <Typography>No projects to show</Typography>
        </Box>
      ) : null}
      {deletePending && (
        <DeleteProjectAlert
          projectId={deletePending.projectId}
          projectName={deletePending.name}
          handleClose={() => setDeletePending(undefined)}
        />
      )}
    </>
  )
}

export default ProjectGrid
