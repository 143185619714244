import { IPFS_GATEWAY } from "../config"

export const centerEllipsis = (address: string, remaining = 6): string => {
  if (address.length <= remaining * 2) {
    return address
  }
  return `${address.substring(0, remaining)}...${address.substring(address.length - remaining)}`
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// format number with comma
export const formatNumberWithComma = (value: number, numDecimals = 2): string => {
  return value.toLocaleString("en-US", {
    useGrouping: true,
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  })
}

export const formatNumberWithMultiplier = (value: number): string => {
  if (value > 10 ** 12) return `${Math.floor(value / 10 ** 12)}T`
  if (value > 10 ** 9) return `${Math.floor(value / 10 ** 9)}B`
  if (value > 10 ** 6) return `${Math.floor(value / 10 ** 6)}M`
  if (value > 10 ** 3) return `${Math.floor(value / 10 ** 3)}K`
  return `${value}`
}

export const truncateDecimals = (value: string, maxDecimalDigits: number): string => {
  if (value.includes(".")) {
    const parts = value.split(".")
    return parts[0] + "." + parts[1].slice(0, maxDecimalDigits)
  }
  return value
}

export const roundToDecimalPlaces = (num: number, decimalPlaces: number): number => {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces
}

export const getIPFSUri = (uri: string): string => {
  if (uri.startsWith("ipfs://")) {
    // old uri with ipfs gateway
    return `${IPFS_GATEWAY}/ipfs/${uri.replace("ipfs://", "")}`
  } else if (!uri.includes("://")) {
    // pure CID
    return `${IPFS_GATEWAY}/ipfs/${uri}`
  }
  return uri
}

export const delay = function (timeMilliSeconds: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setInterval(() => {
      resolve()
    }, timeMilliSeconds)
  })
}

export const formatTokenTypeDash = (tokenType: string): string => {
  if (tokenType.includes("ERC-")) return tokenType
  else {
    const arr = Array.from(tokenType)
    arr.splice(3, 0, "-").join("")
    return arr.toString().replaceAll(",","")
  }
}
