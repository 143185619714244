import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import type { NFTTokenType } from "../../../../contexts/NFTsContext"
import { useNFTs } from "../../../../contexts/NFTsContext"

import { NFTCard } from "./NFTCard"

const NFTsList: React.FC = () => {
  const { getCollectionNFTs, openNFTModal } = useNFTs()
  const [hasLoadedNFTs, setHasLoadedNFTs] = useState(false)
  const [NFTTokens, setNFTTokens] = useState<NFTTokenType[]>([])
  const { collectionId } = useParams<{ collectionId: string }>()

  useEffect(() => {
    if (!collectionId) return
    setNFTTokens([])
    getCollectionNFTs(collectionId)
      .then((NFTs) => {
        setNFTTokens(NFTs)
        setHasLoadedNFTs(true)
      })
      .catch(() => {
        setHasLoadedNFTs(true)
      })
  }, [getCollectionNFTs, collectionId])

  if (!hasLoadedNFTs) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (hasLoadedNFTs && !NFTTokens.length) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Box
          width={400}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
          sx={{ border: "1px solid #333333", borderRadius: "8px", background: "#141414" }}
          data-cy="box-nfts-list-empty"
        >
          <NoDataOrConnectionIcon />
          <Typography fontSize={20} mt={2}>
            <Trans>No NFTs yet</Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="grid"
      gap={2}
      sx={(theme) => ({
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "1fr 1fr 1fr",
        },
        [theme.breakpoints.up("xl")]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },
      })}
    >
      {NFTTokens?.map((n) => (
        <NFTCard onDetailsClick={(nft) => openNFTModal(nft)} key={n.token_id} NFTToken={n} />
      ))}
    </Box>
  )
}

export default NFTsList
