import { Trans } from "@lingui/macro"
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useConnectWallet, useSetChain } from "@web3-onboard/react"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import type { Marketplace } from "../../../../contexts/MarketplaceContext"
import { useMarketplace } from "../../../../contexts/MarketplaceContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../../routes/routes"
import FailedDeploymentModal from "../../../elements/FailedDeploymentModal"
import ImageInput from "../../../elements/ImageInput"
import { SoftButton } from "../../../elements/SoftButton"

const MarketplaceCreate: React.FC = () => {
  const { isLoggedIn, network } = useWeb3Connection()
  const [, connect] = useConnectWallet()
  const [, setChain] = useSetChain()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const {
    marketplaceProject,
    marketplaceNetworks,
    createMarketplace,
    deleteMarketplace,
    createMarketplaceOnChain,
  } = useMarketplace()

  const [isLoadingCreateMarketplace, setIsLoadingCreateMarketplace] = useState(false)
  const [isLoadingDeleteMarketplace, setIsLoadingDeleteMarketplace] = useState(false)
  const [isLoadingRedeployMarketplace, setIsLoadingRedeployMarketplace] = useState(false)
  const [failedMarketplace, setFailedMarketplace] = useState<Marketplace | undefined>()

  const [bannerImages, setBannerImages] = useState<File[]>([])

  const [marketplaceName, setMarketplaceName] = useState("")
  const [marketplaceDescription, setMarketplaceDescription] = useState("")
  const [marketplaceChainId, setMarketplaceChainId] = useState<number>(
    marketplaceNetworks[0].chainId
  )

  useEffect(() => {
    if (network) {
      setMarketplaceChainId(
        marketplaceNetworks.find((mN) => mN.chainId === network.chainId)?.chainId ||
          marketplaceNetworks[0].chainId
      )
    }
  }, [network, marketplaceNetworks])

  const onChangeMarketplaceChain = (chainId: number): void => {
    setMarketplaceChainId(chainId)
    if (isLoggedIn) {
      setChain({ chainId: `0x${chainId.toString(16)}` })
    }
  }

  const onSubmitMarketplace = async (): Promise<void> => {
    // initial validation
    if (!marketplaceName.trim()) {
      enqueueSnackbar("Please provide a marketplace name", {
        variant: "error",
      })
      return
    }

    if (!marketplaceProject?.projectId) return

    try {
      // make sure to be on the correct chain
      if (network?.chainId !== marketplaceChainId) {
        const wasNetworkSet = await setChain({ chainId: `0x${marketplaceChainId.toString(16)}` })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }

      setIsLoadingCreateMarketplace(true)

      // creating marketplace on the API
      createMarketplace({
        name: marketplaceName,
        description: marketplaceDescription,
        chain_id: marketplaceChainId,
        banner: bannerImages.length ? bannerImages[0] : undefined,
      })
        .then((newMarketplace) => {
          if (!newMarketplace) return

          // creating on chain marketplace
          createMarketplaceOnChain(newMarketplace)
            .then(async () => {
              enqueueSnackbar("New marketplace created", {
                variant: "success",
                id: "toast-success-create-marketplace",
              })
              navigate(ROUTE_LINKS.marketplacesOnProject(marketplaceProject?.projectId))
            })
            .catch((err) => {
              setFailedMarketplace(newMarketplace)
              console.error(err)
              enqueueSnackbar("Failed to create marketplace", {
                variant: "error",
                id: "toast-error-create-marketplace",
              })
            })
            .finally(() => {
              setIsLoadingCreateMarketplace(false)
            })
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar("Failed to create marketplace", {
            variant: "error",
            id: "toast-error-create-marketplace",
          })
          setIsLoadingCreateMarketplace(false)
        })
    } catch (err) {
      console.error(err)
      setIsLoadingCreateMarketplace(false)
      enqueueSnackbar("Failed to create marketplace", {
        variant: "error",
        id: "toast-error-create-marketplace",
      })
    }
  }

  const onDeleteFailedMarketplace = (): void => {
    if (!failedMarketplace || !marketplaceProject) return
    setIsLoadingDeleteMarketplace(true)
    deleteMarketplace(marketplaceProject.projectId, failedMarketplace.id)
      .then(() => {
        enqueueSnackbar("Marketplace deleted from records", {
          variant: "success",
          id: "toast-success-delete-marketplace",
        })
        navigate(ROUTE_LINKS.marketplacesOnProject(marketplaceProject?.projectId))
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to delete marketplace", { variant: "error" })
      })
      .finally(() => {
        setIsLoadingDeleteMarketplace(false)
      })
  }

  const onRedeployFailedMarketplace = (): void => {
    if (!failedMarketplace || !marketplaceProject) return
    setIsLoadingRedeployMarketplace(true)
    createMarketplaceOnChain(failedMarketplace)
      .then(() => {
        enqueueSnackbar("New marketplace created and deployed", {
          variant: "success",
          id: "toast-success-redeploy-marketplace",
        })
        navigate(ROUTE_LINKS.marketplacesOnProject(marketplaceProject?.projectId))
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to deploy marketplace", { variant: "error" })
      })
      .finally(() => {
        setIsLoadingRedeployMarketplace(false)
      })
  }

  return (
    <Box display="flex" justifyContent="center" mt={4} mb={16}>
      <Box
        width={960}
        sx={{ borderRadius: "8px", border: "1px solid #262626", background: "#141414" }}
      >
        <Box p={4} pb={2}>
          <Typography fontSize={28}>
            <Trans>Deploy a New Marketplace Contract</Trans>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <Trans>Create a new marketplace smart contract for your project.</Trans>
          </Typography>
        </Box>
        <Divider />
        <Box p={4}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="grid"
              gap={2}
              sx={{
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <TextField
                label="Contract Name"
                size="small"
                value={marketplaceName}
                onChange={(e) => setMarketplaceName(e.target.value)}
                inputProps={{ maxLength: 100 }}
                data-cy="input-marketplace-contract-name"
              />
              <FormControl size="small">
                <InputLabel>Network</InputLabel>
                <Select
                  label="network"
                  size="small"
                  value={marketplaceChainId}
                  onChange={(e) => onChangeMarketplaceChain(e.target.value as number)}
                  data-cy="dropdown-marketplace-network"
                >
                  {marketplaceNetworks?.map((n) => (
                    <MenuItem
                      key={n.label}
                      value={n.chainId}
                      data-cy={`dropdown-option-marketplace-network-${n.chainId}`}
                    >
                      {n.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Description (Optional)"
              multiline
              rows={2}
              size="small"
              inputProps={{ maxLength: 2000 }}
              value={marketplaceDescription}
              onChange={(e) => setMarketplaceDescription(e.target.value)}
              data-cy="input-marketplace-description"
            />
          </Box>
        </Box>
        <Divider />
        <Box mt={3} pl={4} pr={4} mb={4}>
          <Typography color="#DFF7C7" sx={{ mb: 1.5 }}>
            <Trans>Marketplace Image</Trans>
          </Typography>
          <Typography fontSize="13px" sx={{ mb: 1 }}>
            <Trans>Cover Image (recommended min. 640 x 240 px)</Trans>
          </Typography>
          <ImageInput images={bannerImages} setImages={setBannerImages} testId="marketplace" />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={3} pr={4} gap={2}>
          <Button
            onClick={() => {
              if (marketplaceProject?.projectId) {
                navigate(ROUTE_LINKS.marketplacesOnProject(marketplaceProject.projectId))
              }
            }}
            sx={{ background: "#262626", color: "#FFFFFF", pl: 2, pr: 2 }}
            data-cy="button-marketplace-cancel"
          >
            <Trans>Cancel</Trans>
          </Button>
          {isLoggedIn ? (
            <SoftButton
              loading={isLoadingCreateMarketplace}
              onClick={onSubmitMarketplace}
              disabled={!marketplaceName.trim()}
              data-cy="button-marketplace-create-marketplace"
            >
              <Trans>Create marketplace</Trans>
            </SoftButton>
          ) : (
            <SoftButton onClick={() => connect()} data-cy="button-marketplace-connect-wallet">
              <Trans>Connect wallet</Trans>
            </SoftButton>
          )}
        </Box>
      </Box>
      <FailedDeploymentModal
        type="marketplace"
        isOpen={!!failedMarketplace}
        onDelete={onDeleteFailedMarketplace}
        onRedeploy={onRedeployFailedMarketplace}
        isLoadingDelete={isLoadingDeleteMarketplace}
        isLoadingRedeploy={isLoadingRedeployMarketplace}
      />
    </Box>
  )
}

export default MarketplaceCreate
