import { Box, Typography } from "@mui/material"
import React from "react"

interface IManageLootboxHeaderProps {
  step?: string
  heading: string
  subtitle?: string
}

const ManageLootboxHeader: React.FC<IManageLootboxHeaderProps> = ({ step, heading, subtitle }) => {
  return (
    <Box pt={3} pb={3} pl={4} pr={4} width="100%">
      <Box display="flex" gap={1.5}>
        {step ? (
          <Box
            sx={{ border: "1px solid #99E54D", borderRadius: "5px", pl: 1.5, pr: 1.5 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color="#99E54D" variant="body2">
              {step}
            </Typography>
          </Box>
        ) : null}
        <Typography fontSize={24} color="#F5F5F5">
          {heading}
        </Typography>
      </Box>
      {subtitle ? (
        <Typography mt={2} color="#BFBFBF">
          {subtitle}
        </Typography>
      ) : null}
    </Box>
  )
}

export default ManageLootboxHeader
