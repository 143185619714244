import { Trans } from "@lingui/macro"
import { ArrowOutward, Close } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"

interface ITopBannerProps {
  onClose: () => void
}

export const TopBanner = ({ onClose }: ITopBannerProps): JSX.Element => {
  return (
    <Box
      sx={{ background: "#4C692E", zIndex: 10000, position: "relative" }}
      display="flex"
      p={2}
      pr={3}
    >
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Typography fontSize={16}>
          <Trans>Influence the CSG SDK and enter into a prize draw by taking our survey!</Trans>
        </Typography>
        <a
          href="https://share.hsforms.com/1OsS5W_L3TAeh2_Y-oUPgCw4kcdm"
          target="_blank"
          style={{ textDecoration: "none", display: "flex" }}
          data-cy="button-banner-redirect"
        >
          <Typography
            fontWeight={500}
            color="#FFFFFF"
            sx={{ pl: 1.25, pr: 0.25, textDecoration: "none" }}
          >
            <Trans>Enter to win</Trans>
          </Typography>
          <ArrowOutward sx={{ color: "#FFFFFF", mt: 0.25 }} fontSize="small" />
        </a>
      </Box>
      <Box>
        <IconButton sx={{ p: 0 }} onClick={onClose} data-cy="button-banner-close">
          <Close fontSize="small" sx={{ p: 0 }} />
        </IconButton>
      </Box>
    </Box>
  )
}
