import { Box, Typography } from "@mui/material"
import React from "react"

import NFTsList from "./NFTs/NFTsList"

const CollectionNFTs: React.FC = () => {
  return (
    <Box>
      <Typography sx={{ pl: 2, fontSize: "24px" }}>NFTs</Typography>
      <Box ml={2} mr={2} mt={2} mb={10}>
        <NFTsList />
      </Box>
    </Box>
  )
}

export default CollectionNFTs
