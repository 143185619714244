import { Trans } from "@lingui/macro"
import { Typography, Box, Divider } from "@mui/material"
import React from "react"

import DeployLootbox from "../../components/modules/addOns/LootBox/DeployLootbox/DeployLootbox"

const LootboxDeployPage = (): JSX.Element => {
  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" alignItems="center" mb={0.5} flex={1}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>Deploy new Loot Box</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <DeployLootbox />
    </Box>
  )
}

export default LootboxDeployPage
