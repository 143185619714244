import React from "react"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"

import { DashboardLayout } from "../components/layouts/DashboardLayout"
import { LootboxDashboardLayout } from "../components/layouts/LootboxDashboardLayout"
import { MarketplacesDashboardLayout } from "../components/layouts/MarketplacesDashboardLayout"
import { NFTsDashboardLayout } from "../components/layouts/NFTsDashboardLayout"
import AddOnsPage from "../pages/AddOnsPage"
import AnalyticsPage from "../pages/AnalyticsPage"
import DashboardPage from "../pages/DashboardPage"
import LandingPage from "../pages/LandingPage"
import NFTsCollectionPage from "../pages/NFTPages/NFTsCollectionPage"
import NFTsCreateCollectionPage from "../pages/NFTPages/NFTsCreateCollectionPage"
import NFTLaunchpadPage from "../pages/NFTPages/NFTsLaunchpadPage"
import NFTsMintNFTPage from "../pages/NFTPages/NFTsMintNFTPage"
import NFTsProjectPage from "../pages/NFTPages/NFTsProjectPage"
import RPCNodesPage from "../pages/RPCNodesPage"
import ProfilePage from "../pages/SettingsPage"
import LootboxDeployPage from "../pages/lootboxPages/LootboxDeployPage"
import LootboxLandingPage from "../pages/lootboxPages/LootboxLandingPage"
import LootboxManagePage from "../pages/lootboxPages/LootboxManagePage"
import LootboxOpenPage from "../pages/lootboxPages/LootboxOpenPage"
import MarketplaceCreatePage from "../pages/marketplacePages/MarketplaceCreatePage"
import MarketplaceListItemPage from "../pages/marketplacePages/MarketplaceListItemPage"
import MarketplaceViewPage from "../pages/marketplacePages/MarketplaceViewPage"
import MarketplacesBasePage from "../pages/marketplacePages/MarketplacesBasePage"
import MarketplacesOnProjectPage from "../pages/marketplacePages/MarketplacesOnProjectPage"

import AuthLayout from "./AuthLayout"

export const ROUTE_LINKS = {
  Landing: "/",
  Dashboard: "/dashboard",
  // analytics
  Analytics: "/analytics",
  AnalyticsOnProject: (projectId: string) => `/analytics/${projectId}`,
  // settings
  Settings: "/settings",
  // add ons
  AddOns: "/addons",

  // lootboxes
  Lootboxes: "/addons/lootboxes",
  DeployLootbox: "/addons/lootboxes/deploy",
  ManageLootbox: (lootboxAddress: string) => `/addons/lootboxes/${lootboxAddress}/manage`,
  OpenLootbox: (lootboxAddress: string) => `/addons/lootboxes/${lootboxAddress}/open`,
  ConfigureLootbox: (lootboxAddress: string) => `/addons/lootboxes/${lootboxAddress}/configure`,
  RPCNodes: "/RPCNodes",

  // NFT launchpad
  NFTs: "/nfts",
  NFTsOnProject: (projectId: string) => `/nfts/${projectId}`,
  NFTsOnCollection: (projectId: string, collectionId: string) =>
    `/nfts/${projectId}/collections/${collectionId}/view`,
  NFTsCollectionNewImport: (projectId: string) => `/nfts/${projectId}/collections/new/import`,
  NFTsCollectionNewCreate: (projectId: string) => `/nfts/${projectId}/collections/new/create`,
  NFTsNFTCreate: (projectId: string, collectionId: string) =>
    `/nfts/${projectId}/collections/${collectionId}/nfts/new`,

  // marketplace
  marketplaces: "/marketplaces",
  marketplacesOnProject: (projectId: string) => `/marketplaces/${projectId}`,
  marketplacesCreate: (projectId: string) => `/marketplaces/${projectId}/create`,
  marketplaceView: (projectId: string, marketplaceId: string) =>
    `/marketplaces/${projectId}/${marketplaceId}/view`,
  marketplaceListNFT: (projectId: string, marketplaceId: string) =>
    `/marketplaces/${projectId}/${marketplaceId}/list`,

  // policies and others
  PrivacyPolicy: "https://gaming.chainsafe.io/privacy-policy",
  TermsOfService: "https://gaming.chainsafe.io/terms-of-service",
  ChainSafe: "https://chainsafe.io/",
  Docs: "https://docs.gaming.chainsafe.io/",
  DocsNFTLaunchpad: "https://docs.gaming.chainsafe.io/launchpad/introduction",
  DocsMarketplace: "https://docs.gaming.chainsafe.io/marketplace/introduction",
  Discord: "https://discord.com/invite/Q6A3YA2",
  BookCall: "https://calendly.com/colinschwarz/30min-1",
}

const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route path={ROUTE_LINKS.Landing} element={<LandingPage />} />

        {/* Dashboard */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.Dashboard}
            element={
              <DashboardLayout>
                <DashboardPage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* Analytics */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.Analytics}
            element={
              <DashboardLayout>
                <AnalyticsPage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* Analytics on project */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.AnalyticsOnProject(":projectId")}
            element={
              <DashboardLayout>
                <AnalyticsPage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* Profile */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.Settings}
            element={
              <DashboardLayout>
                <ProfilePage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* Add Ons */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.AddOns}
            element={
              <DashboardLayout>
                <AddOnsPage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* Add on : VRF lootbox */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.Lootboxes}
            element={
              <DashboardLayout>
                <LootboxDashboardLayout>
                  <LootboxLandingPage />
                </LootboxDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* VRF lootbox: deploy */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.DeployLootbox}
            element={
              <DashboardLayout>
                <LootboxDashboardLayout>
                  <LootboxDeployPage />
                </LootboxDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* VRF lootbox: manage */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.ManageLootbox(":lootboxAddress")}
            element={
              <DashboardLayout>
                <LootboxDashboardLayout>
                  <LootboxManagePage />
                </LootboxDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* VRF lootbox: configure */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.ConfigureLootbox(":lootboxAddress")}
            element={
              <DashboardLayout>
                <LootboxDashboardLayout>
                  <LootboxManagePage isConfigure={true} />
                </LootboxDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* VRF lootbox: open */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.OpenLootbox(":lootboxAddress")}
            element={
              <DashboardLayout>
                <LootboxDashboardLayout>
                  <LootboxOpenPage />
                </LootboxDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* RPC Nodes */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.RPCNodes}
            element={
              <DashboardLayout>
                <RPCNodesPage />
              </DashboardLayout>
            }
          />
        </Route>

        {/* NFT Launchpad base page */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.NFTs}
            element={
              <DashboardLayout>
                <NFTsDashboardLayout>
                  <NFTLaunchpadPage />
                </NFTsDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* NFT Launchpad on project */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.NFTsOnProject(":projectId")}
            element={
              <DashboardLayout>
                <NFTsDashboardLayout>
                  <NFTsProjectPage />
                </NFTsDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* NFT create collection */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.NFTsCollectionNewCreate(":projectId")}
            element={
              <DashboardLayout>
                <NFTsDashboardLayout>
                  <NFTsCreateCollectionPage />
                </NFTsDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* NFT collection page */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.NFTsOnCollection(":projectId", ":collectionId")}
            element={
              <DashboardLayout>
                <NFTsDashboardLayout>
                  <NFTsCollectionPage />
                </NFTsDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* NFT mint NFT page */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.NFTsNFTCreate(":projectId", ":collectionId")}
            element={
              <DashboardLayout>
                <NFTsDashboardLayout>
                  <NFTsMintNFTPage />
                </NFTsDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* Marketplaces base page */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.marketplaces}
            element={
              <DashboardLayout>
                <MarketplacesDashboardLayout>
                  <MarketplacesBasePage />
                </MarketplacesDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* Marketplaces on project */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.marketplacesOnProject(":projectId")}
            element={
              <DashboardLayout>
                <MarketplacesDashboardLayout>
                  <MarketplacesOnProjectPage />
                </MarketplacesDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* Marketplace create */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.marketplacesCreate(":projectId")}
            element={
              <DashboardLayout>
                <MarketplacesDashboardLayout>
                  <MarketplaceCreatePage />
                </MarketplacesDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* Marketplace view */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.marketplaceView(":projectId", ":marketplaceId")}
            element={
              <DashboardLayout>
                <MarketplacesDashboardLayout>
                  <MarketplaceViewPage />
                </MarketplacesDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>

        {/* Marketplace list item page */}
        <Route element={<AuthLayout />}>
          <Route
            path={ROUTE_LINKS.marketplaceListNFT(":projectId", ":marketplaceId")}
            element={
              <DashboardLayout>
                <MarketplacesDashboardLayout>
                  <MarketplaceListItemPage />
                </MarketplacesDashboardLayout>
              </DashboardLayout>
            }
          />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes
