import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import type { ReactNode } from "react"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useNFTs } from "../../contexts/NFTsContext"
import { useProjectApi } from "../../contexts/ProjectContext"
import { ROUTE_LINKS } from "../../routes/routes"
import { SupportPopup } from "../elements/Banners/SupportPopup"

export const NFTsDashboardLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const { projects } = useProjectApi()
  const { NFTsProject, setNFTsProject, getCollection, hasLoadedCollection, NFTsCollection } =
    useNFTs()
  const navigate = useNavigate()

  const { projectId } = useParams<{ projectId: string }>()
  const { collectionId } = useParams<{ collectionId: string }>()

  useEffect(() => {
    if (projectId && NFTsProject?.projectId !== projectId) {
      const project = projects?.find((p) => p.projectId === projectId)
      if (project) setNFTsProject(project)
    } else if (projectId && !projects?.find((p) => p.projectId === projectId)) {
      setNFTsProject(undefined)
      navigate(ROUTE_LINKS.NFTs)
    }
  }, [projectId, projects, setNFTsProject, NFTsProject, navigate])

  useEffect(() => {
    if (collectionId && NFTsCollection?.id !== collectionId) getCollection(collectionId)
  }, [collectionId, getCollection, NFTsCollection])

  if (collectionId && !hasLoadedCollection) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (
    collectionId &&
    hasLoadedCollection &&
    (!NFTsCollection || !NFTsCollection.contract_address)
  ) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Typography>
          <Trans>Collection not found</Trans>
        </Typography>
      </Box>
    )
  }

  return (
    <Box mb={32}>
      {children}
      <SupportPopup />
    </Box>
  )
}
