import type { User } from "@chainsafe/files-api-client"
import Countly from "countly-sdk-web"
import React, { useCallback, useState } from "react"

type CountlyContextProps = {
  children: React.ReactNode
}

type CountlySegmentData = {
  user_id?: string | number
  project_id?: string | number
  collection_id?: string | number
  marketplace_id?: string | number
  lootbox_address?: string | number
  user_address?: string | number
  nft_address?: string | number
  network?: string | number
} & {
  [key: string]: string | number | undefined
}

type CountlyAddEventData = {
  key: string
  segmentation?: CountlySegmentData
}

type CountlyContextType = {
  trackPageView: (pageName: string) => void
  trackEvent: (data: CountlyAddEventData) => void
  updateProfileOnLogin: (user: User) => void
  updateTrackedProfile: (name: string) => void
  updateProfileOnLogout: () => void
  updateWeb3Profile: (address: string, network: string) => void
}

const COUNTLY_DEMO_ID = "countly-device-demo-id"

const CountlyContext = React.createContext<CountlyContextType | undefined>(undefined)

const CountlyContextProvider = ({ children }: CountlyContextProps): JSX.Element => {
  const [trackedUserId, setTrackedUserId] = useState<string>()
  const [trackedUserEmail, setTrackedUserEmail] = useState<string>()
  const [trackedUserAddress, setTrackedUserAddress] = useState<string>()
  const [trackedNetwork, setTrackedNetwork] = useState<string>()

  const trackPageView = useCallback((pageName: string) => {
    Countly.q.push(["track_pageview", pageName])
  }, [])

  const trackEvent = useCallback(
    (data: CountlyAddEventData) => {
      const segmentation: CountlySegmentData = {}
      if (trackedUserId) segmentation.user_id = trackedUserId
      if (trackedUserEmail) segmentation.email = trackedUserEmail
      if (trackedUserAddress) segmentation.user_address = trackedUserAddress
      if (trackedNetwork) segmentation.network = trackedNetwork

      Countly.q.push([
        "add_event",
        {
          count: 1,
          ...data,
          segmentation: {
            ...segmentation,
            ...data.segmentation,
          },
        },
      ])
    },
    [trackedUserId, trackedUserEmail, trackedUserAddress, trackedNetwork]
  )

  const updateProfileOnLogin = (user: User): void => {
    Countly.q.push(["change_id", user.uuid, true])
    Countly.q.push(["user_details", { email: user.email }])
    setTrackedUserId(user.uuid)
    setTrackedUserEmail(user.email)
  }

  const updateTrackedProfile = (name: string): void => {
    Countly.q.push(["user_details", { name }])
  }

  const updateProfileOnLogout = (): void => {
    Countly.q.push(["change_id", COUNTLY_DEMO_ID])
    setTrackedUserId(undefined)
    setTrackedUserEmail(undefined)
    setTrackedUserAddress(undefined)
    setTrackedNetwork(undefined)
  }

  const updateWeb3Profile = (address: string, network: string): void => {
    setTrackedUserAddress(address)
    setTrackedNetwork(network)
  }

  return (
    <CountlyContext.Provider
      value={{
        trackPageView,
        trackEvent,
        updateProfileOnLogin,
        updateTrackedProfile,
        updateProfileOnLogout,
        updateWeb3Profile,
      }}
    >
      {children}
    </CountlyContext.Provider>
  )
}

const useCountly = (): CountlyContextType => {
  const context = React.useContext(CountlyContext)
  if (context === undefined) {
    throw new Error("useCountly must be used within a CountlyProvider")
  }
  return context
}

export { CountlyContextProvider, useCountly }
