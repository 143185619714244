import { Trans } from "@lingui/macro"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Typography, Box, Divider, Breadcrumbs } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import CollectionCreate from "../../components/modules/nftLaunchpad/collections/CollectionCreate"
import { useNFTs } from "../../contexts/NFTsContext"
import { ROUTE_LINKS } from "../../routes/routes"

const NFTsCreateCollectionPage = (): JSX.Element | null => {
  const { NFTsProject } = useNFTs()

  if (!NFTsProject) return null

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>New collection</Trans>
          </Typography>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 1 }}>
            <Link
              style={{ color: "#8C8C8C", textDecoration: "none" }}
              to={NFTsProject ? ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId) : "#"}
            >
              {NFTsProject?.name || "Project"}
            </Link>
            <Typography>New collection</Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <CollectionCreate />
      </Box>
    </Box>
  )
}

export default NFTsCreateCollectionPage
