import { Trans, t } from "@lingui/macro"
import { Box, TextField, Typography } from "@mui/material"
import { ethers } from "ethers"
import React, { useEffect, useState } from "react"

import { CrossCircleIcon } from "../../../../../assets/icons/CrossCircleIcon"
import { useLootbox } from "../../../../../contexts/LootboxContext"
import { SoftButton } from "../../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"

interface ILootboxAddressesProps {
  isConfigure?: boolean
  goNext: () => void
}

const LootboxTokenAddresses: React.FC<ILootboxAddressesProps> = ({ goNext, isConfigure }) => {
  const { addTokenAddressesToLootbox, lootboxAllowedTokens } = useLootbox()
  const [tokenAddresses, setTokenAddresses] = useState<string[]>(
    lootboxAllowedTokens?.length ? [] : [""]
  )
  const [tokenAddressErrors, setTokenAddressErrors] = useState<string[]>([])
  const [isTokenAddressesLoading, setIsTokenAddressesLoading] = useState(false)

  // initial input
  useEffect(() => {
    if (lootboxAllowedTokens?.length) {
      setTokenAddresses([])
    } else {
      setTokenAddresses([""])
    }
  }, [lootboxAllowedTokens])

  const handleAddTokenAddressesSubmit = async (): Promise<void> => {
    // no new tokens or old tokens
    if (!tokenAddresses.length) return

    // validate token addresses
    setTokenAddressErrors([])
    const tokenAddressErrors = tokenAddresses.map((t) =>
      ethers.utils.isAddress(t) ? "" : "Token address is not valid"
    )
    setTokenAddressErrors(tokenAddressErrors)
    const areTokenAddressesValid = !tokenAddressErrors.find((tE) => !!tE)
    if (!areTokenAddressesValid) return

    setIsTokenAddressesLoading(true)
    // handle token address updates
    const wasSuccess = await addTokenAddressesToLootbox(tokenAddresses)

    setIsTokenAddressesLoading(false)

    // go to next page
    if (wasSuccess && isConfigure) {
      goNext()
    }
  }

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 1` : undefined}
        heading={t`Whitelist Reward Tokens`}
        subtitle={t`Determine the rewards you wish to include as part of your loot box (i.e. NFT’s) and enter the addresses below. When your players open this loot box, they will receive a randomized assortment of these items.`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <>
          {lootboxAllowedTokens?.map((lootboxAllowedToken, i) => (
            <Box
              display="flex"
              flex={1}
              width="100%"
              alignItems="flex-start"
              mt={1}
              mb={1}
              key={lootboxAllowedToken}
            >
              <TextField
                size="small"
                label={t`Token address ${i + 1}`}
                value={lootboxAllowedToken}
                fullWidth
                sx={{ mr: 4.5 }}
              />
            </Box>
          ))}
          {tokenAddresses.map((tokenAddress, i) => (
            <Box display="flex" flex={1} width="100%" alignItems="flex-start" mt={1} mb={1} key={i}>
              <TextField
                size="small"
                label={t`Token address ${(lootboxAllowedTokens?.length || 0) + i + 1}`}
                value={tokenAddress}
                onChange={(e) => {
                  tokenAddresses[i] = e.target.value
                  setTokenAddresses([...tokenAddresses])
                }}
                placeholder="0x..."
                error={!!tokenAddressErrors[i]}
                helperText={tokenAddressErrors[i]}
                fullWidth
              />
              <Box
                pt={1.5}
                pl={1.5}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setTokenAddresses(tokenAddresses.filter((t, index) => index !== i))
                  setTokenAddressErrors([])
                }}
              >
                <CrossCircleIcon />
              </Box>
            </Box>
          ))}
        </>
        <Typography
          onClick={() => {
            setTokenAddresses([...tokenAddresses, ""])
            setTokenAddressErrors([])
          }}
          sx={{
            p: 0,
            mt: 2,
            pl: 1,
            color: "#BFBFBF",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Trans>Add another address</Trans>
        </Typography>
      </Box>
      <Box p={4} display="flex" justifyContent="space-between">
        <div />
        <SoftButton onClick={handleAddTokenAddressesSubmit} loading={isTokenAddressesLoading}>
          <Trans>{isConfigure ? "Continue" : "Save changes"}</Trans>
        </SoftButton>
      </Box>
    </Box>
  )
}

export default LootboxTokenAddresses
