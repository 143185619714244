import { Box } from "@mui/material"
import React from "react"

import CollectionsList from "./collections/CollectionsList"

const ProjectCollections: React.FC = () => {
  return (
    <Box ml={2} mr={2}>
      <CollectionsList />
    </Box>
  )
}

export default ProjectCollections
