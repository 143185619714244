import { Box } from "@mui/material"
import React, { useState } from "react"

import DepositorAddresses from "./DepositorAddresses"
import MintAndTransfer from "./MintAndTransfer"
import Review from "./Review"
import RewardUnits from "./RewardUnits"
import SendLoot from "./SendLoot"
import ManageLootboxSidebar from "./Sidebar"
import LootboxTokenAddresses from "./TokenAddresses"
import type { TManageLootboxTab } from "./types"

interface IProps {
  isConfigure?: boolean
}

const ManageLootbox: React.FC<IProps> = ({ isConfigure }) => {
  const [tab, setTab] = useState<TManageLootboxTab>("tokenAddresses")

  return (
    <Box display="flex" mt={3}>
      <ManageLootboxSidebar tab={tab} setTab={setTab} />
      <Box width="100%">
        {tab === "tokenAddresses" ? (
          <LootboxTokenAddresses
            isConfigure={isConfigure}
            goNext={() => setTab("depositorAccess")}
          />
        ) : tab === "depositorAccess" ? (
          <DepositorAddresses isConfigure={isConfigure} goNext={() => setTab("sendLoot")} />
        ) : tab === "sendLoot" ? (
          <SendLoot isConfigure={isConfigure} goNext={() => setTab("rewards")} />
        ) : tab === "rewards" ? (
          <RewardUnits isConfigure={isConfigure} goNext={() => setTab("mint")} />
        ) : tab === "mint" ? (
          <MintAndTransfer isConfigure={isConfigure} goNext={() => setTab("review")} />
        ) : (
          <Review isConfigure={isConfigure} goNext={() => undefined} />
        )}
      </Box>
    </Box>
  )
}

export default ManageLootbox
