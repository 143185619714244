import { Trans } from "@lingui/macro"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, Modal, Button } from "@mui/material"
import { useConnectWallet } from "@web3-onboard/react"
import React from "react"

import type { NFTCollection } from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 520,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const RedeployCollectionModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  onRedeployCollection: () => void
  isLoadingRedeploy: boolean
  collection: NFTCollection
}> = ({ collection, isOpen, onClose, onRedeployCollection, isLoadingRedeploy }) => {
  const { isLoggedIn } = useWeb3Connection()
  const [, connect] = useConnectWallet()

  return (
    <Modal open={isOpen} sx={{ zIndex: 1 }}>
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          <Trans>Retry deployment?</Trans>
        </Typography>
        <Typography variant="body1" mb={4} align="center">
          <Trans>
            Your collection {collection.name} was created but was not deployed on-chain. Would you
            like to retry deployment?
          </Trans>
        </Typography>

        <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
          <LoadingButton
            variant="text"
            disabled={isLoadingRedeploy}
            sx={{
              color: "#BFBFBF",
              width: 80,
              pl: 2,
              pr: 2,
            }}
            onClick={onClose}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          {!isLoggedIn ? (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 160,
                backgroundColor: "#333333",
                color: "#B4FF68",
                "&:hover": {
                  backgroundColor: "#333333",
                  color: "#B4FF68",
                },
              }}
              onClick={() => connect()}
            >
              <Trans>Connect wallet</Trans>
            </Button>
          ) : (
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 160,
                backgroundColor: "#333333",
                color: "#B4FF68",
                "&:hover": {
                  backgroundColor: "#333333",
                  color: "#B4FF68",
                },
              }}
              loading={isLoadingRedeploy}
              disabled={isLoadingRedeploy}
              onClick={onRedeployCollection}
            >
              <Trans>Retry deployment</Trans>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default RedeployCollectionModal
