import { Box, Link, Typography } from "@mui/material"
import React from "react"

import { ROUTE_LINKS } from "../../../routes/routes"

export const Footer = (): JSX.Element => {
  return (
    <Box mt={10} mb={2} color="GrayText">
      <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1.5}>
        <Typography sx={{ color: "#AFAFAF" }} variant="body2">
          © {new Date().getFullYear()} ChainSafe Systems, All Rights Reserved.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box display="flex" alignItems="center" justifyContent="space-between" width={260} mb={1}>
          <Link
            variant="body2"
            href={ROUTE_LINKS.PrivacyPolicy}
            color="inherit"
            target="_blank"
            data-cy="link-privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            variant="body2"
            href={ROUTE_LINKS.TermsOfService}
            color="inherit"
            target="_blank"
            data-cy="link-terms-and-conditions"
          >
            Terms and Conditions
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
