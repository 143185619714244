import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function CollectionLogo(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="39"
        height="39"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 19.5C23.0913 19.5 26 16.5913 26 13C26 9.40875 23.0913 6.5 19.5 6.5C15.9088 6.5 13 9.40875 13 13C13 16.5913 15.9088 19.5 19.5 19.5ZM19.5 22.75C15.1613 22.75 6.5 24.9275 6.5 29.25V32.5H32.5V29.25C32.5 24.9275 23.8388 22.75 19.5 22.75Z"
          fill="#D6E4FF"
        />
      </svg>
    </SvgIcon>
  )
}
