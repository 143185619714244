import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function LaunchpadIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.9924C0 13.8711 0.712274 14.5833 1.59091 14.5833H10.0758C10.9544 14.5833 11.6667 13.8711 11.6667 12.9924V1.59091C11.6667 0.712273 10.9544 0 10.0758 0H1.59091C0.712276 0 0 0.712274 0 1.59091V12.9924ZM0 24.6591C0 25.5377 0.712274 26.25 1.59091 26.25H10.0758C10.9544 26.25 11.6667 25.5377 11.6667 24.6591V19.0909C11.6667 18.2123 10.9544 17.5 10.0758 17.5H1.59091C0.712276 17.5 0 18.2123 0 19.0909V24.6591ZM14.5833 24.6591C14.5833 25.5377 15.2956 26.25 16.1742 26.25H24.6591C25.5377 26.25 26.25 25.5377 26.25 24.6591V13.2576C26.25 12.3789 25.5377 11.6667 24.6591 11.6667H16.1742C15.2956 11.6667 14.5833 12.3789 14.5833 13.2576V24.6591ZM16.1742 0C15.2956 0 14.5833 0.712274 14.5833 1.59091V7.15909C14.5833 8.03773 15.2956 8.75 16.1742 8.75H24.6591C25.5377 8.75 26.25 8.03773 26.25 7.15909V1.59091C26.25 0.712274 25.5377 0 24.6591 0H16.1742Z"
          fill="#DFF7C7"
        />
      </svg>
    </SvgIcon>
  )
}
