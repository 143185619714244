import { Trans } from "@lingui/macro"
import { Box, Typography, Modal, Button } from "@mui/material"
import React from "react"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 520,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const MintSuccessModal: React.FC<{
  isOpen: boolean
  onOk: () => void
}> = ({ onOk, isOpen }) => {
  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          <Trans>Your NFT is syncing !</Trans>
        </Typography>
        <Typography variant="body1" mb={4} align="center">
          <Trans>
            It may take a <b>few minutes</b> for your NFT to appear on the dashboard
          </Trans>
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="contained" onClick={onOk} data-cy="button-mint-success-modal-got-it">
            <Trans>Got it</Trans>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MintSuccessModal
