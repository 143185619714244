import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { useMarketplace } from "../../../../contexts/MarketplaceContext"
import type { NFTTokenType } from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import SwitchNetworkModal from "../../networkSupport/SwitchNetworkModal"
import WalletConnectModal from "../../walletConnect/WalletConnectModal"

import NFTItemCard from "./ListNFTCard"

const MarketplaceListItem: React.FC = () => {
  const { isLoggedIn } = useWeb3Connection()
  const { getOwnerNFTTokens, marketplace } = useMarketplace()
  const [hasLoadedNFTs, setHasLoadedNFTs] = useState(false)
  const [NFTTokens, setNFTTokens] = useState<NFTTokenType[]>([])
  const { address, network, networks } = useWeb3Connection()

  useEffect(() => {
    if (!address || !network || !marketplace || network.chainId !== marketplace.chain_id) return
    setNFTTokens([])
    getOwnerNFTTokens()
      .then((NFTs) => {
        setNFTTokens(NFTs)
        setHasLoadedNFTs(true)
      })
      .catch(() => {
        setHasLoadedNFTs(true)
      })
  }, [getOwnerNFTTokens, address, network, marketplace])

  if (!marketplace) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (!isLoggedIn) {
    return (
      <WalletConnectModal
        isOpen={true}
        subtitle="To manage your NFTs, you must first connect a wallet."
      />
    )
  }

  if (isLoggedIn && network?.chainId !== marketplace.chain_id) {
    return (
      <SwitchNetworkModal
        network={
          networks.find((network) => network.chainId === marketplace.chain_id) || networks[0]
        }
        isOpen={true}
      />
    )
  }

  if (!hasLoadedNFTs) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (hasLoadedNFTs && !NFTTokens.length) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Box
          width={400}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
          sx={{ border: "1px solid #333333", borderRadius: "8px", background: "#141414" }}
        >
          <NoDataOrConnectionIcon />
          <Typography fontSize={20} mt={2} align="center">
            <Trans>No NFT's in your wallet, please mint NFT's in launchpad</Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box ml={2} mr={2}>
      <Box
        display="grid"
        gap={2}
        sx={(theme) => ({
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
          [theme.breakpoints.up("xl")]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          },
        })}
      >
        {NFTTokens?.map((n) => (
          <NFTItemCard key={`${n.contract_address}-${n.token_id}`} NFTToken={n} />
        ))}
      </Box>
    </Box>
  )
}

export default MarketplaceListItem
