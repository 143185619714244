import { t } from "@lingui/macro"
import axios from "axios"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useState } from "react"

import { useCountly } from "./CountlyContext"
import { useGamingApi } from "./GamingApiContext"
import { useStorageApi } from "./StorageContext"

export interface IProject {
  projectId: string
  accountId: string
  name: string
  projectURL?: string
  projectStage?: string
  targetPlatforms?: string[]
  createdAt: number
  isActive: boolean
}

export interface IAnalyticsData {
  total_series: number
  series: {
    group_tags: string[]
  }[]
  timestamps: number[]
  values: number[][]
}

const apiRoutes = {
  create: "/project/create",
  update: "/project/update",
  remove: "/project/remove",
  getByAccountID: "/project/getByAccountID",
  checkId: "/project/checkId",
  analytics: (
    projectId: string,
    options: {
      name: "method" | "player"
      query?: "unique"
      from: number
      to: number
      group_by?: "method" | "player_id"
    }
  ) =>
    `/v1/analytics/projects/${projectId}/timeseries?name=gaming.${
      options.name
    }.timeseries&from_timestamp=${options.from}&to_timestamp=${options.to}${
      options.group_by ? `&group_by=${options.group_by}` : ""
    }${options.query ? `&query=${options.query}` : ""}`,
}

type ProjectApiContextProps = {
  children: React.ReactNode
}

type ProjectApiContext = {
  createProject: (
    data: Omit<IProject, "accountId" | "projectId" | "createdAt" | "isActive">
  ) => Promise<IProject | undefined>
  updateProject: (
    data: Omit<IProject, "accountId" | "createdAt" | "isActive">
  ) => Promise<boolean | undefined>
  removeProject: (projectId: string) => Promise<void>
  getAllByAccount: () => Promise<void>
  getProjectAnalytics: (
    projectId: string,
    options: {
      name: "method" | "player"
      query?: "unique"
      from: number
      to: number
      group_by?: "method" | "player_id"
    }
  ) => Promise<IAnalyticsData | undefined>
  isProjectsLoading: boolean
  projects: IProject[] | undefined
}

const ProjectApiContext = React.createContext<ProjectApiContext | undefined>(undefined)

const ProjectApiProvider = ({ children }: ProjectApiContextProps): JSX.Element => {
  const { isLoggedIn, accountRestricted } = useStorageApi()
  const { gamingApiInstance } = useGamingApi()
  const [isProjectsLoading, setIsProjectsLoading] = useState(true)
  const [projects, setProjects] = useState<IProject[]>([])
  const { trackEvent } = useCountly()

  const { enqueueSnackbar } = useSnackbar()

  const getAllByAccount = useCallback(async (): Promise<void> => {
    if (!gamingApiInstance) return
    const projectsResponse = await gamingApiInstance.get(apiRoutes.getByAccountID)

    if (projectsResponse.status === 200) {
      setProjects(
        JSON.parse(projectsResponse.data).response.projects.filter(
          (item: IProject) => item.isActive
        )
      )
    } else if (projectsResponse.status === 400) {
      enqueueSnackbar(t`Authentication error`, {
        variant: "error",
      })
    }
    setIsProjectsLoading(false)
  }, [gamingApiInstance, enqueueSnackbar])

  useEffect(() => {
    if (isLoggedIn) {
      getAllByAccount()
    }
  }, [isLoggedIn, getAllByAccount])

  const createProject = useCallback(
    async (
      data: Omit<IProject, "accountId" | "projectId" | "createdAt" | "isActive">
    ): Promise<IProject | undefined> => {
      try {
        if (!gamingApiInstance || accountRestricted) return
        const resp = await gamingApiInstance.post(apiRoutes.create, data)
        getAllByAccount()
        const newProject: IProject = JSON.parse(resp.data).response.project

        // countly event
        trackEvent({ key: "project-created", segmentation: { project_id: newProject.projectId } })

        return newProject
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Access to config, request, and response
          if (error.response?.status === 409) {
            enqueueSnackbar(t`Name already in use`, {
              variant: "error",
            })
          } else if (error.response?.status === 400) {
            enqueueSnackbar(t`Bad request`, {
              variant: "error",
            })
          } else {
            // Just a stock error
            enqueueSnackbar(error.message, {
              variant: "error",
            })
          }
        } else {
          // Just a stock error
          enqueueSnackbar((error as Error).message, {
            variant: "error",
          })
        }
      }
    },
    [accountRestricted, enqueueSnackbar, getAllByAccount, gamingApiInstance, trackEvent]
  )

  const updateProject = useCallback(
    async (
      data: Omit<IProject, "accountId" | "createdAt" | "isActive">
    ): Promise<boolean | undefined> => {
      if (!gamingApiInstance) return
      if (!accountRestricted) {
        try {
          await gamingApiInstance.post(apiRoutes.update, data)
          getAllByAccount()

          // countly event
          trackEvent({ key: "project-updated", segmentation: { project_id: data.projectId } })

          return true
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            if (error.response?.status === 409) {
              enqueueSnackbar(t`Name already in use`, {
                variant: "error",
              })
            } else if (error.response?.status === 400) {
              enqueueSnackbar(t`Bad request`, {
                variant: "error",
              })
            } else {
              // Just a stock error
              enqueueSnackbar(error.message, {
                variant: "error",
              })
            }
          } else {
            // Just a stock error
            enqueueSnackbar((error as Error).message, {
              variant: "error",
            })
          }
        }
      } else {
        enqueueSnackbar(t`Account restricted`, {
          variant: "error",
        })
      }
    },
    [accountRestricted, gamingApiInstance, enqueueSnackbar, getAllByAccount, trackEvent]
  )

  const removeProject = useCallback(
    async (projectId: string): Promise<void> => {
      if (!gamingApiInstance) return
      if (!accountRestricted) {
        await gamingApiInstance.post(apiRoutes.remove, { projectId })
        getAllByAccount()

        // countly event
        trackEvent({ key: "project-deleted", segmentation: { project_id: projectId } })
      }
    },
    [accountRestricted, gamingApiInstance, getAllByAccount, trackEvent]
  )

  const getProjectAnalytics = useCallback(
    async (
      projectId: string,
      options: {
        name: "method" | "player"
        query?: "unique"
        from: number
        to: number
        group_by?: "method" | "player_id"
      }
    ): Promise<IAnalyticsData | undefined> => {
      if (!gamingApiInstance) return
      if (!accountRestricted) {
        const resp = await gamingApiInstance.get(apiRoutes.analytics(projectId, options))
        return resp.data ? JSON.parse(resp.data) : resp.data
      }
    },
    [accountRestricted, gamingApiInstance]
  )

  return (
    <ProjectApiContext.Provider
      value={{
        createProject,
        updateProject,
        removeProject,
        projects,
        getAllByAccount,
        getProjectAnalytics,
        isProjectsLoading,
      }}
    >
      {children}
    </ProjectApiContext.Provider>
  )
}

const useProjectApi = (): ProjectApiContext => {
  const context = React.useContext(ProjectApiContext)
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider")
  }
  return context
}

export { ProjectApiProvider, useProjectApi }
