import { Trans, t } from "@lingui/macro"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Typography, TextField } from "@mui/material"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import React, { useState, useCallback } from "react"

import { useStorageApi } from "../../../contexts/StorageContext"
import { nonceValidation } from "../../../utils/ValidationSchema"
import { RoundIconButton } from "../../elements/RoundIconButton"

interface IPasswordlessEmail {
  email: string
  resetLogin: () => void
}

const PasswordlessEmail = ({ resetLogin, email }: IPasswordlessEmail): JSX.Element => {
  const [isSubmitNonceLoading, setIsSubmitNonceLoading] = useState(false)
  const { storageApiClient, login } = useStorageApi()
  const [hasEmailResent, setHasEmailResent] = useState(false)
  const [nonceError, setNonceError] = useState("")
  const { enqueueSnackbar } = useSnackbar()

  const onSubmitNonce = useCallback(
    ({ nonce }: { nonce: string }) => {
      setIsSubmitNonceLoading(true)
      setNonceError("")
      storageApiClient
        .postIdentityEmailToken({
          email,
          nonce: nonce.trim(),
        })
        .then(async (data) => {
          login("email", { token: data || "", email }).catch((e) => {
            console.error(e)
            enqueueSnackbar(t`Login failed`, { variant: "error" })
          })
        })
        .catch((e) => {
          console.error(e)
          if (e.error && e.error.details && e.error.details[0].type === "nonce") {
            setNonceError(t`Verification code is incorrect. Please try again.`)
          } else {
            enqueueSnackbar(t`Something went wrong!`, { variant: "error" })
          }
        })
        .finally(() => setIsSubmitNonceLoading(false))
    },
    [storageApiClient, email, login, enqueueSnackbar]
  )

  const onResendEmail = useCallback(() => {
    if (!email) return
    storageApiClient
      .getIdentityEmailToken({ email })
      .then(() => setHasEmailResent(true))
      .catch((e) => {
        console.error(e)
        enqueueSnackbar(t`Failed to resend verification code`, { variant: "error" })
      })
  }, [storageApiClient, email, enqueueSnackbar])

  const passwordFormik = useFormik({
    initialValues: {
      nonce: "",
    },
    validationSchema: nonceValidation,
    onSubmit: onSubmitNonce,
  })

  return (
    <Box width={420} mt={8}>
      <Typography variant="h4" sx={{ mb: 3 }} align="center">
        <Trans>Verify your email</Trans>
      </Typography>
      <Typography variant="body1" align="center" sx={{ mb: 4 }}>
        <Trans>
          We've sent an email to {email}. It contains a verification code that'll sign you in super
          quickly!
        </Trans>
      </Typography>
      <form
        onSubmit={passwordFormik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ mb: 2 }}
          fullWidth
          id="nonce"
          name="nonce"
          data-cy="input-verification-code"
          label="Verification code"
          size="small"
          placeholder="123456"
          value={passwordFormik.values.nonce}
          onChange={passwordFormik.handleChange}
          error={(passwordFormik.touched.nonce && !!passwordFormik.errors.nonce) || !!nonceError}
          helperText={(passwordFormik.touched.nonce && passwordFormik.errors.nonce) || nonceError}
        />
        <RoundIconButton
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          size="medium"
          loading={isSubmitNonceLoading}
          disabled={!passwordFormik.values.nonce || passwordFormik.values.nonce.length < 6}
          sx={{ mt: 2 }}
          data-cy="button-submit-verification-code"
        >
          <ArrowForwardIcon />
        </RoundIconButton>
      </form>

      {!hasEmailResent ? (
        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ mr: 0.5, color: "#AFAFAF" }}>
            <Trans>Didn&apos;t receive the email? </Trans>
          </Typography>
          <Typography
            variant="body2"
            onClick={onResendEmail}
            sx={{ textDecoration: "underline", cursor: "pointer", color: "#AFAFAF" }}
          >
            <Trans>Send another email</Trans>
          </Typography>
        </Box>
      ) : (
        <Typography variant="body2" align="center" sx={{ mt: 4 }}>
          <Trans>Check your inbox! We&apos;ve sent another email.</Trans>
        </Typography>
      )}
      <Box display="flex" justifyContent="center" mt={3}>
        <Typography
          onClick={resetLogin}
          variant="body2"
          sx={{ textDecoration: "underline", cursor: "pointer", color: "#AFAFAF" }}
        >
          <Trans>Go back</Trans>
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordlessEmail
