import { Trans } from "@lingui/macro"
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded"
import { Box, Typography, Tooltip as TooltipMui } from "@mui/material"
import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
  Tooltip,
} from "recharts"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { formatNumberWithComma } from "../../../../utils/Helpers"

const BAR_SIZE = 30
const BAR_GAP = 15

const DailyMethodCalls: React.FC<{ data: { method: string; calls: number }[] }> = ({ data }) => {
  return (
    <Box
      width="100%"
      sx={{
        border: "1px solid #333333",
        borderRadius: 2,
      }}
    >
      <Box
        pb={1.5}
        mt={2}
        pr={2}
        borderBottom="1px solid #434343"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box ml={3} display="flex" alignItems="center" gap={1}>
          <Typography fontSize={18}>Method calls</Typography>
          <Typography color="#8C8C8C" variant="body2">
            by name
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <Box
            sx={{
              border: "1px solid #262626",
              borderRadius: 2,
              padding: "4px 6px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
            }}
          >
            <Typography variant="body2" color="#595959" fontWeight={500}>
              <Trans>Last 24 hrs</Trans>
            </Typography>
          </Box>
          <TooltipMui
            title="This graph is updated daily with the number of calls made for each method"
            arrow
            placement="top"
            data-cy="tooltip-method-calls-by-name"
          >
            <HelpOutlineRoundedIcon sx={{ color: "#8C8C8C" }} />
          </TooltipMui>
        </Box>
      </Box>
      {data.length ? (
        <Box data-cy="chart-method-calls-by-name" sx={{ overflowY: "scroll", maxHeight: 226 }}>
          <Box height={data.length * (BAR_SIZE + BAR_GAP)} pt={1}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 5, right: 24, left: 8, bottom: 0 }}
                barCategoryGap={BAR_GAP}
                barSize={BAR_SIZE}
              >
                <XAxis
                  type="number"
                  tick={false}
                  axisLine={false}
                  tickFormatter={(_, index) => {
                    return data[index].method
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="method"
                  tickLine={false}
                  axisLine={false}
                  tick={{ fill: "#DFF7C7", fontSize: "12px" }}
                  tickFormatter={(_, index) => {
                    return formatNumberWithComma(data[index].calls, 0)
                  }}
                />
                <Bar dataKey="calls" fill="#31372B" radius={[3, 3, 3, 3]}>
                  <LabelList dataKey="method" position="insideLeft" offset={15} fill="#F5F5F5" />
                  {data.map((entry, index) => (
                    <Cell fontSize="14px" key={`cell-${index}`}>
                      {entry.method}
                    </Cell>
                  ))}
                </Bar>
                <Tooltip
                  content={({ label, payload }) => (
                    <Box
                      sx={{
                        backgroundColor: "#141414",
                        border: "1px solid #B4FF68",
                        padding: "8px 12px 8px 12px",
                        borderRadius: 1,
                      }}
                    >
                      <Typography color="#8C8C8C">{label}</Typography>
                      <Typography color="#DFF7C7">
                        {payload?.length ? payload[0].value : "-"} calls
                      </Typography>
                    </Box>
                  )}
                  cursor={false}
                  wrapperStyle={{ outline: "none" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="75%"
          gap={1}
          data-cy="label-empty-method-calls-by-name"
        >
          <NoDataOrConnectionIcon />
          <Typography>
            <Trans>No calls made</Trans>
          </Typography>
          <Typography variant="body2" color="#8C8C8C">
            <Trans>Project analytics will show here once there is activity.</Trans>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default DailyMethodCalls
