import { Box } from "@mui/material"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import OpenLootbox from "../../components/modules/addOns/LootBox/OpenLootbox/index"
import { useLootbox } from "../../contexts/LootboxContext"

interface IProps {
  isConfigure?: boolean
}

const LootboxOpenPage: React.FC<IProps> = () => {
  const { setLootboxToOpen } = useLootbox()
  const { lootboxAddress } = useParams()

  useEffect(() => {
    setLootboxToOpen(lootboxAddress)
    return () => {
      setLootboxToOpen(undefined)
    }
  }, [lootboxAddress, setLootboxToOpen])

  return (
    <Box pt={1} pb={1}>
      <OpenLootbox />
    </Box>
  )
}

export default LootboxOpenPage
