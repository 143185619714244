import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useEffect } from "react"

import { NoDataOrConnectionIcon } from "../../../assets/icons/NoDataOrConnectionIcon"
import { useMarketplace } from "../../../contexts/MarketplaceContext"

import MarketplaceCard from "./MarketplaceCard"

const MarketplacesOnProject: React.FC = () => {
  const { marketplaces, isLoadingMarketplaces, hasLoadedMarketplaces, getMarketplaces } =
    useMarketplace()

  useEffect(() => {
    getMarketplaces()
  }, [getMarketplaces])

  if (isLoadingMarketplaces && !hasLoadedMarketplaces) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (hasLoadedMarketplaces && !marketplaces?.length) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Box
          width={400}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
          sx={{ border: "1px solid #333333", borderRadius: "8px", background: "#141414" }}
          data-cy="box-marketplaces-list-empty"
        >
          <NoDataOrConnectionIcon />
          <Typography fontSize={20} mt={2}>
            <Trans>No marketplaces yet</Trans>
          </Typography>
          <Typography fontSize="16px" align="center" mt={2} color="#8C8C8C">
            <Trans>
              Please add or deploy a new marketplace contract to continue
              {/* This will be added when we have documentation */}
              {/* , or check out our
              documentation for&nbsp;
              <a href="#" target="_blank" style={{ color: "#DFF7C7" }}>
                deploying marketplace contracts.
              </a> */}
            </Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="grid"
      gap={2}
      sx={(theme) => ({
        gridTemplateColumns: "1fr",
        [theme.breakpoints.up("lg")]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.up("xl")]: {
          gridTemplateColumns: "1fr 1fr 1fr",
        },
      })}
    >
      {marketplaces?.map((marketplace) => (
        <MarketplaceCard key={marketplace.id} marketplace={marketplace} />
      ))}
    </Box>
  )
}

export default MarketplacesOnProject
