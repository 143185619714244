import { useLocalStorage } from "@chainsafe/browser-storage-hooks"
import { Trans } from "@lingui/macro"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import { Box, Button, Card, Divider, MenuItem, Select, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

import { ArrowOutGrayIcon } from "../assets/icons/ArrowOutGrayIcon"
import { ArrowOutIcon } from "../assets/icons/ArrowOutIcon"
import { useCountly } from "../contexts/CountlyContext"

const networks: string[] = [
  "Ethereum",
  "Polygon",
  "BNB Smart chain",
  "Avalanche",
  "Arbitrum",
  "Polygon zkEVM",
  "Optimism",
  "Oasis Saphire",
  "NEAR",
  "Aurora",
  "Aptos",
  "Gnosis Chain",
  "Cronos",
  "Fantom",
  "Starknet",
  "Harmony",
  "Fuse",
  "Quorum",
  "MultiChain",
]

interface IRPCReferral {
  provider: string
  image: string
  subtitle: string
  pointers?: string[]
  referralLink: string
  learnMoreLink?: string
}

const RPCReferrals: IRPCReferral[] = [
  {
    provider: "Chainstack",
    image: "logos/chainstack_logo.png",
    subtitle: "Fast and reliable blockchain infrastructure",
    pointers: ["Enterprise-grade security", "Freedom to choose", "Optimised for growth"],
    referralLink: "https://pages.chainstack.com/referrals/chainsafe",
    learnMoreLink: "https://chainstack.com/",
  },
]

const HIDE_SUPPORT_BANNER_LOCALSTORAGE_KEY = "csgd.hideSupportBanner"

const RPCNodeCard = (RPCReferral: IRPCReferral): JSX.Element => {
  const { trackEvent } = useCountly()

  return (
    <Card sx={{ maxWidth: 360, pt: 3, pl: 3, pb: 3, pr: 3, backgroundColor: "#1F1F1F" }}>
      {/* icon */}
      <Box
        sx={{
          borderRadius: "50%",
          backgroundColor: "lightgray",
          width: 32,
          height: 32,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={1}
      >
        <img style={{ width: 24, height: 24 }} src={RPCReferral.image} />
      </Box>
      {/* name */}
      <Box pt={1} pb={0.5}>
        <Typography>{RPCReferral.provider}</Typography>
      </Box>
      {/* subtitle */}
      <Box pb={0.5}>
        <Typography variant="body2" color="#8C8C8C">
          {RPCReferral.subtitle}
        </Typography>
      </Box>
      {/* pointers */}
      {RPCReferral.pointers?.length ? (
        <Box mt={2} mb={1}>
          {RPCReferral.pointers?.map((point) => (
            <Box key={point} display="flex" alignItems="center" mb={1}>
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: "#333333",
                  width: 24,
                  height: 24,
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DoneIcon color="primary" fontSize="small" />
              </Box>
              <Typography variant="body2" ml={1} sx={{ color: "#E5E5E5" }}>
                {point}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : null}
      {/* buttons */}
      <Box mt={3} display="flex">
        <Button
          data-cy={`button-referral-link-${RPCReferral.provider.toLowerCase()}`}
          variant="outlined"
          color="primary"
          onClick={() => {
            trackEvent({ key: "rpc-nodes-chainstack-signup-click" })
            window.open(RPCReferral.referralLink, "_blank")
          }}
        >
          <Trans>Sign Up</Trans>
        </Button>
        {RPCReferral.learnMoreLink ? (
          <Button
            data-cy={`button-learn-more-link-${RPCReferral.provider.toLowerCase()}`}
            variant="text"
            sx={{ ml: 2, color: "#BFBFBF" }}
            onClick={() => window.open(RPCReferral.learnMoreLink, "_blank")}
          >
            <Trans>Learn more</Trans>
            <Box ml={0.5} pt={0.4} sx={{ height: 24 }}>
              <ArrowOutGrayIcon />
            </Box>
          </Button>
        ) : null}
      </Box>
    </Card>
  )
}

const RPCNodesPage = (): JSX.Element => {
  const { canUseLocalStorage, localStorageGet, localStorageSet } = useLocalStorage()

  // show support banner if it has not been hidden
  // and saved in localstorage
  const [shouldShowSupportBanner, setShouldShowSupportBanner] = useState(false)

  useEffect(() => {
    if (canUseLocalStorage && !localStorageGet(HIDE_SUPPORT_BANNER_LOCALSTORAGE_KEY)) {
      setShouldShowSupportBanner(true)
    }
  }, [canUseLocalStorage, localStorageGet])

  const onRemoveSupportBanner = (): void => {
    setShouldShowSupportBanner(false)
    localStorageSet(HIDE_SUPPORT_BANNER_LOCALSTORAGE_KEY, "true")
  }

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box>
          <Typography sx={{ flex: 1, mb: 0.5, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>RPC Nodes</Trans>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box display="flex" pl={2} pr={2} pt={2} pb={2}>
        <Box
          id="box-header"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box display="flex" alignItems="center" justifyContent="flex-start" flex={1}>
            <Typography>
              <Trans>Read more about how to set up your RPC provider in Web3.Unity&nbsp;</Trans>
            </Typography>
            <Box
              data-cy="button-rpc-nodes-read-more"
              display="flex"
              alignItems="center"
              onClick={() =>
                window.open(
                  "https://docs.gaming.chainsafe.io/current/setting-up-an-rpc-node/",
                  "_blank"
                )
              }
              sx={{ cursor: "pointer" }}
            >
              <Box pl={0.5}>
                <Typography
                  sx={{
                    color: "#B4FF68",
                    textDecoration: "none",
                  }}
                >
                  <Trans>here</Trans>
                </Typography>
              </Box>
              <Box pt={2} pl={0.5}>
                <ArrowOutIcon />
              </Box>
            </Box>
          </Box>
          <Box>
            <Select
              data-cy="dropdown-rpc-nodes-networks"
              defaultValue={networks[0]}
              size="small"
              sx={{ minWidth: 200 }}
            >
              {networks.map((network) => (
                <MenuItem
                  data-cy={`dropdown-networks-${network.toLowerCase().replaceAll(" ", "-")}-option`}
                  key={network}
                  value={network}
                >
                  {network}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box pl={2} pr={2} pt={2} pb={2}>
        {RPCReferrals.map((RPCReferral) => (
          <RPCNodeCard key={RPCReferral.provider} {...RPCReferral} />
        ))}
      </Box>
      {/* Bottom bar */}
      {shouldShowSupportBanner ? (
        <Box
          sx={{
            width: "calc(100% - 315px)",
            position: "absolute",
            bottom: 24,
            backgroundColor: "#31372B",
            pt: 1.75,
            pb: 1.75,
            pl: 3,
            pr: 1,
            ml: 2,
            borderRadius: 3,
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            <Trans>
              Ask us about how ChainSafe can manage your RPC endpoints for reliable support and
              infrastructure.
            </Trans>
          </Typography>
          <Box display="flex">
            <a
              data-cy="button-rpc-nodes-contact-us"
              href="https://gaming.chainsafe.io/contact"
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" sx={{ mr: 1 }}>
                <Trans>Contact us</Trans>
              </Button>
            </a>
            <Button data-cy="button-rpc-nodes-close-contact-us" onClick={onRemoveSupportBanner}>
              <CloseIcon sx={{ color: "gray" }} />
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default RPCNodesPage
