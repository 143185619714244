import { Trans } from "@lingui/macro"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useSnackbar } from "notistack"
import React, { useState } from "react"

import { useProjectApi } from "../../../contexts/ProjectContext"

interface IDeleteProjectAlert {
  projectId: string
  projectName: string
  handleClose: () => void
}

export default function DeleteProjectAlert({
  projectId,
  projectName,
  handleClose,
}: IDeleteProjectAlert): JSX.Element {
  const { removeProject } = useProjectApi()
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={{ p: 2, background: "#121212", maxWidth: 460 }}>
        <DialogContent sx={{ background: "#121212" }}>
          <Typography variant="h5" align="center">
            <Trans>Delete project</Trans>
          </Typography>
          <DialogContentText color="#D9D9D9" variant="body2" id="alert-dialog-description" mt={2}>
            <Trans>
              Deleting a project is a permanent action. Please confirm that you would like to
              permanently delete “{projectName}”
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ background: "#121212" }}>
          <Button
            onClick={handleClose}
            data-cy="button-delete-project-modal-cancel"
            variant="text"
            disabled={isDeleteLoading}
            sx={{
              color: "#8C8C8C",
              backgroundColor: "#262626",
              width: 100,
              mr: 1,
              ":hover": {
                backgroundColor: "#262626",
              },
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <LoadingButton
            color="primary"
            data-cy="button-delete-project-modal-delete"
            onClick={async () => {
              setIsDeleteLoading(true)
              await removeProject(projectId)
              setIsDeleteLoading(false)
              enqueueSnackbar("Project deleted", { variant: "info" })
              handleClose()
            }}
            loading={isDeleteLoading}
            autoFocus
            sx={{
              color: theme.palette.error.main,
              background: "#333333",
              pl: 2,
              pr: 2,
              ":hover": {
                backgroundColor: "#333333",
              },
            }}
          >
            <Trans>Yes, delete it</Trans>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
