import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

import { useCountly } from "../../../contexts/CountlyContext"
import { ROUTE_LINKS } from "../../../routes/routes"
import { SoftButton } from "../../elements/SoftButton"

const LootBoxAddOnCard: React.FC = () => {
  const navigate = useNavigate()
  const { trackEvent } = useCountly()

  return (
    <Box
      sx={{
        background: "#141414",
        borderRadius: "5px",
        border: "1px solid #262626",
      }}
      p={3}
    >
      <Box display="flex" alignItems="center" mb={2}>
        <img
          src="/logos/chainlink_logo.png"
          style={{ cursor: "pointer", width: "24px", height: "24px", marginRight: "8px" }}
          alt="Chainsafe"
        />
        <Typography variant="body1" sx={{ fontSize: 18 }}>
          Chainlink
        </Typography>
      </Box>
      <Typography fontSize={20} fontWeight={500} mb={0.5}>
        <Trans>VRF Loot Box</Trans>
      </Typography>
      <Typography color="#BFBFBF" variant={"body2"}>
        <Trans>Deploy loot boxes for your players</Trans>
      </Typography>
      <Box mt={4}>
        <SoftButton
          size="small"
          onClick={() => {
            trackEvent({ key: "view-lootboxes" })
            navigate(ROUTE_LINKS.Lootboxes)
          }}
          data-cy="button-lootbox-view-addon"
        >
          <Trans>View Add-On</Trans>
        </SoftButton>
      </Box>
    </Box>
  )
}

export default LootBoxAddOnCard
