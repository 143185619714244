import { Trans } from "@lingui/macro"
import { Typography, Box, Divider, Select, MenuItem, Button } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

import MarketplacesOnProject from "../../components/modules/marketplace/MarketplacesOnProject"
import { useMarketplace } from "../../contexts/MarketplaceContext"
import { useProjectApi } from "../../contexts/ProjectContext"
import { ROUTE_LINKS } from "../../routes/routes"

const MarketplaceOnProjectPage = (): JSX.Element | null => {
  const { projects } = useProjectApi()
  const { marketplaceProject } = useMarketplace()
  const navigate = useNavigate()

  if (!marketplaceProject) return null

  return (
    <Box pt={1} pb={1}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ml: 2,
          mr: 2,
        }}
      >
        <Box display="flex" alignItems="center" mb={0.5}>
          <Typography sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }} variant="h4">
            <Trans>Marketplaces</Trans>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Select
            size="small"
            sx={{ background: "#333333", height: "36px", width: "160px" }}
            value={marketplaceProject.projectId}
            onChange={(e) => {
              navigate(ROUTE_LINKS.marketplacesOnProject(e.target.value))
            }}
            data-cy="dropdown-marketplaces-change-project"
          >
            {projects?.map((p) => (
              <MenuItem key={p.name} value={p.projectId}>
                {p.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            onClick={() => {
              navigate(ROUTE_LINKS.marketplacesCreate(marketplaceProject.projectId || ""))
            }}
            sx={{
              background: "#333333",
              pl: 2,
              pr: 2,
              ":hover": {
                background: "#333333",
              },
            }}
            data-cy="button-marketplaces-deploy-marketplace"
          >
            <Trans>Deploy Marketplace</Trans>
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <MarketplacesOnProject />
      </Box>
    </Box>
  )
}

export default MarketplaceOnProjectPage
