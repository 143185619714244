import { Trans } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { Typography, Box, Divider, Breadcrumbs } from "@mui/material"
import { useSnackbar } from "notistack"
import React from "react"
import { Link } from "react-router-dom"

import { SoftButton } from "../../components/elements/SoftButton"
import MarketplaceView from "../../components/modules/marketplace/MarketplaceView"
import { useMarketplace } from "../../contexts/MarketplaceContext"
import { useWeb3Connection } from "../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../routes/routes"
import { DRAWER_WIDTH, BANNER_HEIGHT } from "../../themes/theme"
import { centerEllipsis, getIPFSUri } from "../../utils/Helpers"
import { getNetwork } from "../../utils/NetworkHelpers"

const MarketplaceViewPage = (): JSX.Element | null => {
  const { enqueueSnackbar } = useSnackbar()
  const { marketplaceProject, marketplace } = useMarketplace()
  const { networks } = useWeb3Connection()

  if (!marketplaceProject || !marketplace) return null

  const { id, banner, chain_id, description, contract_address } = marketplace

  return (
    <Box pt={1} pb={1}>
      {banner ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: DRAWER_WIDTH,
            width: `calc(100vw - ${DRAWER_WIDTH}px)`,
            zIndex: -100,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: BANNER_HEIGHT,
              zIndex: -99,
              background: `linear-gradient(180deg, rgba(20, 20, 20, 0) 10.94%, rgba(20, 20, 20, 0.135) 29.2%, rgba(20, 20, 20, 0.285) 43%, rgba(20, 20, 20, 0.435) 56.36%, rgba(20, 20, 20, 0.83) 82.19%, #141414 100%)`,
            }}
          ></Box>
          <Box sx={{ zIndex: -100, position: "absolute", width: "100%" }}>
            <img
              style={{ width: "100%", height: BANNER_HEIGHT, objectFit: "cover", opacity: 0.5 }}
              src={getIPFSUri(banner)}
              data-cy="label-marketplace-view-image"
            />
          </Box>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          ml: 2,
          mr: 2,
        }}
      >
        <Box mb={0.5} flex={1}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 1, mb: 1 }}>
            <Link
              style={{ color: "#F5F5F5", textDecoration: "none" }}
              to={ROUTE_LINKS.marketplacesOnProject(marketplaceProject?.projectId)}
              data-cy="link-breadcrumb-project-marketplaces"
            >
              {marketplaceProject?.name || "Project"}
            </Link>
            <Link
              style={{ color: "#F5F5F5", textDecoration: "none" }}
              to={ROUTE_LINKS.marketplaceView(marketplaceProject?.projectId, marketplace.id)}
              data-cy="link-breadcrumb-marketplace-view"
            >
              {marketplace.name || "Marketplaces"}
            </Link>
            <Typography data-cy="label-breadcrumb-nfts">NFTs</Typography>
          </Breadcrumbs>
          <Typography
            sx={{ flex: 1, color: "#F5F5F5", fontWeight: 500 }}
            variant="h4"
            data-cy="label-marketplace-view-name"
          >
            {marketplace.name}
          </Typography>
          {chain_id ? (
            <Typography
              sx={{ flex: 1, mt: 0.5, color: "#F5F5F5" }}
              variant="body1"
              data-cy="label-marketplace-view-network"
            >
              {getNetwork(chain_id, networks)?.label}
            </Typography>
          ) : null}
          {description ? (
            <Typography
              sx={{ flex: 1, mt: 2, color: "#F5F5F5" }}
              variant="body1"
              data-cy="label-marketplace-view-description"
            >
              {description}
            </Typography>
          ) : null}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mt={4}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ p: "8px 16px", background: "#262626", borderRadius: 5 }}>
                <Typography color="#A5A5A5">Address</Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography color="#F5F5F5">{centerEllipsis(contract_address, 8)}</Typography>
                  <ContentCopy
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(contract_address)
                      enqueueSnackbar("Contract address copied", { variant: "success" })
                    }}
                    data-cy="button-marketplace-view-copy-address"
                  />
                </Box>
              </Box>
              <Box sx={{ p: "8px 16px", background: "#262626", borderRadius: 5 }}>
                <Typography color="#A5A5A5">ID</Typography>
                <Box display="flex" gap={1} alignItems="center">
                  <Typography color="#F5F5F5">{centerEllipsis(id, 8)}</Typography>
                  <ContentCopy
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(id)
                      enqueueSnackbar("Marketplace ID copied", { variant: "success" })
                    }}
                    data-cy="button-marketplace-view-copy-id"
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Link
                to={ROUTE_LINKS.marketplaceListNFT(marketplaceProject?.projectId, marketplace.id)}
                style={{ textDecoration: "none" }}
              >
                <SoftButton data-cy="button-marketplace-view-list-new-nft">
                  <Trans>List New NFT</Trans>
                </SoftButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box mb={4}>
        <MarketplaceView />
      </Box>
    </Box>
  )
}

export default MarketplaceViewPage
