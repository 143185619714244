// trims a string at both ends for a character
export function trimChar(str: string, char: string): string {
  char = char.charAt(0)
  if (str.charAt(0) === char) {
    str = str.substr(1, str.length - 1)
  }
  if (str.charAt(str.length - 1) === char) {
    str = str.substr(0, str.length - 1)
  }
  return str
}
