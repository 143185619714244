import { Box } from "@mui/material"
import React from "react"

import LootBoxAddOnCard from "./LootBoxAddOnCard"

const AddOns: React.FC = () => {
  return (
    <Box ml={2} mr={2}>
      {/* This will be added back when documentation is ready */}
      {/* <Box display="flex" alignItems="center" justifyContent="flex-start" flex={1}>
        <Typography>
          <Trans>Read our documentation on how to&nbsp;</Trans>
        </Typography>
        <Link
          href="https://docs.gaming.chainsafe.io/current/setting-up-an-rpc-node/"
          underline="none"
          display="flex"
          alignItems="center"
          ml={0.5}
          target="_blank"
        >
          <Trans>set up add-ons here</Trans>
          <ArrowOutIcon sx={{ mt: 1.25, ml: 0.5 }} />
        </Link>
      </Box> */}
      <Box mt={2} maxWidth={320}>
        <LootBoxAddOnCard />
      </Box>
    </Box>
  )
}

export default AddOns
