import { Trans } from "@lingui/macro"
import { Box, CircularProgress, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import type { MarketplaceItem } from "../../../../contexts/MarketplaceContext"
import { useMarketplace } from "../../../../contexts/MarketplaceContext"

import NFTItemCard from "./NFTItemCard"

const MarketplaceView: React.FC = () => {
  const { getMarketplaceItems } = useMarketplace()
  const [hasLoadedNFTs, setHasLoadedNFTs] = useState(false)
  const [NFTItems, setNFTItems] = useState<MarketplaceItem[]>([])
  const { marketplaceId } = useParams<{ marketplaceId: string }>()

  useEffect(() => {
    if (!marketplaceId) return
    setNFTItems([])
    getMarketplaceItems(marketplaceId)
      .then((NFTs) => {
        setNFTItems(NFTs)
        setHasLoadedNFTs(true)
      })
      .catch(() => {
        setHasLoadedNFTs(true)
      })
  }, [getMarketplaceItems, marketplaceId])

  if (!hasLoadedNFTs) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <CircularProgress />
      </Box>
    )
  }

  if (hasLoadedNFTs && !NFTItems.length) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Box
          width={400}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
          sx={{ border: "1px solid #333333", borderRadius: "8px", background: "#141414" }}
          data-cy="box-listed-nfts-empty"
        >
          <NoDataOrConnectionIcon />
          <Typography fontSize={20} mt={2}>
            <Trans>No NFTs listed in marketplace</Trans>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box ml={2} mr={2}>
      <Typography sx={{ fontSize: 24, mb: 3, mt: 3, fontWeight: 500 }}>Listed NFT's</Typography>
      <Box
        display="grid"
        gap={2}
        sx={(theme) => ({
          gridTemplateColumns: "1fr",
          [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
          [theme.breakpoints.up("xl")]: {
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
          },
        })}
      >
        {NFTItems.map((NFTItem) => (
          <NFTItemCard
            key={`${NFTItem.token.contract_address}-${NFTItem.token.token_id}-${NFTItem.token.uri}-${NFTItem.id}`}
            NFTItem={NFTItem}
          />
        ))}
      </Box>
    </Box>
  )
}

export default MarketplaceView
