import React, { useCallback, useState, useEffect } from "react"

import { useCountly } from "./CountlyContext"
import { useGamingApi } from "./GamingApiContext"
import { useStorageApi } from "./StorageContext"

const apiNewsletterRoutes = {
  updateSubscriptionProfile: "/newsletters/subscriptions/profile",
  check: "/newsletters/subscriptions/check",
  updateSubscriptions: "/newsletters/subscriptions/update",
}

export type SubscriptionProfile = {
  profileFirstName: string
  profileLastName: string
  profileDiscordUsername: string
  teamSize: string
  companyName: string
  companyUrl: string
  web3Experience: string
}

export type Subscriptions = {
  criticalUpdates: boolean
  productCommunications: boolean
  newsletters: boolean
}

type ProfileInfoContextProps = {
  children: React.ReactNode | React.ReactNode[]
}

interface IProfileInfo {
  "csgd.isProfileConfirmed"?: string
  "csgd.profileFirstName"?: string
  "csgd.profileLastName"?: string
  "csgd.profileDiscordUsername"?: string
  "csgd.teamSize"?: string
  "csgd.companyName"?: string
  "csgd.companyUrl"?: string
  "csgd.web3Experience"?: string
}

interface IUserLocalStore extends IProfileInfo {
  [key: string]: any
}

type ProfileInfoContext = {
  userLocalStore: IUserLocalStore | undefined
  setProfileInfo: (info: IProfileInfo) => Promise<void>
  updateSubscriptionProfile: (profile: SubscriptionProfile) => Promise<void>
  subscriptions: Subscriptions | undefined
  updateSubscriptions: (status: Subscriptions) => Promise<void>
}

const ProfileInfoContext = React.createContext<ProfileInfoContext | undefined>(undefined)

const ProfileInfoProvider = ({ children }: ProfileInfoContextProps): JSX.Element => {
  const { isLoggedIn, userInfo, storageApiClient } = useStorageApi()
  const { gamingApiInstance } = useGamingApi()
  const { trackEvent, updateTrackedProfile } = useCountly()
  const [userLocalStore, setUserLocalStore] = useState<IUserLocalStore | undefined>()
  const [subscriptions, setSubscriptions] = useState<Subscriptions | undefined>()

  useEffect(() => {
    if (userInfo && isLoggedIn) {
      storageApiClient
        .getUserLocalStore()
        .then(setUserLocalStore)
        // for new user, read user store API returns 404
        .catch((e) => {
          console.error(e)
          setUserLocalStore({})
        })
    } else {
      setUserLocalStore(undefined)
    }
  }, [userInfo, isLoggedIn, storageApiClient, updateTrackedProfile])

  // track user data
  useEffect(() => {
    const name = `${userLocalStore?.["csgd.profileFirstName"] || ""} ${userLocalStore?.["csgd.profileLastName"] || ""}`
    if (name) updateTrackedProfile(name)
  }, [userLocalStore, updateTrackedProfile])

  const setProfileInfo = useCallback(
    async (info: IProfileInfo) => {
      const userLocalStoreData: IUserLocalStore = {
        ...userLocalStore,
        ...info,
        "csgd.isProfileConfirmed": "true",
      }
      try {
        await storageApiClient.updateUserLocalStore(userLocalStoreData)
        const updatedUserLocalStore = await storageApiClient.getUserLocalStore()
        setUserLocalStore(updatedUserLocalStore)
        trackEvent({ key: "user-profile-updated" })
      } catch (e) {
        console.error(e)
      }
    },
    [storageApiClient, userLocalStore, trackEvent]
  )

  // check hubspot subscriptions
  const checkSubscriptions = useCallback(() => {
    if (!gamingApiInstance) return
    gamingApiInstance
      .get(apiNewsletterRoutes.check)
      .then(({ data }) => {
        if (data && JSON.parse(data)) {
          setSubscriptions(JSON.parse(data).subscriptions)
        }
      })
      .catch(console.error)
  }, [gamingApiInstance])

  // check subscription status on load
  useEffect(() => {
    checkSubscriptions()
  }, [checkSubscriptions])

  // add contact to hubspot
  // adds subscriptions
  const updateSubscriptionProfile = async (profile: SubscriptionProfile): Promise<undefined> => {
    if (!gamingApiInstance) return
    try {
      // update subscriptions
      await gamingApiInstance.post(apiNewsletterRoutes.updateSubscriptionProfile, profile)
      checkSubscriptions()
    } catch (err) {
      console.error(err)
      return Promise.reject()
    }
  }

  // update user email subscriptions
  const updateSubscriptions = async (subscriptionsInput: Subscriptions): Promise<undefined> => {
    if (!gamingApiInstance) return
    try {
      // update subscriptions
      const { data } = await gamingApiInstance.patch(
        apiNewsletterRoutes.updateSubscriptions,
        subscriptionsInput
      )

      setSubscriptions(JSON.parse(data).subscriptions)
    } catch (err) {
      setSubscriptions(subscriptions)
      console.error(err)
      return Promise.reject()
    }
  }

  return (
    <ProfileInfoContext.Provider
      value={{
        userLocalStore,
        setProfileInfo,
        updateSubscriptionProfile,
        subscriptions,
        updateSubscriptions,
      }}
    >
      {children}
    </ProfileInfoContext.Provider>
  )
}

const useProfileInfo = (): ProfileInfoContext => {
  const context = React.useContext(ProfileInfoContext)
  if (context === undefined) {
    throw new Error("useProfileInfo must be used within a ProfileInfoProvider")
  }
  return context
}

export { ProfileInfoProvider, useProfileInfo }
