import { Box } from "@mui/material"
import React from "react"
import { Navigate } from "react-router-dom"

import { Footer } from "../components/modules/footer/Footer"
import LoginModule from "../components/modules/login/LoginModule"
import { useStorageApi } from "../contexts/StorageContext"
import { ROUTE_LINKS } from "../routes/routes"

const LandingPage = (): JSX.Element => {
  const { isLoggedIn } = useStorageApi()

  if (isLoggedIn) {
    return <Navigate to={ROUTE_LINKS.Dashboard} replace={true} />
  }

  return (
    <Box
      sx={{
        background:
          "linear-gradient(75deg, rgba(28, 32, 24, 0.3) 70%, rgba(180, 255, 104, 0.3) 180%)",
        height: "100vh",
      }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box sx={{ ml: 2, mt: 2, width: 48, height: 48 }}>
          <img src="/chainsafe-logo-svg.svg" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          {!isLoggedIn && <LoginModule />}
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default LandingPage
