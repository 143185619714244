import { useLocalStorage, useSessionStorage } from "@chainsafe/browser-storage-hooks"
import type {
  IFilesApiClient,
  Token,
  IdentityProvider,
  IdentityToken,
  User,
} from "@chainsafe/files-api-client"
import { FilesApiClient } from "@chainsafe/files-api-client"
import { t } from "@lingui/macro"
import axios from "axios"
import jwtDecode from "jwt-decode"
import * as React from "react"
import { useState, useEffect, useMemo, useCallback } from "react"

import { useCountly } from "./CountlyContext"
import type { TokenType } from "./NFTsContext"

const tokenStorageKey = "csgd.refreshToken"

export type DirectAuthContextStatus =
  | "initializing"
  | "initialized"
  | "awaiting confirmation"
  | "logging in"
  | "done"

// opensea type
export interface INFTAttribute {
  trait_type: string
  value: string
}

export interface IUploadNFTRequest {
  name: string
  description?: string
  properties?: object
  external_url?: string
  image_data?: string
  attributes?: INFTAttribute[]
  background_color?: string
  animation_url?: string
  youtube_url?: string
  image: File
  additionalFiles: Array<File | string>
  tokenType: TokenType
  amount?: number
}

type StorageApiContextProps = {
  apiUrl?: string
  withLocalStorage?: boolean
  children: React.ReactNode | React.ReactNode[]
}

type StorageApiContext = {
  userInfo?: User
  storageApiClient: IFilesApiClient
  isLoggedIn: boolean | undefined
  login(
    loginType: IdentityProvider,
    tokenInfo?: { token: IdentityToken; email: string }
  ): Promise<void>
  logout: () => void
  uploadNFTData: (request: IUploadNFTRequest) => Promise<string | undefined>
  status: DirectAuthContextStatus
  resetStatus(): void
  accountRestricted?: boolean
  accessToken: Token | undefined
}

const StorageApiContext = React.createContext<StorageApiContext | undefined>(undefined)

const StorageApiProvider = ({
  apiUrl,
  withLocalStorage = true,
  children,
}: StorageApiContextProps): JSX.Element => {
  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true"
  const { canUseLocalStorage, localStorageRemove, localStorageGet, localStorageSet } =
    useLocalStorage()
  const { sessionStorageRemove, sessionStorageGet, sessionStorageSet } = useSessionStorage()
  const { trackEvent, updateProfileOnLogin, updateProfileOnLogout } = useCountly()

  // initializing api
  const initialAxiosInstance = useMemo(
    () =>
      axios.create({
        // Disable the internal Axios JSON de serialization as this is handled by the client
        transformResponse: [],
      }),
    []
  )

  const initialApiClient = useMemo(() => {
    return new FilesApiClient({}, apiUrl, initialAxiosInstance)
  }, [apiUrl, initialAxiosInstance])

  const [storageApiClient, setStorageApiClient] = useState<FilesApiClient>(initialApiClient)
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const [userInfo, setUserInfo] = useState<User | undefined>()
  const [status, setStatus] = useState<DirectAuthContextStatus>("initialized")

  // access tokens
  const [accessToken, setAccessToken] = useState<Token | undefined>(undefined)
  const [refreshToken, setRefreshToken] = useState<Token | undefined>(undefined)
  const [accountRestricted, setAccountRestricted] = useState(false)

  const setTokensAndSave = useCallback(
    (accessToken: Token, refreshToken: Token) => {
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
      refreshToken.token && withLocalStorage && localStorageSet(tokenStorageKey, refreshToken.token)
      !withLocalStorage && sessionStorageSet(tokenStorageKey, refreshToken.token)
      accessToken.token && storageApiClient.setToken(accessToken.token)
      setIsLoadingUser(false)
    },
    [storageApiClient, localStorageSet, sessionStorageSet, withLocalStorage]
  )

  useEffect(() => {
    const initializeApiClient = async (): Promise<void> => {
      const axiosInstance = axios.create({
        // Disable the internal Axios JSON de serialization as this is handled by the client
        transformResponse: [],
      })

      axiosInstance.interceptors.response.use(
        (response) => {
          return response
        },
        async (error) => {
          if (!error?.config?._retry && error?.response?.status === 401 && !maintenanceMode) {
            error.config._retry = true
            const refreshTokenLocal = withLocalStorage
              ? localStorageGet(tokenStorageKey)
              : sessionStorageGet(tokenStorageKey)
            if (refreshTokenLocal) {
              const refreshTokenApiClient = new FilesApiClient({}, apiUrl, axiosInstance)
              try {
                const { access_token, refresh_token } = await refreshTokenApiClient.getRefreshToken(
                  {
                    refresh: refreshTokenLocal,
                  }
                )

                setTokensAndSave(access_token, refresh_token)
                error.response.config.headers.Authorization = `Bearer ${access_token.token}`
                return axios(error.response.config)
              } catch (err) {
                localStorageRemove(tokenStorageKey)
                !withLocalStorage && sessionStorageRemove(tokenStorageKey)
                setRefreshToken(undefined)
                return Promise.reject(error)
              }
            } else {
              localStorageRemove(tokenStorageKey)
              !withLocalStorage && sessionStorageRemove(tokenStorageKey)
              setRefreshToken(undefined)
              return Promise.reject(error)
            }
          }
          return Promise.reject(error)
        }
      )

      const apiClient = new FilesApiClient({}, apiUrl, axiosInstance)
      const savedRefreshToken = withLocalStorage
        ? localStorageGet(tokenStorageKey)
        : sessionStorageGet(tokenStorageKey)

      setStorageApiClient(apiClient)
      if (!maintenanceMode && savedRefreshToken) {
        try {
          const { access_token, refresh_token } = await apiClient.getRefreshToken({
            refresh: savedRefreshToken,
          })

          setTokensAndSave(access_token, refresh_token)
        } catch (error) {
          setIsLoadingUser(false)
          console.error("There was an error refreshing the saved token")
          console.error(error)
        }
      }
    }

    initializeApiClient()
  }, [canUseLocalStorage])

  useEffect(() => {
    if (accessToken && accessToken.token && storageApiClient) {
      storageApiClient?.setToken(accessToken.token)
      const decodedAccessToken = jwtDecode<{ perm: { secured?: string; files?: string } }>(
        accessToken.token
      )

      if (decodedAccessToken.perm.files === "restricted") {
        setAccountRestricted(true)
      } else {
        setAccountRestricted(false)
      }
    }
  }, [accessToken, storageApiClient])

  const isLoggedIn = useMemo(() => {
    if (isLoadingUser) {
      return undefined
    }
    if (isLoadingUser === false && refreshToken) {
      return true
    } else {
      return false
    }
  }, [refreshToken, isLoadingUser])

  useEffect(() => {
    if (isLoggedIn) {
      storageApiClient
        .getUser()
        .then((userInfo) => {
          setUserInfo(userInfo)

          // setting tracked user id
          updateProfileOnLogin(userInfo)
        })
        .catch(console.error)
    }
  }, [isLoggedIn, storageApiClient, updateProfileOnLogin])

  const getIdentityToken = async (
    loginType: IdentityProvider,
    tokenInfo?: { token: IdentityToken; email: string }
  ): Promise<{ identityToken: IdentityToken; userInfo: any }> => {
    if (loginType === "email") {
      if (!tokenInfo) {
        throw new Error("token not provided")
      } else {
        return {
          identityToken: tokenInfo.token,
          userInfo: { email: tokenInfo?.email },
        }
      }
    } else {
      throw Error("Alternatives not supported")
    }
  }

  const login = async (
    loginType: IdentityProvider,
    tokenInfo?: { token: IdentityToken; email: string }
  ): Promise<void> => {
    if (!storageApiClient || maintenanceMode) return

    try {
      setStatus("awaiting confirmation")
      const { identityToken } = await getIdentityToken(loginType, tokenInfo)
      const { access_token, refresh_token } = await storageApiClient.loginUser({
        provider: loginType,
        service: "gaming",
        token: identityToken.token,
      })
      setStatus("logging in")
      setTokensAndSave(access_token, refresh_token)

      // tracking with countly
      const userId = jwtDecode<{ uuid: string }>(access_token?.token || "").uuid
      trackEvent({ key: "login", segmentation: { user_id: userId } })
    } catch (error) {
      console.error(error)
      throw new Error("Login Error")
    }
  }

  const logout = (): void => {
    setAccessToken(undefined)
    setRefreshToken(undefined)
    storageApiClient.setToken("")
    localStorageRemove(tokenStorageKey)
    setStatus("initialized")
    !withLocalStorage && sessionStorageRemove(tokenStorageKey)

    // tracking with countly
    updateProfileOnLogout()
    trackEvent({ key: "logout" })
  }

  const uploadNFTData = async (request: IUploadNFTRequest): Promise<string | undefined> => {
    if (!storageApiClient || maintenanceMode || !accessToken) return

    try {
      // upload NFT
      const { cid } = await storageApiClient.uploadNFT(request, "blake2b-208")
      return cid
    } catch (error) {
      console.error(error)
      return Promise.reject(t`upload NFT failed`)
    }
  }

  return (
    <StorageApiContext.Provider
      value={{
        accessToken,
        storageApiClient,
        isLoggedIn,
        login,
        resetStatus: () => setStatus("initialized"),
        status,
        userInfo,
        logout,
        uploadNFTData,
        accountRestricted,
      }}
    >
      {children}
    </StorageApiContext.Provider>
  )
}

const useStorageApi = (): StorageApiContext => {
  const context = React.useContext(StorageApiContext)
  if (context === undefined) {
    throw new Error("useStorage must be used within a StorageProvider")
  }
  return context
}

export { StorageApiProvider, useStorageApi }
