import { Trans } from "@lingui/macro"
import { Box, Typography } from "@mui/material"
import React from "react"

import { BlankCircleIcon } from "../../../assets/icons/BlankCircleIcon"
import { CheckedCircleIcon } from "../../../assets/icons/CheckedCircleIcon"
import { useProfileInfo } from "../../../contexts/ProfileInfoContext"

const SubscriptionsView: React.FC = () => {
  const { subscriptions } = useProfileInfo()

  return (
    <Box mr={2} ml={2}>
      <Box>
        <Typography sx={{ fontSize: 24 }}>
          <Trans>Email Subscriptions</Trans>
        </Typography>
      </Box>
      <Box mt={3}>
        <Box
          mt={2}
          pt={1.5}
          pb={1.25}
          pl={2}
          display="flex"
          alignItems="center"
          sx={{
            border: "1px solid #595959",
            borderRadius: 4,
            backgroundColor: "#262626",
            opacity: subscriptions?.productCommunications ? 0.6 : 1,
          }}
        >
          {subscriptions?.productCommunications ? (
            <CheckedCircleIcon data-cy="icon-product-communications-checkbox-checked" />
          ) : (
            <BlankCircleIcon data-cy="icon-product-communications-checkbox-unchecked" />
          )}
          <Typography sx={{ ml: 0.5, mb: 0.4 }}>
            <Trans>Communications from our Product team</Trans>
          </Typography>
        </Box>

        <Box
          mt={2}
          pt={1.5}
          pb={1.25}
          pl={2}
          display="flex"
          alignItems="center"
          sx={{
            border: "1px solid #595959",
            borderRadius: 4,
            backgroundColor: "#262626",
            opacity: subscriptions?.newsletters ? 0.6 : 1,
          }}
        >
          {subscriptions?.newsletters ? (
            <CheckedCircleIcon data-cy="icon-newsletters-checkbox-checked" />
          ) : (
            <BlankCircleIcon data-cy="icon-newsletters-checkbox-unchecked" />
          )}
          <Typography sx={{ ml: 0.5, mb: 0.4 }}>
            <Trans>Monthly Gaming community newsletter</Trans>
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Typography variant="body2" fontStyle="italic" color="#D9D9D9" textAlign="left">
          <Trans>
            *To unsubscribe from a mailing list, click the ‘unsubscribe’ button at the bottom of the
            email you received.
          </Trans>
        </Typography>
      </Box>
    </Box>
  )
}

export default SubscriptionsView
