import { Trans } from "@lingui/macro"
import { ContentCopy } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"

import type {
  NFTCollection} from "../../../../contexts/NFTsContext";
import {
  COLLECTION_SYNC_TIME_IN_SECONDS,
  useNFTs,
} from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../../routes/routes"
import { centerEllipsis, getIPFSUri } from "../../../../utils/Helpers"
import { getNetwork } from "../../../../utils/NetworkHelpers"






import DeleteCollectionModal from "./DeleteCollectionModal"
import RedeployCollectionModal from "./RedeployCollectionModal"

interface IProps {
  collection: NFTCollection
}

const CollectionCard: React.FC<IProps> = ({ collection }) => {
  const { getCollections, NFTsProject, createCollectionOnChain, deleteCollection } = useNFTs()
  const { enqueueSnackbar } = useSnackbar()
  const { networks } = useWeb3Connection()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isRedeployModalOpen, setIsRedeployModalOpen] = useState(false)
  const [isLoadingDeleteCollection, setIsLoadingDeleteCollection] = useState(false)
  const [isLoadingRedeployCollection, setIsLoadingRedeployCollection] = useState(false)

  const { name, banner, contract_address, chain_id, type, created_at } = collection
  const hasSynced = useMemo(
    () =>
      contract_address
        ? true
        : dayjs().diff(dayjs(created_at * 1000)) / 1000 > COLLECTION_SYNC_TIME_IN_SECONDS,
    [created_at, contract_address]
  )

  const onDeleteFailedCollection = (): void => {
    if (!collection || !NFTsProject) return
    setIsLoadingDeleteCollection(true)
    deleteCollection(collection.id)
      .then(() => {
        enqueueSnackbar("Collection deleted from records", { variant: "success" })
        getCollections()
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to delete collection", { variant: "error" })
      })
      .finally(() => {
        setIsLoadingDeleteCollection(false)
      })
  }

  const onRedeployFailedCollection = (): void => {
    if (!collection || !NFTsProject) return
    setIsLoadingRedeployCollection(true)
    createCollectionOnChain(collection)
      .then(() => {
        enqueueSnackbar("New collection deployed", { variant: "success" })
        getCollections()
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to deploy collection", { variant: "error" })
      })
      .finally(() => {
        setIsLoadingRedeployCollection(false)
      })
  }

  if (!NFTsProject) return null

  const CollectionCard = (
    <Box
      width="100%"
      sx={{ p: 1, borderRadius: "12px", border: "1px solid #262626", background: "#141414" }}
    >
      <Box height={180} display="flex" alignItems="center" mt={1.25} mb={1.25}>
        {banner ? (
          <img
            style={{ width: "100%", maxHeight: 200, borderRadius: "8px", objectFit: "cover" }}
            src={getIPFSUri(banner)}
            alt="collection"
          />
        ) : (
          <Box height="180px" width="100%" sx={{ background: "#595959", borderRadius: "8px" }} />
        )}
      </Box>
      <Box p={1}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="18px" data-cy="label-collection-name">
            {name}
          </Typography>
          <Typography
            fontSize="14px"
            color="#8C8C8C"
            sx={{ pt: 0.5 }}
            data-cy="label-collection-token-and-network-type"
          >
            {type}, {getNetwork(chain_id, networks)?.label || "unknown"}
          </Typography>
        </Box>
        <Box>
          {contract_address ? (
            <Box display="flex" gap={1} alignItems="center">
              <Typography color="#8C8C8C">{centerEllipsis(contract_address, 8)}</Typography>
              <ContentCopy
                color="primary"
                sx={{ cursor: "pointer", fontSize: 16 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  enqueueSnackbar("Collection address copied", { variant: "success" })
                  navigator.clipboard.writeText(contract_address)
                }}
                data-cy="button-collection-copy-address"
              />
            </Box>
          ) : !hasSynced ? (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography data-cy="label-collection-syncing" color="#FFE58F">
                <Trans>Syncing...</Trans>
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography color="#FFE58F">
                <Trans>Not on-chain</Trans>
              </Typography>
              <Box display="flex" gap={1.5}>
                <Button
                  sx={{
                    color: "#FFFFFF",
                    textDecoration: "underline",
                    p: 0,
                    minWidth: "36px",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  disabled={isLoadingDeleteCollection || isLoadingRedeployCollection}
                  onClick={() => {
                    setIsRedeployModalOpen(true)
                  }}
                  data-cy="button-collection-retry-deployment"
                >
                  <Trans>Retry deployment</Trans>
                </Button>
                <Button
                  sx={{
                    color: "#BFBFBF",
                    textDecoration: "underline",
                    p: 0,
                    minWidth: "26px",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => {
                    setIsDeleteModalOpen(true)
                  }}
                  data-cy="button-collection-delete"
                >
                  <Trans>Delete</Trans>
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {hasSynced && contract_address ? (
        <Link
          to={ROUTE_LINKS.NFTsOnCollection(NFTsProject.projectId, collection.id)}
          style={{ textDecoration: "none" }}
          data-cy="link-collection-go-to-nfts"
        >
          {CollectionCard}
        </Link>
      ) : (
        CollectionCard
      )}
      <DeleteCollectionModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        isLoadingDelete={isLoadingDeleteCollection}
        onDeleteCollection={onDeleteFailedCollection}
        collection={collection}
      />
      <RedeployCollectionModal
        isOpen={isRedeployModalOpen}
        onClose={() => setIsRedeployModalOpen(false)}
        isLoadingRedeploy={isLoadingRedeployCollection}
        onRedeployCollection={onRedeployFailedCollection}
        collection={collection}
      />
    </>
  )
}

export default CollectionCard
