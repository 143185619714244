import { Trans } from "@lingui/macro"
import { Box, Chip, Typography } from "@mui/material"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useProjectApi } from "../../../contexts/ProjectContext"
import { ROUTE_LINKS } from "../../../routes/routes"
import { formatNumberWithMultiplier } from "../../../utils/Helpers"

import DailyMethodCalls from "./AnalyticsGraphs/DailyMethodCalls"
import DailyPlayerCalls from "./AnalyticsGraphs/DailyPlayerCalls"
import TotalAPICalls from "./AnalyticsGraphs/TotalMethodCalls"
import TotalPlayerCount from "./AnalyticsGraphs/TotalPlayerCount"

const AnalyticsBody: React.FC = () => {
  const { projects, getProjectAnalytics, isProjectsLoading } = useProjectApi()
  const { projectId } = useParams()
  const navigate = useNavigate()

  const [totalCalls, setTotalCalls] = useState<number | undefined>(undefined)
  const [dailyPlayerCalls, setDailyPlayerCalls] = useState<
    { player: string; calls: number }[] | undefined
  >(undefined)
  const [dailyMethodCalls, setDailyMethodCalls] = useState<
    { method: string; calls: number }[] | undefined
  >(undefined)

  useEffect(() => {
    if (!projectId && projects?.length && !isProjectsLoading) {
      navigate(ROUTE_LINKS.AnalyticsOnProject(projects[0].projectId))
    }
  }, [navigate, projectId, projects, isProjectsLoading])

  const getAllAnalytics = useCallback(async () => {
    if (!projectId) return
    const yesterday = dayjs().subtract(1, "day").unix()
    const today = dayjs().unix()

    // total calls
    getProjectAnalytics(projectId, {
      name: "method",
      from: yesterday,
      to: today,
    })
      .then((totalCallsResp) => {
        if (totalCallsResp) {
          setTotalCalls(
            totalCallsResp.values.length && totalCallsResp.values[0].length
              ? totalCallsResp.values[0].at(-1)
              : undefined
          )
        }
      })
      .catch(console.error)

    // 24 hours players
    await getProjectAnalytics(projectId, {
      name: "player",
      group_by: "player_id",
      from: yesterday,
      to: today,
    })
      .then((dailyPlayerCallsResp) => {
        if (dailyPlayerCallsResp) {
          setDailyPlayerCalls(
            dailyPlayerCallsResp.series.map((s, i) => ({
              player: s.group_tags[0].includes("player_id:")
                ? s.group_tags[0].split("player_id:")[1]
                : s.group_tags[0],
              calls:
                dailyPlayerCallsResp.values[i].at(-1) || dailyPlayerCallsResp.values[i].at(-2) || 0,
            }))
          )
        }
      })
      .catch(console.error)

    // daily method wise calls
    getProjectAnalytics(projectId, {
      name: "method",
      group_by: "method",
      from: yesterday,
      to: today,
    })
      .then((dailyMethodCallsResp) => {
        if (dailyMethodCallsResp) {
          setDailyMethodCalls(
            dailyMethodCallsResp.series.map((s, i) => ({
              method: s.group_tags[0].includes("method:")
                ? s.group_tags[0].split("method:")[1]
                : s.group_tags[0],
              calls:
                dailyMethodCallsResp.values[i].at(-1) || dailyMethodCallsResp.values[i].at(-2) || 0,
            }))
          )
        }
      })
      .catch(console.error)
  }, [projectId, getProjectAnalytics])

  useEffect(() => {
    getAllAnalytics()
  }, [getAllAnalytics])

  if (!isProjectsLoading && !projects?.length) {
    return (
      <Box
        pt={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="#8C8C8C"
        data-cy="label-no-projects-available"
      >
        <Trans>No projects available</Trans>
      </Box>
    )
  }

  return (
    <Box mb={16}>
      {/* projects buttons */}
      <Box data-cy="box-analytics-projects" mr={2} ml={2} mt={3} mb={3}>
        {projects?.map((p) => {
          const isActive = projectId === p.projectId
          return (
            <Chip
              key={p.projectId}
              label={p.name}
              variant="outlined"
              clickable={true}
              color={p.name ? "primary" : "default"}
              size="medium"
              sx={{
                pt: 2,
                pb: 2,
                pl: 0.75,
                pr: 0.75,
                borderRadius: 16,
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "NeueMontreal",
                backgroundColor: isActive ? "#DFF7C7" : "#333333",
                color: isActive ? "#141414" : "#8C8C8C",
                borderColor: "transparent",
                mr: 2,
                mb: 2,
                minWidth: 100,
                maxWidth: 200,
                ":hover": {
                  backgroundColor: isActive ? "#DFF7C7 !important" : "#333333 !important",
                  color: isActive ? "#141414" : "#8C8C8C",
                },
              }}
              onClick={() => {
                navigate(ROUTE_LINKS.AnalyticsOnProject(p.projectId))
              }}
            />
          )
        })}
      </Box>
      {/* activity cards */}
      <Box display="flex" mr={2} ml={2} mb={3} gap={3}>
        <Box
          sx={{
            borderRadius: 2,
            border: "1px solid #333333",
            pt: 0.5,
            pb: 0.5,
            pl: 2.5,
            pr: 2.5,
            backgroundColor: "#141414",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Box>
            <Typography data-cy="label-active-users" color="#B4FF68" fontSize={48}>
              {dailyPlayerCalls ? formatNumberWithMultiplier(dailyPlayerCalls.length) : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={18}>
              <Trans>Active users</Trans>
            </Typography>
            <Typography color="#8C8C8C" variant="body2">
              <Trans>in the last 24 hours</Trans>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            border: "1px solid #333333",
            pt: 0.5,
            pb: 0.5,
            pl: 2.5,
            pr: 2.5,
            backgroundColor: "#141414",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Box>
            <Typography data-cy="label-total-calls" color="#B4FF68" fontSize={48}>
              {totalCalls ? formatNumberWithMultiplier(totalCalls) : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={18}>
              <Trans>Total calls</Trans>
            </Typography>
            <Typography color="#8C8C8C" variant="body2">
              <Trans>in the last 24 hours</Trans>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mr={2} ml={2} gap={3} display="flex" flexDirection="column">
        {/* first row of graph */}
        <Box display="flex" gap={2} flex={1}>
          <DailyPlayerCalls data={dailyPlayerCalls || []} />
          <DailyMethodCalls data={dailyMethodCalls || []} />
        </Box>
        {/* second row of graphs */}
        <Box display="flex" gap={2} flex={1}>
          <TotalPlayerCount projectId={projectId} />
          <TotalAPICalls projectId={projectId} />
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyticsBody
