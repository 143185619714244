import { Trans } from "@lingui/macro"
import { Box, MenuItem, Select, Typography } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
  Tooltip,
} from "recharts"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { useCountly } from "../../../../contexts/CountlyContext"
import { useProjectApi } from "../../../../contexts/ProjectContext"
import { formatNumberWithComma } from "../../../../utils/Helpers"

const BAR_SIZE = 30
const BAR_GAP = 15

const TotalMethodCalls: React.FC<{ projectId?: string }> = ({ projectId }) => {
  const { trackEvent } = useCountly()
  const { getProjectAnalytics } = useProjectApi()

  const [timeRange, setTimeRange] = useState<"last7Days" | "last30Days" | "last6Months">(
    "last7Days"
  )
  const [data, setData] = useState<{ method: string; calls: number }[]>([])

  useEffect(() => {
    // total method wise calls
    if (!projectId) return
    getProjectAnalytics(projectId, {
      name: "method",
      group_by: "method",
      from: dayjs()
        .subtract(timeRange === "last7Days" ? 7 : timeRange === "last30Days" ? 30 : 180, "days")
        .unix(),
      to: dayjs().unix(),
    })
      .then((totalMethodCallsResp) => {
        setData(
          totalMethodCallsResp?.series.map((s, i) => ({
            method: s.group_tags[0].includes("method:")
              ? s.group_tags[0].split("method:")[1]
              : s.group_tags[0],
            calls: totalMethodCallsResp.values[i].reduce((s, a) => s + a, 0),
          })) || []
        )
      })
      .catch(console.error)
  }, [projectId, timeRange, getProjectAnalytics])

  return (
    <Box
      width="100%"
      sx={{
        border: "1px solid #333333",
        borderRadius: 2,
      }}
    >
      <Box borderBottom="1px solid #434343">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          ml={3}
          mr={3}
          mt={1.5}
          mb={1.25}
        >
          <Typography fontSize={18}>Total calls</Typography>
          <Select
            value={timeRange}
            size="small"
            onChange={(e) => {
              trackEvent({
                key: "analytics-time-range-toggle",
                segmentation: { project_id: projectId },
              })
              setTimeRange(e.target.value as any)
            }}
            sx={{ height: 34 }}
            data-cy="dropdown-total-method-calls"
          >
            <MenuItem data-cy="dropdown-option-total-method-calls-7-days" value={"last7Days"}>
              Last 7 days
            </MenuItem>
            <MenuItem data-cy="dropdown-option-total-method-calls-30-days" value={"last30Days"}>
              Last 30 days
            </MenuItem>
            <MenuItem data-cy="dropdown-option-total-method-calls-6-months" value={"last6Months"}>
              Last 6 months
            </MenuItem>
          </Select>
        </Box>
      </Box>
      {data.length ? (
        <Box data-cy="chart-total-method-calls" sx={{ overflowY: "scroll", maxHeight: 226 }}>
          <Box height={data.length * (BAR_SIZE + BAR_GAP)} pt={1}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 5, right: 24, left: 8, bottom: 0 }}
                barCategoryGap={BAR_GAP}
                barSize={BAR_SIZE}
              >
                <XAxis
                  type="number"
                  tick={false}
                  axisLine={false}
                  tickFormatter={(_, index) => {
                    return data[index].method
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="method"
                  tickLine={false}
                  axisLine={false}
                  tick={{ fill: "#DFF7C7", fontSize: "12px" }}
                  tickFormatter={(_, index) => {
                    return formatNumberWithComma(data[index].calls, 0)
                  }}
                />
                <Bar dataKey="calls" fill="#31372B" radius={[3, 3, 3, 3]}>
                  <LabelList dataKey="method" position="insideLeft" offset={15} fill="#F5F5F5" />
                  {data.map((entry, index) => (
                    <Cell fontSize="14px" key={`cell-${index}`}>
                      {entry.method}
                    </Cell>
                  ))}
                </Bar>
                <Tooltip
                  content={({ label, payload }) => (
                    <Box
                      sx={{
                        backgroundColor: "#141414",
                        border: "1px solid #B4FF68",
                        padding: "8px 12px 8px 12px",
                        borderRadius: 1,
                      }}
                    >
                      <Typography color="#8C8C8C">{label}</Typography>
                      <Typography color="#DFF7C7">
                        {payload?.length ? payload[0].value : "-"} calls
                      </Typography>
                    </Box>
                  )}
                  cursor={false}
                  wrapperStyle={{ outline: "none" }}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="75%"
          gap={1}
          data-cy="label-empty-total-calls"
        >
          <NoDataOrConnectionIcon />
          <Typography>
            <Trans>No calls made</Trans>
          </Typography>
          <Typography variant="body2" color="#8C8C8C">
            <Trans>Project analytics will show here once there is activity.</Trans>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default TotalMethodCalls
