import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function UploadIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} sx={{ width: 36, height: 36 }}>
      <svg
        width="37"
        height="37"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3505_8608)">
          <path
            d="M10.8974 27.5905C9.00044 27.5905 7.18116 26.8697 5.8398 25.5867C4.49844 24.3036 3.74487 22.5635 3.74487 20.749C3.74487 18.9345 4.49844 17.1943 5.8398 15.9112C7.18116 14.6282 9.00044 13.9074 10.8974 13.9074C11.3454 11.9115 12.6561 10.1574 14.5411 9.03122C15.4744 8.47357 16.5206 8.08683 17.62 7.89308C18.7194 7.69934 19.8505 7.70238 20.9486 7.90203C22.0467 8.10169 23.0903 8.49405 24.02 9.05671C24.9496 9.61937 25.747 10.3413 26.3666 11.1813C26.9862 12.0213 27.4159 12.9629 27.6312 13.9524C27.8464 14.9419 27.8431 15.9598 27.6212 16.9481H29.1416C30.5528 16.9481 31.9063 17.5087 32.9042 18.5066C33.9021 19.5046 34.4628 20.858 34.4628 22.2693C34.4628 23.6806 33.9021 25.0341 32.9042 26.032C31.9063 27.0299 30.5528 27.5905 29.1416 27.5905H27.6212"
            stroke="#DFF7C7"
            strokeWidth="3.80087"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.939 23.0295L18.5 18.4685L23.061 23.0295"
            stroke="#DFF7C7"
            strokeWidth="3.80087"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5 18.4685V32.1516"
            stroke="#DFF7C7"
            strokeWidth="3.80087"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3505_8608">
            <rect
              width="36.4883"
              height="36.4883"
              fill="white"
              transform="translate(0.255859 0.224365)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
