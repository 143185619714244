import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

interface IIconProps extends SvgIconProps {
  fill?: string
}

export function RPCNodesIcon(props: IIconProps): JSX.Element {
  const { fill = "#8C8C8C" } = props
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.875 17.8145V8.18555C21.8743 8.04646 21.8368 7.91003 21.7666 7.79001C21.6963 7.67 21.5956 7.57064 21.4746 7.50195L12.8809 2.66797C12.7651 2.60111 12.6337 2.56592 12.5 2.56592C12.3663 2.56592 12.2349 2.60111 12.1191 2.66797L3.52539 7.50195C3.40444 7.57064 3.30375 7.67 3.23345 7.79001C3.16315 7.91003 3.12575 8.04646 3.125 8.18555V17.8145C3.12575 17.9535 3.16315 18.09 3.23345 18.21C3.30375 18.33 3.40444 18.4294 3.52539 18.498L12.1191 23.332C12.2349 23.3989 12.3663 23.4341 12.5 23.4341C12.6337 23.4341 12.7651 23.3989 12.8809 23.332L21.4746 18.498C21.5956 18.4294 21.6963 18.33 21.7666 18.21C21.8368 18.09 21.8743 17.9535 21.875 17.8145Z"
          fill={fill}
        />
        <path
          d="M21.7676 7.78516L12.5879 13L3.23242 7.78516"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5879 13L12.5 23.4297"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
