import type { IdentityProvider } from "@chainsafe/files-api-client"
import { t, Trans } from "@lingui/macro"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Typography, TextField } from "@mui/material"
import dayjs from "dayjs"
import { useFormik } from "formik"
import { useSnackbar } from "notistack"
import React, { useCallback, useState } from "react"

import { MAINTENANCE_MODE, MAINTENANCE_WINDOW_TIMESTAMP } from "../../../config"
import { useCountly } from "../../../contexts/CountlyContext"
import { useStorageApi } from "../../../contexts/StorageContext"
import { emailValidation } from "../../../utils/ValidationSchema"
import { RoundIconButton } from "../../elements/RoundIconButton"

import PasswordlessEmail from "./PasswordlessEmail"

const LoginModule = (): JSX.Element => {
  const { resetStatus } = useStorageApi()
  const [loginMode, setLoginMode] = useState<IdentityProvider>()
  const [isConnecting, setIsConnecting] = useState(false)
  const { storageApiClient } = useStorageApi()
  const [email, setEmail] = useState("")
  const { enqueueSnackbar } = useSnackbar()
  const { trackEvent } = useCountly()

  // const handleSelectWalletAndConnect = async () => {
  //   try {
  //     await selectWallet()
  //   } catch (error) {
  //     enqueueSnackbar(t`There was an error connecting your wallet`, { variant: "error" })
  //   }
  // }

  const resetLogin = async (): Promise<void> => {
    setEmail("")
    setLoginMode(undefined)
    resetStatus()
  }

  const onSubmitEmail = useCallback(
    (values: { email: string }) => {
      setIsConnecting(true)
      const trimmedEmail = values.email.trim()

      storageApiClient
        .getIdentityEmailToken({ email: trimmedEmail })
        .then(() => {
          setEmail(trimmedEmail)
          setLoginMode("email")

          // event tracking
          trackEvent({ key: "login-attempt", segmentation: { email: trimmedEmail } })
        })
        .catch((e) => {
          console.error(e)
          enqueueSnackbar(t`Login failed`, { variant: "error" })
        })
        .finally(() => setIsConnecting(false))
    },
    [storageApiClient, enqueueSnackbar, trackEvent]
  )

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidation,
    onSubmit: onSubmitEmail,
  })

  const shouldShowMaintenance =
    !MAINTENANCE_MODE &&
    !!MAINTENANCE_WINDOW_TIMESTAMP &&
    dayjs.unix(MAINTENANCE_WINDOW_TIMESTAMP).isAfter(dayjs())

  const initialView = (): JSX.Element => {
    return (
      <Box width={420} sx={{ p: 2, mt: 14 }}>
        <Typography sx={{ color: "#AFAFAF", fontSize: 20 }} mb={1} variant="h6" align="center">
          Get started with
          <br />
        </Typography>
        <Typography mb={5} variant="h4" align="center" sx={{ fontSize: 34 }}>
          ChainSafe Gaming
        </Typography>
        <Box>
          <form
            onSubmit={emailFormik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              id="email"
              name="email"
              data-cy="input-email"
              label="Email address"
              size="small"
              placeholder="john@gmail.com"
              value={emailFormik.values.email}
              onChange={emailFormik.handleChange}
              error={emailFormik.touched.email && !!emailFormik.errors.email}
              helperText={emailFormik.touched.email && emailFormik.errors.email}
              disabled={MAINTENANCE_MODE}
            />
            <RoundIconButton
              color="primary"
              variant="contained"
              type="submit"
              size="medium"
              disabled={MAINTENANCE_MODE || isConnecting || !emailFormik.values.email}
              loading={isConnecting}
              sx={{ mt: 2 }}
              data-cy="button-login-with-email"
            >
              <ArrowForwardIcon />
            </RoundIconButton>
          </form>
          {/* <Typography
            variant="body1"
            sx={{ mt: 2, mb: 2 }}
            align="center"
          >
            <Trans>Or</Trans>
          </Typography>
          <Button
            variant="contained"
            data-cy="button-web3"
            fullWidth
            onClick={() => {
              setLoginMode("web3")
              handleSelectWalletAndConnect()
            }}
            size="large"
            disabled={MAINTENANCE_MODE || isConnecting || status !== "initialized"}
          >
            <Trans>Continue with Web3 Wallet</Trans>
          </Button> */}
          {shouldShowMaintenance ? (
            <Typography
              variant="h5"
              align="center"
              color="darkred"
              sx={{ textDecoration: "underline", mt: 2 }}
            >
              <Trans>
                System maintenance is scheduled to start at{" "}
                {dayjs.unix(MAINTENANCE_WINDOW_TIMESTAMP || 0).format("YYYY-MM-DD HH:mm a")}. The
                system will be unavailable.
              </Trans>
            </Typography>
          ) : null}
          {MAINTENANCE_MODE ? (
            <Typography
              variant="h5"
              align="center"
              color="darkred"
              sx={{ textDecoration: "underline", mt: 2 }}
            >
              <Trans>System is currently unavailable for maintenance !</Trans>
            </Typography>
          ) : null}
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {!loginMode ? initialView() : <PasswordlessEmail resetLogin={resetLogin} email={email} />}
    </Box>
  )
}

export default LoginModule
