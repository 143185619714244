import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function ArrowOutIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4.75V1H8.25"
          stroke="#DFF7C7"
          strokeWidth="1.40625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 5.5L12 1"
          stroke="#DFF7C7"
          strokeWidth="1.40625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7.50006V11.5001C10 11.6327 9.94732 11.7598 9.85355 11.8536C9.75979 11.9474 9.63261 12.0001 9.5 12.0001H1.5C1.36739 12.0001 1.24021 11.9474 1.14645 11.8536C1.05268 11.7598 1 11.6327 1 11.5001V3.50006C1 3.36745 1.05268 3.24028 1.14645 3.14651C1.24021 3.05274 1.36739 3.00006 1.5 3.00006H5.5"
          stroke="#DFF7C7"
          strokeWidth="1.40625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
