import { Trans, t } from "@lingui/macro"
import { Close } from "@mui/icons-material"
import { Box, Typography, Modal, Button, TextField } from "@mui/material"
// import { cid as isCID } from "is-ipfs"
import React, { useCallback, useState } from "react"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 640,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const MintAddCIDModal: React.FC<{
  isOpen: boolean
  onCancel: () => void
  onAddCIDs: (CIDs?: string[]) => void
}> = ({ isOpen, onCancel, onAddCIDs }) => {
  const [CIDs, setCIDs] = useState<string[]>([])
  const [CID, setCID] = useState("")
  const [isCIDError, setIsCIDError] = useState(false)

  const onSubmitCIDs = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      // if (!isCID(CID)) return setIsCIDError(true)

      setCID("")

      if (CIDs.includes(CID)) return
      setCIDs((prevState) => [...prevState, CID])
      setIsCIDError(false)
    },
    [CID, CIDs]
  )

  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Typography variant="h6" mb={4}>
          <Trans>Add CID</Trans>
        </Typography>

        {CIDs.length ? (
          <Box mb={4}>
            <Box display="flex" mb={1}>
              <Typography width="15%" color="#8C8C8C">
                <Trans>File type</Trans>
              </Typography>
              <Typography color="#8C8C8C" maxWidth="75%">
                <Trans>Name</Trans>
              </Typography>
            </Box>
            {CIDs.map((CID) => (
              <Box key={CID} display="flex" mb={1} alignItems="center">
                <Typography width="15%">
                  <Trans>CID</Trans>
                </Typography>
                <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", flex: 1 }}>
                  <Trans>{CID}</Trans>
                </Typography>
                <Close
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    ml: 4,
                    background: "#595959",
                    color: "#000000",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setCIDs((prevState) => [...prevState.filter((cid) => cid !== CID)])
                  }}
                />
              </Box>
            ))}
          </Box>
        ) : null}

        <Box width="100%" mt={2}>
          <form onSubmit={onSubmitCIDs} style={{ display: "flex", gap: "12px", width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              value={CID}
              onChange={(e) => setCID(e.target.value)}
              label="CID"
              error={!!isCIDError}
              helperText={isCIDError ? t`CID is invalid` : undefined}
              data-cy="input-add-cid-value"
            />
            <Button
              type="submit"
              sx={{
                background: "#262626",
                color: "#FFFFFF",
                height: "40px",
                "&:hover": {
                  background: "#262626",
                },
              }}
              data-cy="button-add-cid-add"
            >
              <Trans>Add</Trans>
            </Button>
          </form>
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={1.5} mt={4}>
          <Button
            onClick={onCancel}
            sx={{ background: "transparent", color: "#FFFFFF", pl: 2, pr: 2 }}
            data-cy="button-add-cid-cancel"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: "#595959",
              p: 0.5,
              minWidth: "72px",
              minHeight: "32px",
              "&:hover": {
                background: "#595959",
              },
            }}
            onClick={() => {
              onAddCIDs(CIDs)
              setCIDs([])
            }}
            data-cy="button-add-cid-finish"
          >
            <Trans>Finish</Trans>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default MintAddCIDModal
