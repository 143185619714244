import { Trans } from "@lingui/macro"
import { LoadingButton } from "@mui/lab"
import { Box, Typography, Modal } from "@mui/material"
import React from "react"

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 520,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  outline: "none",
}

const FailedDeploymentModal: React.FC<{
  type: "marketplace" | "collection"
  isOpen: boolean
  onDelete: () => void
  onRedeploy: () => void
  isLoadingRedeploy: boolean
  isLoadingDelete: boolean
}> = ({ type, isOpen, onDelete, onRedeploy, isLoadingDelete, isLoadingRedeploy }) => {
  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Typography variant="h5" mb={2} align="center">
          <Trans>Deployment Unsuccessful</Trans>
        </Typography>
        <Typography variant="body1" mb={4} align="left">
          <Trans>
            Your {type} was created but was not deployed on-chain. Would you like to retry
            deployment?
          </Trans>
        </Typography>

        <Box display="flex" justifyContent="flex-end" mt={3} gap={1}>
          <LoadingButton
            variant="text"
            loading={isLoadingDelete}
            disabled={isLoadingDelete || isLoadingRedeploy}
            sx={{
              color: "#BFBFBF",
              width: 160,
              pl: 2,
              pr: 2,
            }}
            onClick={onDelete}
            data-cy={`button-${type}-delete-${type}`}
          >
            <Trans>Delete {type}</Trans>
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{
              width: 160,
              backgroundColor: "#333333",
              color: "#B4FF68",
              "&:hover": {
                backgroundColor: "#333333",
                color: "#B4FF68",
              },
            }}
            loading={isLoadingRedeploy}
            disabled={isLoadingDelete || isLoadingRedeploy}
            onClick={onRedeploy}
            data-cy={`button-${type}-retry-deployment`}
          >
            <Trans>Retry deployment</Trans>
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default FailedDeploymentModal
