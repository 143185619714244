import { Trans } from "@lingui/macro"
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { useConnectWallet, useSetChain } from "@web3-onboard/react"
import { useSnackbar } from "notistack"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { CollectionLogo } from "../../../../assets/icons/CollectionLogo"
import { TOKEN_TYPE, useNFTs } from "../../../../contexts/NFTsContext"
import type { TokenType, NFTCollection } from "../../../../contexts/NFTsContext"
import { useWeb3Connection } from "../../../../contexts/Web3ConnectionContext"
import { ROUTE_LINKS } from "../../../../routes/routes"
import FailedDeploymentModal from "../../../elements/FailedDeploymentModal"
import ImageInput from "../../../elements/ImageInput"
import { SoftButton } from "../../../elements/SoftButton"

const CollectionCreate: React.FC = () => {
  const { isLoggedIn, network } = useWeb3Connection()
  const [, setChain] = useSetChain()

  const { createCollection, deleteCollection, createCollectionOnChain, NFTsProject, NFTsNetworks } =
    useNFTs()
  const [, connect] = useConnectWallet()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [failedCollection, setFailedCollection] = useState<NFTCollection | undefined>()
  const [isLoadingCreateCollection, setIsLoadingCreateCollection] = useState(false)
  const [isLoadingDeleteCollection, setIsLoadingDeleteCollection] = useState(false)
  const [isLoadingRedeployCollection, setIsLoadingRedeployCollection] = useState(false)

  const logoFileInput = useRef<HTMLInputElement>(null)

  const [bannerImages, setBannerImages] = useState<File[]>([])
  const [logoImages, setLogoImages] = useState<File[]>([])

  const [collectionName, setCollectionName] = useState("")
  const [collectionDescription, setCollectionDescription] = useState("")
  const [collectionType, setCollectionType] = useState<TokenType>(TOKEN_TYPE.ERC721)
  const [collectionChainId, setCollectionChainId] = useState<number>(NFTsNetworks[0].chainId)

  useEffect(() => {
    if (network) {
      setCollectionChainId(
        NFTsNetworks.find((nN) => nN.chainId === network.chainId)?.chainId ||
          NFTsNetworks[0].chainId
      )
    }
  }, [network, NFTsNetworks])

  const logoToPreview = useMemo(
    () => (logoImages?.length ? URL.createObjectURL(logoImages[0]) : ""),
    [logoImages]
  )

  const onChangeCollectionChain = (chainId: number): void => {
    setCollectionChainId(chainId)
    if (isLoggedIn) {
      setChain({ chainId: `0x${chainId.toString(16)}` })
    }
  }

  const onSubmitCollection = async (): Promise<void> => {
    // initial validation
    if (!collectionName.trim()) {
      enqueueSnackbar("Please provide a collection name", {
        variant: "error",
      })
      return
    }

    if (!NFTsProject?.projectId) return

    try {
      // make sure to be on the correct chain
      if (network?.chainId !== collectionChainId) {
        const wasNetworkSet = await setChain({ chainId: `0x${collectionChainId.toString(16)}` })
        if (!wasNetworkSet) {
          enqueueSnackbar("Failed to switch network", { variant: "error" })
          return
        }
      }

      setIsLoadingCreateCollection(true)
      // creating collection on the API
      createCollection({
        name: collectionName,
        description: collectionDescription,
        chain_id: collectionChainId,
        type: collectionType,
        banner: bannerImages.length ? bannerImages[0] : undefined,
        logo: logoImages.length ? logoImages[0] : undefined,
      })
        .then((newCollection) => {
          if (!newCollection) return

          // creating on chain collection
          createCollectionOnChain(newCollection)
            .then(() => {
              enqueueSnackbar("New collection created", {
                variant: "success",
                id: "toast-success-create-collection",
              })
              navigate(ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId))
            })
            .catch((err) => {
              setFailedCollection(newCollection)
              console.error(err)
              enqueueSnackbar("Failed to create collection", {
                variant: "error",
                id: "toast-error-create-collection",
              })
            })
            .finally(() => {
              setIsLoadingCreateCollection(false)
            })
        })
        .catch((err) => {
          console.error(err)
          enqueueSnackbar("Failed to create collection", {
            variant: "error",
            id: "toast-error-create-collection",
          })
          setIsLoadingCreateCollection(false)
        })
    } catch (err) {
      console.error(err)
      setIsLoadingCreateCollection(false)
      enqueueSnackbar("Failed to create collection", {
        variant: "error",
        id: "toast-error-create-collection",
      })
    }
  }

  const onDeleteFailedCollection = (): void => {
    if (!failedCollection || !NFTsProject) return
    setIsLoadingDeleteCollection(true)
    deleteCollection(failedCollection.id)
      .then(() => {
        enqueueSnackbar("Collection deleted from records", {
          variant: "success",
          id: "toast-success-delete-collection",
        })
        navigate(ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId))
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to delete collection", { variant: "error" })
      })
      .finally(() => {
        setIsLoadingDeleteCollection(false)
      })
  }

  const onRedeployFailedCollection = (): void => {
    if (!failedCollection || !NFTsProject) return
    setIsLoadingRedeployCollection(true)
    createCollectionOnChain(failedCollection)
      .then(() => {
        enqueueSnackbar("New collection created and deployed", {
          variant: "success",
          id: "toast-success-redeploy-collection",
        })
        navigate(ROUTE_LINKS.NFTsOnProject(NFTsProject?.projectId))
      })
      .catch((err) => {
        console.error(err)
        enqueueSnackbar("Failed to deploy collection", {
          variant: "error",
          id: "toast-error-redeploy-collection",
        })
      })
      .finally(() => {
        setIsLoadingRedeployCollection(false)
      })
  }

  return (
    <Box display="flex" justifyContent="center" mt={4} mb={16}>
      <Box
        width={960}
        sx={{ borderRadius: "8px", border: "1px solid #262626", background: "#141414" }}
      >
        <Box p={4} pb={2}>
          <Typography fontSize={28}>
            <Trans>Collection Details</Trans>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <Trans>
              Enter your collection details below. This will populate in-game for your players to
              see.
            </Trans>
          </Typography>
        </Box>
        <Divider />
        <Box p={4}>
          <Typography color="#DFF7C7">
            <Trans>Basic Information</Trans>
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography color="#8C8C8C">
            <Trans>Logo Image (Optional)</Trans>
          </Typography>
          <Box mt={2} display="flex" gap={2} alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderRadius: "50%",
                border: "1px dashed #D6E4FF",
                width: 60,
                height: 60,
              }}
            >
              {logoToPreview ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: 60,
                    maxHeight: 60,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={logoToPreview}
                />
              ) : (
                <CollectionLogo sx={{ width: 40, height: 40 }} />
              )}
            </Box>
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files?.length) {
                  setLogoImages(Array.from(e.target.files))
                }
              }}
              accept="image/*"
              ref={logoFileInput}
              style={{ display: "none" }} // Make the file input element invisible
              data-cy="file-new-collection-logo-image"
            />
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                logoFileInput?.current?.click()
              }}
              sx={{
                height: 32,
                background: "#595959",
                color: "#FFFFFF",
                ":hover": {
                  background: "#595959",
                  color: "#FFFFFF",
                },
              }}
            >
              {logoToPreview ? <Trans>Change image</Trans> : <Trans>Upload image</Trans>}
            </Button>
          </Box>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="grid"
              gap={2}
              sx={{
                gridTemplateColumns: "2fr 1fr 1fr",
              }}
            >
              <TextField
                label="Name"
                size="small"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                inputProps={{ maxLength: 100 }}
                data-cy="input-new-collection-name"
              />
              <FormControl size="small">
                <InputLabel>Network</InputLabel>
                <Select
                  label="network"
                  size="small"
                  value={collectionChainId}
                  onChange={(e) => onChangeCollectionChain(e.target.value as number)}
                  data-cy="dropdown-new-collection-network"
                >
                  {NFTsNetworks?.map((n) => (
                    <MenuItem
                      key={n.label}
                      value={n.chainId}
                      data-cy={`dropdown-option-new-collection-network-${n.chainId}`}
                    >
                      {n.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel>Collection Type</InputLabel>
                <Select
                  label="Collection Type"
                  size="small"
                  value={collectionType}
                  onChange={(e) => setCollectionType(e.target.value as TokenType)}
                  data-cy="dropdown-new-collection-type"
                >
                  <MenuItem
                    key={TOKEN_TYPE.ERC721}
                    value={TOKEN_TYPE.ERC721}
                    data-cy={`dropdown-option-new-collection-type-${TOKEN_TYPE.ERC721}`}
                  >
                    ERC721
                  </MenuItem>
                  <MenuItem
                    key={TOKEN_TYPE.ERC1155}
                    value={TOKEN_TYPE.ERC1155}
                    data-cy={`dropdown-option-new-collection-type-${TOKEN_TYPE.ERC1155}`}
                  >
                    ERC1155
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField
              label="Description (Optional)"
              multiline
              rows={2}
              size="small"
              inputProps={{ maxLength: 2000 }}
              value={collectionDescription}
              onChange={(e) => setCollectionDescription(e.target.value)}
              data-cy="input-new-collection-description"
            />
          </Box>
        </Box>
        <Divider />
        <Box mt={3} pl={4} pr={4} mb={4}>
          <Typography color="#DFF7C7" sx={{ mb: 1.5 }}>
            <Trans>Media</Trans>
          </Typography>
          <Typography fontSize="13px" sx={{ mb: 1 }}>
            <Trans>Cover Image (recommended min. 640 x 240 px)</Trans>
          </Typography>
          <ImageInput images={bannerImages} setImages={setBannerImages} testId="new-collection" />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={3} pr={4} gap={2}>
          <Button
            onClick={() => {
              if (NFTsProject?.projectId) {
                navigate(ROUTE_LINKS.NFTsOnProject(NFTsProject.projectId))
              }
            }}
            sx={{ background: "#262626", color: "#FFFFFF", pl: 2, pr: 2 }}
          >
            <Trans>Cancel</Trans>
          </Button>
          {isLoggedIn ? (
            <SoftButton
              loading={isLoadingCreateCollection}
              onClick={onSubmitCollection}
              disabled={!collectionName.trim()}
              data-cy="button-new-collection-create"
            >
              <Trans>Create collection</Trans>
            </SoftButton>
          ) : (
            <SoftButton onClick={() => connect()} data-cy="button-new-collection-connect-wallet">
              <Trans>Connect wallet</Trans>
            </SoftButton>
          )}
        </Box>
      </Box>
      <FailedDeploymentModal
        type="collection"
        isOpen={!!failedCollection}
        onDelete={onDeleteFailedCollection}
        onRedeploy={onRedeployFailedCollection}
        isLoadingDelete={isLoadingDeleteCollection}
        isLoadingRedeploy={isLoadingRedeployCollection}
      />
    </Box>
  )
}

export default CollectionCreate
