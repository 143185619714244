import { Trans } from "@lingui/macro"
import { Box, MenuItem, Select, Typography } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  Tooltip,
} from "recharts"

import { NoDataOrConnectionIcon } from "../../../../assets/icons/NoDataOrConnectionIcon"
import { useCountly } from "../../../../contexts/CountlyContext"
import { useProjectApi } from "../../../../contexts/ProjectContext"
import { formatNumberWithMultiplier } from "../../../../utils/Helpers"

const TotalPlayerCount: React.FC<{ projectId?: string }> = ({ projectId }) => {
  const { trackEvent } = useCountly()
  const { getProjectAnalytics } = useProjectApi()
  const [timeRange, setTimeRange] = useState<"last7Days" | "last30Days" | "last6Months">(
    "last7Days"
  )
  const [data, setData] = useState<{ date: string; players: number }[]>([])

  useEffect(() => {
    // total method wise calls
    if (!projectId) return
    getProjectAnalytics(projectId, {
      name: "player",
      group_by: "player_id",
      query: "unique",
      from: dayjs()
        .subtract(timeRange === "last7Days" ? 7 : timeRange === "last30Days" ? 30 : 180, "days")
        .unix(),
      to: dayjs().unix(),
    })
      .then((totalPlayerCountResp) => {
        if (
          totalPlayerCountResp?.timestamps.length &&
          totalPlayerCountResp.values?.length &&
          totalPlayerCountResp.values[0].length
        ) {
          setData(
            totalPlayerCountResp?.timestamps.map((t, i) => ({
              date: dayjs(t * 1000).format("DD MMM YY"),
              players: totalPlayerCountResp.values[0][i],
            })) || []
          )
        } else {
          setData([])
        }
      })
      .catch(console.error)
  }, [projectId, timeRange, getProjectAnalytics])

  return (
    <Box
      width="100%"
      sx={{
        border: "1px solid #333333",
        borderRadius: 2,
        pt: 1,
        pb: 1,
        pr: 3,
      }}
    >
      <Box
        display="flex"
        gap={1}
        ml={3}
        mb={2}
        mt={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize={18}>Total player count</Typography>
        <Select
          value={timeRange}
          size="small"
          onChange={(e) => {
            trackEvent({
              key: "analytics-time-range-toggle",
              segmentation: { project_id: projectId },
            })
            setTimeRange(e.target.value as any)
          }}
          sx={{ height: 34 }}
          data-cy="dropdown-total-player-count"
        >
          <MenuItem data-cy="dropdown-option-total-player-count-7-days" value={"last7Days"}>
            Last 7 days
          </MenuItem>
          <MenuItem data-cy="dropdown-option-total-player-count-30-days" value={"last30Days"}>
            Last 30 days
          </MenuItem>
          <MenuItem data-cy="dropdown-option-total-player-count-6-months" value={"last6Months"}>
            Last 6 months
          </MenuItem>
        </Select>
      </Box>
      <Box mr={1}>
        <ResponsiveContainer width="100%" height={180}>
          {data.length ? (
            <AreaChart id="chart-total-player-count" data={data}>
              <defs>
                <linearGradient id="colorP" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#588E23" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#588E23" stopOpacity={0} />
                </linearGradient>
              </defs>
              {data.length ? <CartesianGrid stroke="#434343" /> : null}
              <XAxis
                dataKey="date"
                tickCount={12}
                axisLine={{ stroke: "#588E23" }}
                tick={{ fill: "#8C8C8C", fontSize: "14px" }}
                tickLine={{ stroke: "transparent" }}
              />
              <YAxis
                tick={{ fill: "#8C8C8C", fontSize: "12px" }}
                tickLine={{ stroke: "transparent" }}
                axisLine={false}
                tickFormatter={(value) => {
                  return formatNumberWithMultiplier(value)
                }}
              />
              <Area
                type="monotone"
                dataKey="players"
                stroke="#588E23"
                fillOpacity={1}
                fill="url(#colorP)"
              />
              <Tooltip
                content={({ label, payload }) => (
                  <Box
                    sx={{
                      backgroundColor: "#141414",
                      border: "1px solid #B4FF68",
                      padding: "8px 12px 8px 12px",
                      borderRadius: 1,
                    }}
                  >
                    <Typography color="#8C8C8C">{label}</Typography>
                    <Typography color="#DFF7C7">
                      {payload?.length ? payload[0].value : "-"}
                    </Typography>
                  </Box>
                )}
                cursor={false}
                wrapperStyle={{ outline: "none" }}
              />
            </AreaChart>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="75%"
              gap={1}
              data-cy="label-empty-total-player-count"
            >
              <NoDataOrConnectionIcon />
              <Typography>
                <Trans>No active players</Trans>
              </Typography>
              <Typography variant="body2" color="#8C8C8C">
                <Trans>Project analytics will show here once there is activity.</Trans>
              </Typography>
            </Box>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default TotalPlayerCount
