import type { SvgIconProps } from "@mui/material"
import { SvgIcon } from "@mui/material"
import React from "react"

export function NoDataOrConnectionIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 34 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.6275 28.37L30.4724 30.3765L25.4914 25.7391L25.2611 25.5247L25.0736 25.7773C24.0737 27.1238 23.4612 28.7269 23.4018 30.4796H20.3208C20.387 27.9291 21.3499 25.5852 22.9117 23.6997L23.0812 23.4952L22.8867 23.3142L20.2821 20.8892L20.0695 20.6913L19.8784 20.9099C17.583 23.5349 16.183 26.8532 16.114 30.4796H13.0337C13.1057 26.0697 14.8653 22.0356 17.7301 18.8939L17.9178 18.688L17.7139 18.4982L13.1421 14.2418L12.9648 14.0768L12.7752 14.2275C9.41156 16.9 5.06102 18.5452 0.281489 18.6074V15.7786C4.16845 15.7186 7.73036 14.4199 10.5521 12.2812L10.8189 12.079L10.5739 11.8508L7.96928 9.42589L7.80056 9.26881L7.6133 9.40325C5.57968 10.8634 3.04457 11.7658 0.281489 11.8233V8.99382C2.15813 8.94215 3.87773 8.37534 5.32519 7.44367L5.62967 7.24769L5.36465 7.00095L0.413098 2.39102L2.5682 0.384598L32.6275 28.37ZM16.6715 0.518895H19.7519C19.7038 3.47689 18.8989 6.25656 17.5101 8.71045L15.2076 6.56681C16.0957 4.72531 16.6287 2.67701 16.6715 0.518895ZM23.676 14.4679C26.3308 13.179 29.3214 12.4327 32.5041 12.391V15.22C30.1742 15.2574 27.9621 15.7578 25.9589 16.5934L23.676 14.4679ZM29.2573 19.6473C30.2851 19.3617 31.3704 19.1984 32.5041 19.1751V22.0127C32.4146 22.0198 32.3277 22.0291 32.2439 22.0386C32.2199 22.0413 32.1961 22.0441 32.1726 22.0468C32.0677 22.0588 31.968 22.0702 31.8685 22.0783L29.2573 19.6473ZM9.37386 0.518895H12.4648C12.4375 1.56361 12.2582 2.58218 11.9373 3.53908L9.29542 1.0795C9.30327 1.02411 9.3118 0.968913 9.32059 0.912048C9.32852 0.860734 9.33665 0.80806 9.3447 0.752653C9.35565 0.677174 9.36598 0.599161 9.37386 0.518895Z"
          fill="#DFF7C7"
          stroke="#141414"
          strokeWidth="0.562977"
        />
      </svg>
    </SvgIcon>
  )
}
