import { LoadingButton } from "@mui/lab"
import type { ButtonProps } from "@mui/material"
import React from "react"

interface ISoftButtonProps extends ButtonProps {
  loading?: boolean
}

export const SoftButton = ({ children, sx, ...props }: ISoftButtonProps): JSX.Element => {
  return (
    <LoadingButton
      sx={{
        backgroundColor: "#333333",
        pr: 2,
        pl: 2,
        color: "primary",
        ":hover": {
          backgroundColor: "#333333",
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  )
}
