import { Trans, t } from "@lingui/macro"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { Box, TextField, Typography } from "@mui/material"
import { ethers } from "ethers"
import React, { useCallback, useState } from "react"

import { CrossCircleIcon } from "../../../../../assets/icons/CrossCircleIcon"
import { useLootbox } from "../../../../../contexts/LootboxContext"
import { RoundIconButton } from "../../../../elements/RoundIconButton"
import { SoftButton } from "../../../../elements/SoftButton"

import ManageLootboxHeader from "./ManageHeader"

interface IPlayerMint {
  playerAddress: string
  numberOfRewards: number
  numberOfLootboxes: number
}

interface IMintProps {
  isConfigure?: boolean
  goNext: () => void
}

const MintAndTransfer: React.FC<IMintProps> = ({ goNext, isConfigure }) => {
  const { mintAndTransferToPlayers } = useLootbox()

  const [isMintsLoading, setIsMintsLoading] = useState(false)
  const [playerMints, setPlayerMints] = useState<IPlayerMint[]>([
    { playerAddress: "", numberOfRewards: 1, numberOfLootboxes: 1 },
  ])
  const [playerMintsErrors, setPlayerMintsErrors] = useState<string[]>([])

  const handleMintsSubmit = useCallback(async () => {
    if (!playerMints.length) return

    setPlayerMintsErrors([])

    // validate player addresses addresses
    const newPlayerAddressErrors = playerMints
      .map((p) => p.playerAddress)
      .map((p) => (ethers.utils.isAddress(p) ? "" : "Player address is not valid"))
    setPlayerMintsErrors([...newPlayerAddressErrors])
    const arePlayerAddressesValid = !newPlayerAddressErrors.find((pE) => !!pE)
    if (!arePlayerAddressesValid) return

    setIsMintsLoading(true)
    const wasSuccess = await mintAndTransferToPlayers(
      playerMints.map((p) => p.playerAddress),
      playerMints.map((p) => p.numberOfRewards),
      playerMints.map((p) => p.numberOfLootboxes)
    )

    setIsMintsLoading(false)

    if (wasSuccess) {
      setPlayerMints([{ playerAddress: "", numberOfRewards: 1, numberOfLootboxes: 1 }])

      // go to next page
      if (isConfigure) goNext()
    }
  }, [mintAndTransferToPlayers, isConfigure, playerMints, goNext])

  return (
    <Box bgcolor="#141414" sx={{ border: "1px solid #262626", borderRadius: "5px" }}>
      <ManageLootboxHeader
        step={isConfigure ? t`Step 5` : undefined}
        heading={t`Loot Box minting`}
        subtitle={t`Mint and transfer your loot box directly to players. First enter the address of the player you wish to send the loot box to, then specify the quantity of loot boxes in this transaction.`}
      />
      <Box
        sx={{
          borderTop: "1px solid #262626",
          borderBottom: "1px solid #262626",
        }}
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Box display="flex" width="100%" alignItems="center">
          <Typography width="45%">
            <Trans>Player address</Trans>
          </Typography>
          <Typography width="25%" align="center" minWidth={200}>
            <Trans># of rewards</Trans>
          </Typography>
          <Typography width="25%" align="center" minWidth={200}>
            <Trans># of lootboxes</Trans>
          </Typography>
          <Box width="5%" />
        </Box>
        {playerMints?.length ? (
          playerMints.map((playerMint, i) => (
            <Box display="flex" width="100%" mt={2} mb={2} alignItems="flex-start" key={i}>
              <Box width="45%" pr={2}>
                <TextField
                  size="small"
                  label={t`Token address ${i + 1}`}
                  value={playerMint.playerAddress}
                  onChange={(e) => {
                    playerMints[i].playerAddress = e.target.value
                    setPlayerMints([...playerMints])
                  }}
                  placeholder="0x..."
                  error={!!playerMintsErrors[i]}
                  helperText={playerMintsErrors[i]}
                  fullWidth
                />
              </Box>

              {/* # of rewards */}
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                justifyContent="center"
                width="25%"
                pl={1}
                pr={1}
                minWidth={200}
              >
                <RoundIconButton
                  variant="contained"
                  size="small"
                  sx={{ fontSize: "24px" }}
                  onClick={() => {
                    if (playerMints[i].numberOfRewards === 1) return
                    playerMints[i].numberOfRewards--
                    setPlayerMints([...playerMints])
                  }}
                >
                  <RemoveIcon />
                </RoundIconButton>
                <TextField
                  value={playerMint.numberOfRewards}
                  size="small"
                  sx={{ maxWidth: "80px", alignSelf: "center" }}
                />
                <RoundIconButton
                  variant="contained"
                  size="small"
                  onClick={() => {
                    playerMints[i].numberOfRewards++
                    setPlayerMints([...playerMints])
                  }}
                >
                  <AddIcon />
                </RoundIconButton>
              </Box>

              {/* # of lootboxes */}
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                justifyContent="center"
                width="25%"
                pl={1}
                pr={1}
                minWidth={200}
              >
                <RoundIconButton
                  variant="contained"
                  size="small"
                  sx={{ fontSize: "24px" }}
                  onClick={() => {
                    if (playerMints[i].numberOfLootboxes === 1) return
                    playerMints[i].numberOfLootboxes--
                    setPlayerMints([...playerMints])
                  }}
                >
                  <RemoveIcon />
                </RoundIconButton>
                <TextField
                  value={playerMint.numberOfLootboxes}
                  size="small"
                  sx={{ maxWidth: "80px", alignSelf: "center" }}
                />
                <RoundIconButton
                  variant="contained"
                  size="small"
                  onClick={() => {
                    playerMints[i].numberOfLootboxes++
                    setPlayerMints([...playerMints])
                  }}
                >
                  <AddIcon />
                </RoundIconButton>
              </Box>

              {/* cross */}
              <Box width="5%">
                <Box
                  pt={1.5}
                  pl={1.5}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setPlayerMints(
                      playerMints.filter((p) => p.playerAddress !== playerMint.playerAddress)
                    )
                    setPlayerMintsErrors([])
                  }}
                >
                  <CrossCircleIcon />
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={{ mt: 4, mb: 4, color: "#BFBFBF" }}>
            <Trans>No Player addresses added</Trans>
          </Typography>
        )}
        <Typography
          onClick={() => {
            setPlayerMints([
              ...playerMints,
              {
                playerAddress: "",
                numberOfRewards: 1,
                numberOfLootboxes: 1,
              },
            ])
            setPlayerMintsErrors([])
          }}
          sx={{
            p: 0,
            color: "#BFBFBF",
            mt: 2,
            textDecoration: "underline",
            pl: 1,
            cursor: "pointer",
          }}
        >
          <Trans>Add another address</Trans>
        </Typography>
      </Box>
      <Box p={4} display="flex" justifyContent="space-between">
        <div />
        <SoftButton onClick={handleMintsSubmit} loading={isMintsLoading}>
          <Trans>Mint and transfer</Trans>
        </SoftButton>
      </Box>
    </Box>
  )
}

export default MintAndTransfer
