import { Box, Modal } from "@mui/material"
import React from "react"

import type { Network } from "../../../utils/NetworkHelpers"

import NetworkSupport from "./NetworkSupport"

interface IWalletConnectModalProps {
  networks: Network[]
  onClose?: () => void
  isOpen: boolean
}

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: 600,
  border: "1px solid #262626",
  boxShadow: 24,
  bgcolor: "#121212",
  borderRadius: "4px",
  padding: 4,
  height: "fit-content",
  overflow: "auto",
  maxHeight: "100vh",
  ":focus": {
    outline: "none",
  },
}

const NetworkSupportModal: React.FC<IWalletConnectModalProps> = ({
  onClose,
  isOpen,
  networks,
}: IWalletConnectModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose} sx={{ zIndex: 1 }}>
      <Box sx={style}>
        <NetworkSupport networks={networks} />
      </Box>
    </Modal>
  )
}

export default NetworkSupportModal
