import { t, Trans } from "@lingui/macro"
import { Box, Button, Typography } from "@mui/material"
import React, { useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"

import { UploadIcon } from "../../assets/icons/UploadIcon"

export interface IImageInputProps {
  name?: string
  error?: string
  images?: File[]
  previewImage?: string
  testId?: string
  maximumSize?: number
  minimumDimensions?: {
    width: number
    height: number
  }
  fitType?: "cover" | "contain"
  setImages?: (value: File[]) => void
  setError?: (error: string) => void
}

const ImageInput = React.forwardRef(
  (
    {
      images,
      previewImage,
      testId,
      setImages,
      name,
      minimumDimensions,
      maximumSize,
      fitType,
      error,
      setError,
    }: IImageInputProps,
    forwardedRef: any
  ) => {
    const [isDropActive, setIsDropActive] = useState(false)

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length + rejectedFiles.length > 1) {
          // multiple files uploaded
        } else if (rejectedFiles.length) {
          // there are rejected files
          setError && setError(rejectedFiles[0].errors[0].message)
        } else if (acceptedFiles.length) {
          // accepted file available
          const acceptedFile = acceptedFiles[0]
          setError && setError("")
          // handle maximum size
          if (maximumSize && maximumSize < acceptedFile.size) {
            setError &&
              setError(t`Image can have a maximum size of ${Math.ceil(maximumSize / 1000000)}MB`)
            return
          }
          // check dimension or set image
          if (minimumDimensions) {
            const fr = new FileReader()
            fr.readAsDataURL(acceptedFile)

            fr.onload = function () {
              const img = new Image()

              img.onload = function () {
                const width = img.width
                const height = img.height
                if (width < minimumDimensions.width || height < minimumDimensions.height) {
                  setError &&
                    setError(
                      t`Image must have minimum dimensions of ${minimumDimensions.width} x ${minimumDimensions.height}px`
                    )
                } else {
                  setImages && setImages([acceptedFile])
                }
              }
              img.src = fr.result as string
            }
          } else {
            setImages && setImages([acceptedFile])
          }
        }
        setIsDropActive(false)
      },
      onDragEnter: () => {
        setIsDropActive(true)
      },
      onDragLeave: () => {
        setIsDropActive(false)
      },
      accept: {
        "image/*": [".jpeg", ".png"],
      },
    })

    const imageToPreview = useMemo(
      () => (images?.length ? URL.createObjectURL(images[0]) : previewImage),
      [images, previewImage]
    )

    return (
      <Box
        width="100%"
        height="inherit"
        sx={{
          border: `1px dashed `,
          borderColor: isDropActive ? "#D6E4FF" : "#4D4D4D",
          borderRadius: "10px",
          background: "#262626",
          cursor: imageToPreview ? "default" : "pointer",
        }}
        ref={forwardedRef}
      >
        <Box
          {...getRootProps({
            onClick: (event) => event.stopPropagation(),
          })}
        >
          {imageToPreview ? (
            <Box sx={{ position: "relative", lineHeight: "0px" }}>
              <img
                src={imageToPreview}
                alt={t`NFT`}
                style={{
                  borderRadius: "12px",
                  objectFit: fitType || "cover",
                  width: "100%",
                  height: "auto",
                  maxHeight: "200px",
                }}
                data-cy={`image-${testId}-preview`}
              />
              <Button
                size="small"
                onClick={open}
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  background: "#595959",
                  color: "#FFFFFF",
                  ":hover": {
                    background: "#595959",
                    color: "#FFFFFF",
                  },
                }}
                data-cy={`button-${testId}-replace`}
              >
                <Trans>Replace</Trans>
              </Button>
            </Box>
          ) : (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={6.25}
              onClick={open}
            >
              <UploadIcon />
              <Typography sx={{ mt: 1 }}>
                <Trans>Drag and drop file to upload</Trans>
              </Typography>
              <Typography color="#999999" sx={{ mt: 1 }}>
                <Trans>(i.e. png, jpg, gif, svg)</Trans>
              </Typography>
            </Box>
          )}
        </Box>
        <input name={name} {...getInputProps()} data-cy={`file-${testId}-image`} />
        {error && (
          <Typography component="p" variant="body2" data-cy={`label-${testId}-error`}>
            {error}
          </Typography>
        )}
      </Box>
    )
  }
)

ImageInput.displayName = "ImageInput"

export default ImageInput
