import { Trans } from "@lingui/macro"
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { LaunchpadIcon } from "../../../assets/icons/LaunchPadIcon"
import { useMarketplace } from "../../../contexts/MarketplaceContext"
import type { IProject } from "../../../contexts/ProjectContext"
import { useProjectApi } from "../../../contexts/ProjectContext"
import { ROUTE_LINKS } from "../../../routes/routes"
import { SoftButton } from "../../elements/SoftButton"
import CreateProjectModal from "../project/CreateProjectModal"

const MarketplaceBase: React.FC = () => {
  const { projects, isProjectsLoading } = useProjectApi()
  const { marketplaceProject } = useMarketplace()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const navigate = useNavigate()

  return (
    <Box mt={10} display="flex" justifyContent="center" alignItems="center">
      {isProjectsLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : !isProjectsLoading && projects?.length ? (
        <Box
          width={320}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <LaunchpadIcon />
          <Typography fontSize={20} sx={{ mt: 3 }}>
            <Trans>To begin, please select a project</Trans>
          </Typography>
          <Typography color="#F5F5F5" sx={{ mt: 1 }}>
            <Trans>Collections will populate here</Trans>
          </Typography>

          <FormControl fullWidth size="small" sx={{ mt: 3 }}>
            <InputLabel>Choose a project</InputLabel>
            <Select
              label="Choose a project"
              sx={{ background: "#333333" }}
              value={marketplaceProject?.projectId}
              onChange={(e) => {
                navigate(ROUTE_LINKS.marketplacesOnProject(e.target.value))
              }}
              data-cy="dropdown-marketplaces-choose-a-project"
            >
              {projects?.map((p) => (
                <MenuItem key={p.name} value={p.projectId}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Box
          width={420}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontSize={30} sx={{ mt: 3 }} align="center">
            <Trans>You haven't started a project</Trans>
          </Typography>
          <Typography color="#F5F5F5" sx={{ mt: 2 }} align="center">
            <Trans>
              Ensure you have a project ready to link to your NFT collection. Go ahead and create
              your project.
            </Trans>
          </Typography>

          <SoftButton
            sx={{ mt: 3 }}
            onClick={() => {
              setIsCreateModalOpen(true)
            }}
            data-cy="button-marketplaces-new-project"
          >
            <Trans>New project</Trans>
          </SoftButton>
        </Box>
      )}

      {isCreateModalOpen ? (
        <CreateProjectModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false)
          }}
          modalMode={"create"}
          onNewProject={(newProject: IProject) => {
            navigate(ROUTE_LINKS.marketplacesOnProject(newProject.projectId))
          }}
        />
      ) : null}
    </Box>
  )
}

export default MarketplaceBase
