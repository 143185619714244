import type { ReactNode } from "react"
import React from "react"

import { LootboxProvider } from "../../contexts/LootboxContext"
import { useWeb3Connection } from "../../contexts/Web3ConnectionContext"
import NetworkSupportModal from "../modules/networkSupport/NetworkSupportModal"
import WalletConnectModal from "../modules/walletConnect/WalletConnectModal"

export const LootboxDashboardLayout = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isLoggedIn, network, networks } = useWeb3Connection()

  return (
    <LootboxProvider>
      {!isLoggedIn && <WalletConnectModal isOpen={true} />}
      {isLoggedIn && !network?.lootboxFactoryAddress && (
        <NetworkSupportModal
          networks={networks.filter((n) => !n.isLocalNetwork && !!n.lootboxFactoryAddress)}
          isOpen={true}
        />
      )}
      {children}
    </LootboxProvider>
  )
}
